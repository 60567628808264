import React, { useEffect, useState } from 'react';
import { OperationVariables, QueryResult, useMutation, useQuery } from '@apollo/client';
import { GET_CANDIDATY } from '../../graphql/querries/Candidature';
import { DELETE_CANDIDATY } from '../../graphql/mutations/Candidature';
import { CandidatyTypes, FileLinkTypes, RessourceFilesTypes, UserTypes } from '../../utils/types';
import { useToasts } from 'react-toast-notifications';
import { downloadExcel } from 'react-export-table-to-excel';
import { CREATE_FILE_LINK, UPDATE_FILE_LINK, DELETE_FILE_LINK } from '../../graphql/mutations/FileLink';
import { GET_FILE_LINK } from '../../graphql/querries/FileLink';
import { useTranslation } from 'react-i18next';

const useLogic = () => {
  const FILES_ID = {
    DEPOT: 'DP',
    L1: 'L1',
    M1: 'M1',
    L3: 'L3'
  };

  const { t } = useTranslation();

  const linkDropBox = 'https://www.dropbox.com/home/Applications/GeIT';

  const { loading, data, refetch } = useQuery(GET_CANDIDATY);
  const [deleteCandidaty] = useMutation(DELETE_CANDIDATY);

  const [deleteFileLink] = useMutation(DELETE_FILE_LINK);
  const [createFileLink] = useMutation(CREATE_FILE_LINK);
  const [updateFileLink] = useMutation(UPDATE_FILE_LINK);
  const fileLinkQuerry = useQuery(GET_FILE_LINK);
  const [openCreaction, setopenCreaction] = useState<boolean>(false);
  const { addToast } = useToasts();
  const [waiting, setwaiting] = useState<boolean>(false);
  const [search, setSearch] = useState<string>('');
  const [candidaty, setCandidaty] = useState<CandidatyTypes[]>([]);
  const [currentCreation, setcurrentCreation] = useState<UserTypes>({} as UserTypes);
  const [valueText, setValueText] = useState({
    depot: {} as FileLinkTypes,
    M1: {} as FileLinkTypes,
    L1: {} as FileLinkTypes,
    L3: {} as FileLinkTypes
  });
  const [isLocked, setisLocked] = useState<boolean>(true);

  const [errorTextField, setErrorTextField] = useState({ depot: false, M1: false, L1: false, L3: false });
  const REGEXHTTP = 'https://.*|http://.*';

  const handleChangeParams = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const reg = new RegExp(REGEXHTTP);
    switch (e.target.id) {
      case 'depot':
        setErrorTextField(() => {
          return { ...errorTextField, depot: !reg.test(e.target.value) };
        });
        setValueText(() => {
          return { ...valueText, depot: { ...valueText.depot, link: e.target.value } };
        });
        break;
      case 'M1':
        setErrorTextField(() => {
          return { ...errorTextField, M1: !reg.test(e.target.value) };
        });
        setValueText(() => {
          return { ...valueText, M1: { ...valueText.M1, link: e.target.value } };
        });
        break;
      case 'L1':
        setErrorTextField(() => {
          return { ...errorTextField, L1: !reg.test(e.target.value) };
        });
        setValueText(() => {
          return { ...valueText, L1: { ...valueText.L1, link: e.target.value } };
        });
        break;
      case 'L3':
        setErrorTextField(() => {
          return { ...errorTextField, L3: !reg.test(e.target.value) };
        });
        setValueText(() => {
          return { ...valueText, L3: { ...valueText.L3, link: e.target.value } };
        });
        break;
    }
  };

  // TODO Fix can't get form ID if the type is not any
  const handleSubmitParams = async (e: any) => {
    e.preventDefault();
    switch (e.target?.id) {
      case 'depotForm':
        if (valueText.depot.id) {
          updateFile(valueText.depot);
        } else {
          setwaiting(true);
          await createFileLink({
            variables: {
              link: valueText.depot.link,
              tier: FILES_ID.DEPOT,
            },
          })
            .then(() => {
              addToast(t('successSend'), {
                appearance: 'success',
              });
              fileLinkQuerry.refetch();
              setwaiting(false);
            })
            .catch((error) => {
              addToast(error.message, {
                appearance: 'error',
              });
              setwaiting(false);
            });
        }
        break;
      case 'L1Form':
        if (valueText.L1.id) {
          updateFile(valueText.L1);
        } else {
          setwaiting(true);
          await createFileLink({
            variables: {
              link: valueText.L1.link,
              tier: FILES_ID.L1,
            },
          })
            .then(() => {
              addToast(t('successSend'), {
                appearance: 'success',
              });
              fileLinkQuerry.refetch();
              setwaiting(false);
            })
            .catch((error) => {
              addToast(error.message, {
                appearance: 'error',
              });
              setwaiting(false);
            });
        }
        break;
      case 'L3Form':
        if (valueText.L3.id) {
          updateFile(valueText.L3);
        } else {
          setwaiting(true);
          await createFileLink({
            variables: {
              link: valueText.L3.link,
              tier: FILES_ID.L3,
            },
          })
            .then(() => {
              addToast(t('successSend'), {
                appearance: 'success',
              });
              fileLinkQuerry.refetch();
              setwaiting(false);
            })
            .catch((error) => {
              addToast(error.message, {
                appearance: 'error',
              });
              setwaiting(false);
            });
        }
        break;
      case 'M1Form':
        if (valueText.M1.id) {
          updateFile(valueText.M1);
        } else {
          setwaiting(true);
          await createFileLink({
            variables: {
              link: valueText.M1.link,
              tier: FILES_ID.M1,
            },
          })
            .then(() => {
              addToast(t('successSend'), {
                appearance: 'success',
              });
              fileLinkQuerry.refetch();
              setwaiting(false);
            })
            .catch((error) => {
              setwaiting(false);
              addToast(error.message, {
                appearance: 'error',
              });
            });
          break;
        }
    }
  };

  const updateFile = async (file: FileLinkTypes) => {
    setwaiting(true);

    await updateFileLink({
      variables: {
        id: file.id,
        link: file.link,
      },
    })
      .then(() => {
        addToast(t('successEdit'), {
          appearance: 'success',
        });
        fileLinkQuerry.refetch();
        setwaiting(false);
      })
      .catch((error) => {
        setwaiting(false);
        addToast(error.message, {
          appearance: 'error',
        });
      });
  };

  const handleDeleteCandidaty = async (candidat: CandidatyTypes) => {
    setwaiting(true);
    await deleteCandidaty({
      variables: {
        id: candidat.id,
        deleteFiles: true,
      },
    })
      .then(() => {
        addToast(t('successDelete'), {
          appearance: 'success',
        });
        refetch();
        setwaiting(false);
      })
      .catch((error) => {
        setwaiting(false);
        addToast(error.message, {
          appearance: 'error',
        });
      });
  };
  const handleDeleteFileLink = async (file: FileLinkTypes) => {
    setwaiting(true);
    await deleteFileLink({
      variables: {
        id: file.id,
      },
    })
      .then(() => {
        setwaiting(false);
        addToast(t('successDelete'), {
          appearance: 'success',
        });
        fileLinkQuerry.refetch();
      })
      .catch((error) => {
        setwaiting(false);
        addToast(error.message, {
          appearance: 'error',
        });
      });
  };

  const formatLink = (path: string[]): string => {
    const filesSplited = path[0].split('/');
    return linkDropBox + '/' + filesSplited[1] + '/' + filesSplited[2];
  };

  const handleDownloadExcel = () => {
    const header = ['Nom', 'Prenom', 'Email', 'Tel', 'Formation', 'Message', 'Date de soumission', 'Files'];

    const table: any = [];
    data.Candidacies.forEach((element: CandidatyTypes) => {
      table.push({
        name: element.name,
        surname: element.surname,
        email: element.email,
        tel: element.tel + '',
        formation: element.formation,
        message: element.message,
        date: new Date(element.createdAt).toLocaleDateString(),
        files: formatLink(element.path),
      });
    });

    downloadExcel({
      fileName: 'Candidatures' + Date.now().toLocaleString(),
      sheet: 'CandidatyTypes',
      tablePayload: {
        header,
        body: table,
      },
    });
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(() => event.target.value);
    if (event.target.value !== '') {
      let search_results = data.Candidacies.filter(
        (item: CandidatyTypes) =>
          item?.name.toLowerCase().includes(event.target.value.toLowerCase()) ||
          item?.surname.toLowerCase().includes(event.target.value.toLowerCase()) ||
          item?.email.toLowerCase().includes(event.target.value.toLowerCase()),
      );
      setCandidaty(() => search_results);
    } else {
      setCandidaty(data.Candidacies);
    }
  };

  const getDataFile = (fileLinkQuerry: QueryResult<any, OperationVariables>) => {
    if (!fileLinkQuerry.loading && fileLinkQuerry.data) {
      const filesL3 = fileLinkQuerry?.data?.getFileLinks?.filter((l: FileLinkTypes) => l.tier === FILES_ID.L3);
      const filesM1 = fileLinkQuerry?.data?.getFileLinks?.filter((l: FileLinkTypes) => l.tier === FILES_ID.M1);
      const filesL1 = fileLinkQuerry?.data?.getFileLinks?.filter((l: FileLinkTypes) => l.tier === FILES_ID.L1);
      const filesDP = fileLinkQuerry?.data?.getFileLinks?.filter((l: FileLinkTypes) => l.tier === FILES_ID.DEPOT);

      setValueText({
        L3: filesL3 && filesL3[0] ? filesL3[0] : ({ link: '' } as FileLinkTypes),
        M1: filesM1 && filesM1[0] ? filesM1[0] : ({ link: '' } as FileLinkTypes),
        L1: filesL1 && filesL1[0] ? filesL1[0] : ({ link: '' } as FileLinkTypes),
        depot: filesDP && filesDP[0] ? filesDP[0] : ({ link: '' } as FileLinkTypes),
      });
    }
  };

  const handleLock = (e: React.ChangeEvent<HTMLInputElement>) => {
    setisLocked(!isLocked);
  };

  const handleCreation = (candidaty: CandidatyTypes) => {
    let ressources: any = [];

    candidaty?.files.forEach((element) => {
      ressources.push({
        link: element,
        path: candidaty?.path.length > 0 && candidaty?.path[0] ? candidaty?.path[0] : undefined,
      });
    });

    setcurrentCreation(() => {
      return {
        id: candidaty.id,
        email: candidaty.email,
        lastName: candidaty.surname,
        firstName: candidaty.name,
        username: candidaty.name.toUpperCase() + '_' + candidaty.surname.split(' ')[0].toUpperCase(),
        tel: candidaty.tel,
        Role: { type: 'member' },
        RessourceFiles: ressources,
      };
    });
    setopenCreaction(true);
  };

  useEffect(() => {
    if (!loading && data) {
      setCandidaty(data.Candidacies);
    }
  }, [data, loading]);

  useEffect(() => {
    if (!fileLinkQuerry.loading && fileLinkQuerry.data) getDataFile(fileLinkQuerry);
  }, [fileLinkQuerry]);

  return {
    loading,
    handleDeleteCandidaty,
    search,
    handleSearch,
    candidaty,
    handleDownloadExcel,
    waiting,
    valueText,
    errorTextField,
    handleSubmitParams,
    handleChangeParams,
    handleDeleteFileLink,
    handleCreation,
    isLocked,
    handleLock,
    refetch,
    openCreaction,
    setopenCreaction,
    currentCreation,
    setcurrentCreation,
  };
};

export default useLogic;
