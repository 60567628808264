import React, { useEffect, useState } from 'react';
import { Box, Tab, Tabs } from '@mui/material';
import { FC } from 'react';
import BibliothequeList from './Book/BibliothequeList';
import ThematicList from './Thematic/ThematicList';
import { ThematicType } from '../../utils/interface';
import { DATE_NOW, TITLE } from '../../utils/constant';
import Action from './Book/BibliothequeAction';
import ThematicAction from './Thematic/ThematicAction';
import BibliothequeForm from './Book/BibliothequeForm';
import { useThematics } from '../../graphql/consumerHooks/thematic';
import Loader from '../../components/Loader';
import ThematicForm from './Thematic/ThematicForm';
import ActionItem from './Item/ItemAction';
import ItemForm from './Item/ItemForm';
import { addThematic, showDialogThematic, thematicList } from '../../Redux/Actions/ActionsThematic';
import { useDispatch, useSelector } from 'react-redux';
import DialogCustom from '../../components/Dialog/dialog';
import { addBook, addItem, showBookDetails, showDialogBook, showDialogItem } from '../../Redux/Actions/ActionBook';

import { useTranslation } from 'react-i18next';
import Details from './Book/Details';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel: FC<TabPanelProps> = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
};

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const Bibliotheque = () => {
  const { t } = useTranslation();

  const [panel, setPanel] = useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setPanel(newValue);
  };
  const dispatch = useDispatch();
  const { data: dataThematics, loading } = useThematics();
  const action = useSelector((state: any) => state);

  useEffect(() => {
    if (!loading && dataThematics) {
      const thematics: ThematicType[] = [];
      dataThematics?.map((item: any) => {
        return thematics.push({ id: item?.id, title: item?.title });
      });

      dispatch(thematicList(thematics));
    }
  }, [loading, dataThematics, dispatch]);

  const handleCloseThematic = () => {
    dispatch(showDialogThematic(false));
    dispatch(
      addThematic({
        id: '',
        title: '',
      }),
    );
  };

  const handleCloseItem = () => {
    dispatch(showDialogItem(false));
    dispatch(
      addItem({
        id: '',
        price: '',
        bookId: '',
      }),
    );
  };

  const handleCloseBook = () => {
    dispatch(showDialogBook(false));
    dispatch(
      addBook({
        id: '',
        title: '',
        published_date: DATE_NOW.format('DD/MM/YYYY'),
        thematicId: '',
        referral_years: '',
        page_number: 1,
        image: { imageBase64: '', imageName: '' },
        pdf: { pdfBase64: '', pdfName: '' },
        ressourceFiles: [],
        thematic: { id: '', title: '' },
        thematic_title: '',
        content: '',
        items: [],
      }),
    );
  };

  const handleCloseDetails = () => {
    dispatch(showBookDetails(false));
    dispatch(
      addBook({
        id: '',
        title: '',
        published_date: DATE_NOW.format('DD/MM/YYYY'),
        thematicId: '',
        referral_years: '',
        page_number: 1,
        image: { imageBase64: '', imageName: '' },
        pdf: { pdfBase64: '', pdfName: '' },
        ressourceFiles: [],
        thematic: { id: '', title: '' },
        thematic_title: '',
        content: '',
        items: [],
      }),
    );
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={panel} onChange={handleChange}>
          <Tab label={t('Library.book')} {...a11yProps(0)} />
          <Tab label={t('Library.thematic')} {...a11yProps(1)} />
        </Tabs>
      </Box>
      <Loader enable={action?.loader?.loader} />

      <DialogCustom
        title={TITLE['book']}
        Action={Action}
        Content={BibliothequeForm}
        open={action.book.show}
        handleClose={handleCloseBook}
      />

      <DialogCustom
        title={TITLE['thematic']}
        Action={ThematicAction}
        Content={ThematicForm}
        open={action.thematic.show}
        handleClose={handleCloseThematic}
      />

      <DialogCustom
        title={TITLE['item']}
        Action={ActionItem}
        Content={ItemForm}
        open={action.book.showItem}
        handleClose={handleCloseItem}
      />

      <Details open={action?.book?.open} handleClose={handleCloseDetails} />

      <TabPanel value={panel} index={0}>
        <BibliothequeList />
      </TabPanel>
      <TabPanel value={panel} index={1}>
        <ThematicList />
      </TabPanel>
    </Box>
  );
};

export default Bibliotheque;
