import { useMutation } from '@apollo/client';
import { ChangeEvent } from 'react';
import { useToasts } from 'react-toast-notifications';
import { EXPORT_DATABASE, IMPORT_DATABASE } from '../../../graphql/mutations/importExport';

interface Props {
  inputFileRef: React.MutableRefObject<HTMLInputElement | null>;
}

const useLogic = ({ inputFileRef }: Props) => {
  const [exportData, { loading: loadingExport }] = useMutation(EXPORT_DATABASE);
  const [importData, { loading: loadingImport }] = useMutation(IMPORT_DATABASE);
  const { addToast } = useToasts();

  const handleImport = async () => {
    inputFileRef?.current?.click();
  };

  const handleUpload = async (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files && files.length) {
      var reader = new FileReader();
      reader.readAsText(files[0], 'UTF-8');
      const result = await new Promise((resolve, reject) => {
        reader.onload = (event) => {
          resolve(reader.result);
        };
        reader.onerror = (event) => {
          reject(event);
        };
      });

      const data = await JSON.stringify(result);

      await importData({ variables: { data: data, overwrite: false } })
        .then((result) => {
          if (!result.errors) {
            addToast('Success', {
              appearance: 'success',
            });
          }
        })
        .catch((err: any) => {
          addToast(+err, {
            appearance: 'error',
          });
        });
    }
  };

  const handleExport = async () => {
    await exportData()
      .then((result) => {
        if (!result.errors) {
          const data = JSON.parse(result.data.exportData);
          const blob = new Blob([data], { type: 'text/plain' });
          const url = URL.createObjectURL(blob);

          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `backup.geit`);
          document.body.appendChild(link);
          link.click();
          link?.parentNode?.removeChild(link);
        }
      })
      .catch((err: any) => {
        addToast(err, {
          appearance: 'error',
        });
      });
  };

  return {
    handleImport,
    handleExport,
    loadingExport,
    loadingImport,
    handleUpload,
  };
};

export default useLogic;
