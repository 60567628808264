import React from 'react';
import { Avatar, Box, Container, CssBaseline, Typography, TextField, Button } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { ArrowBackIos } from '@mui/icons-material';
import useLogic from './useLogic';
import { LISTE_ROUTES } from '../../routes/route';

const theme = createTheme();

const VerifyOtp = () => {
  const { state, code, handleGoTo, handleResendOTP, handleChangeCode, handleVerifyOTP } = useLogic();

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Button
          startIcon={<ArrowBackIos />}
          style={{ color: '#959595' }}
          onClick={() => handleGoTo(LISTE_ROUTES.LOGIN.path)}
        >
          Back
        </Button>
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: '#ff9800' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5" style={{ marginBottom: '20px' }}>
            Verification code
          </Typography>
          <Typography component="h4" variant="body1">
            A 6-digit security code was sent to the email {state}
          </Typography>
          <Box component="form" noValidate sx={{ mt: 1 }}>
            <TextField
              autoFocus
              type="number"
              margin="normal"
              fullWidth
              value={code}
              onChange={handleChangeCode}
              id="email"
              name="email"
              placeholder="Enter the verification code"
              required
            />
            <Button
              fullWidth
              variant="text"
              style={{ color: '#959595', textTransform: 'capitalize' }}
              sx={{ mt: 3, mb: 2 }}
              onClick={handleResendOTP}
            >
              Resend code
            </Button>
            <Button
              fullWidth
              variant="contained"
              style={{ backgroundColor: '#ff9800' }}
              sx={{ mt: 3, mb: 2 }}
              onClick={handleVerifyOTP}
            >
              Verify
            </Button>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default VerifyOtp;
