import { gql } from '@apollo/client';

const GET_ME = gql`
  query Me {
    me {
      RessourceFiles {
        createdAt
        id
        link
        path
        name
        type
        updatedAt
      }
      Role {
        createdAt
        id
        type
        updatedAt
      }
      address
      createdAt
      email
      firstName
      id
      lastName
      password
      status
      tel
      updatedAt
      username
      Course {
        Curriculum {
          Diploma {
            createdAt
            description
            id
            name
            title
            type
            updatedAt
          }
          Education {
            createdAt
            description
            id
            name
            title
            updatedAt
          }
          amout
          createdAt
          id
          updatedAt
          year
        }
        createdAt
        id
        status
        updatedAt
      }
      Location {
        Country {
          isoCode
          id
          name
        }
        createdAt
        id
        town
        updatedAt
      }
    }
  }
`;

export { GET_ME };
