import { Avatar, Box, Container, CssBaseline, Typography, TextField, Button } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import React from 'react';
import useLogic from './useLogic';
import GEIT_BO_Logo from '../Login/images/LogoBO.svg';
import useStyles from './styles';
import styles from '../Login/style';

const theme = createTheme();

const ForgotPassword = () => {
  const { email, isDisabled, handleChange, hanldeClick } = useLogic();
  const styles = useStyles()

  return (
    <ThemeProvider theme={theme}>
      <Box style={{ backgroundColor: '#ffffff' }}>

        <Container component="main" maxWidth="md">

          <Box className={styles.ContentLogin}>
            <CssBaseline />
            <img src={GEIT_BO_Logo} alt="logo BO" />
            <Box className={styles.wrapLogin}
              sx={{
                marginTop: 0,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              {/* <Avatar sx={{ m: 1, bgcolor: '#ff9800' }}>
            <LockOutlinedIcon />
          </Avatar> */}
              <Typography component="h1" variant="h5" className={styles.titleConnection}>
                Mot de passe oublié
              </Typography>
              <Box component="form" noValidate sx={{ mt: 1 }} className={styles.input}>
                <TextField
                  autoComplete="email"
                  autoFocus
                  margin="normal"
                  fullWidth
                  id="email"
                  label="Adresse mail"
                  name="email"
                  onChange={handleChange}
                  placeholder="Votre adresse mail "
                  value={email}
                />
                <Button
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                  onClick={hanldeClick}
                  className={styles.bgOrange}
                >
                  Envoyer
                </Button>
              </Box>
            </Box>
          </Box>
        </Container>
      </Box>
    </ThemeProvider >
  );
};

export default ForgotPassword;
