import { gql } from '@apollo/client';

export const Actualities = gql`
  fragment Actualities on Actuality {
    active
    categoryId
    content
    default_date
    end_date
    id
    image
    start_date
    title
    category {
      id
      name
    }
  }
`;
