import { useQuery } from '@apollo/client';
import { GET_CATEGORIES } from '../querries/category';

export const useCategories = () => {
  const { data, loading, error } = useQuery(GET_CATEGORIES, {
    fetchPolicy: 'network-only',
  });

  return { data: data?.getCategories, loading, error };
};
