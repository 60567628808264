import { ActionType } from '../../utils/constant';
import { ThematicType } from '../../utils/interface';
import { ADD_THEMATIC, SHOW_DIALOG_THEMATIC, BUTTON_ACTION_DIALOG, THEMATIC_LIST } from '../Actions/ActionsThematic';

interface Thematic {
  thematic: ThematicType;
  dataThematics: ThematicType[];
  show: boolean;
  waiting: boolean;
  actionType: ActionType;
}

const initialState: Thematic = {
  thematic: { id: '', title: '' },
  dataThematics: [],
  show: false,
  waiting: false,
  actionType: ActionType.SAVE,
};

export const thematicReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case ADD_THEMATIC:
      return { ...state, thematic: action.payload.thematic };
    case THEMATIC_LIST:
      return { ...state, dataThematics: action.payload.thematics };

    case SHOW_DIALOG_THEMATIC:
      return { ...state, show: action.payload.show };
    case BUTTON_ACTION_DIALOG:
      return { ...state, actionType: action.payload.actionType };
    default:
      return state;
  }
};
