import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import en from './en.json';
import fr from './fr.json';
import mg from './mg.json';

const resources = {
  en: {
    translation: en,
  },
  fr: {
    translation: fr,
  },
  mg: {
    translation: mg,
  },
};

const options = {
  order: ['querystring', 'navigator'],
  lookupQuerystring: 'lng',
};

i18n.use(LanguageDetector).use(initReactI18next).init({
  resources,
  detection: options,
  fallbackLng: 'en',
  debug: true,
});

export default i18n;
