import { makeStyles } from '@mui/styles'

export default makeStyles(() => ({

    active: {
        borderRadius:'12px',
        backgroundColor: '#FFF3EC',
        // borderRight: "4px solid red",
        color: "#E85D04",
        paddingRight:'2px',
       
        '& svg': {
            fill: "#E85D04"
        },
        '&>div:after':{
            display:'flex',
            content:"''",
            position:'absolute !important',
            borderRight:'4px solid #E85D04 !important',
            width:'30px',
            height:'100%',
            borderRadius:"12px",
            right:'-2px',
        },
        '&:hover':{
            backgroundColor:'none !important' 
         },
    },
    texwwwt: {
        borderRadius: '5px !important',
        height:"100%",
        '&:hover':{
            backgroundColor:"unset !important"
        },
        '& span' : {
            fontWeight: 600, 
            fontSize:20,
            color: '#6C757D',
            letterSpacing: '0.4px',
        },
    },
    spanActive:{
        '& span' : {
            color: "#E85D04",
        }
    }
}))
