import { AttachFile, Delete, DataSaverOff, Search, SaveAs } from '@mui/icons-material';
import { Box, Button, IconButton, InputAdornment, TextField } from '@mui/material';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Loader from '../../components/Loader';
import UserCreate from '../../components/User/UserCreate';
import useLogic from './useLogic';
import ExportFile from './images/ExportFile';
import imageDel from './images/MdiDelete2.svg';
import imageModif from './images/MdiPencil2.svg';
import imageDownload from './images/MdiDownload.svg';
// import imageModif from './images/MdiPencil/MdiPencil';


/** Import Herilala */
import * as React from 'react';
import Collapse from '@mui/material/Collapse';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import useStyles from './styles'
interface Props { }

const ListeCandidaties: FC<Props> = () => {
  const {
    loading,
    handleDeleteCandidaty,
    search,
    handleSearch,
    handleCreation,
    candidaty,
    handleDownloadExcel,
    waiting,
    refetch,
    openCreaction,
    setopenCreaction,
    currentCreation,
  } = useLogic();
  const { t } = useTranslation();

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: t('User.lastName') + '',
      flex: 1,
      editable: false,
    },
    {
      field: 'surname',
      headerName: t('User.firstName') + '',
      flex: 1,
      editable: false,
    },
    {
      field: 'email',
      headerName: 'Email',
      type: 'number',
      flex: 1,
      editable: false,
    },
    {
      field: 'tel',
      headerName: t('User.phone') + '',
      type: 'number',
      flex: 1,
      editable: false,
    },
    {
      field: 'message',
      headerName: 'Message',
      type: 'number',
      width: 210,
      editable: false,
    },
    {
      field: 'createdAt',
      headerName: t('User.submission_date') + '',
      type: 'string',
      flex: 1,
      editable: false,
      valueGetter: (params: GridValueGetterParams) => new Date(params.row.createdAt).toLocaleDateString(),
    },
    {
      field: 'files',
      headerName: t('User.file') + '',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      flex: 1,
      renderCell: (params: any) => {
        return (
          <div>
            {params.row.files.map((file: string, index: number) => {
              return (
                <>
                  <a key={index} href={file}>
                    <IconButton color="info" aria-label="open drawer" edge="end" sx={{ mr: 0 }}>
                      <AttachFile />
                    </IconButton>
                  </a>
                </>
              );
            })}
          </div>
        );
      },
    },
    {
      field: '  ',
      headerName: ' ',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      flex: 1,
      renderCell: (params: any) => {
        return (
          <div>
            <IconButton
              onClick={() => {
                handleCreation(params.row);
              }}
              color="info"
              aria-label="open drawer"
              edge="end"
              sx={{ mr: 0 }}
            >
              <SaveAs />
            </IconButton>
          </div>
        );
      },
    },
    {
      field: ' ',
      headerName: ' ',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      width: 50,
      renderCell: (params: any) => {
        return (
          <div>
            <IconButton
              color="error"
              aria-label="open drawer"
              onClick={() => {
                handleDeleteCandidaty(params.row);
              }}
              edge="end"
              sx={{ mr: 2 }}
            >
              <Delete />
            </IconButton>
          </div>
        );
      },
    },
  ];




  function createData(
    name: string,
    firstName: any,
    email: any,
    telephone: any,
    message: any,
    DateSousmis: any,
    imageModif: any,
    imageDelete: any,
  ) {
    return {
      name,
      firstName,
      email,
      telephone,
      message,
      DateSousmis,
      imageModif,
      imageDelete,
      history: [
        {
          date: '2020-01-05',
          customerId: '11091700',
          amount: 3,
        },
        {
          date: '2020-01-02',
          customerId: 'Anonymous',
          amount: 1,
        },
      ],
    };
  }

  /**Code Herilala Tableau encore Statique */

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  function Row(props: { row: ReturnType<typeof createData> }) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);

    return (
      <React.Fragment >

        <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell component="th" scope="row">
            <Box sx={{ width: 80, display: 'block', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', wordWrap: 'normal', borderBottom: 'unset', verticalAlign: 'right' }}>
              {row.name}
            </Box>
          </TableCell>
          <TableCell align="left" >
            <Box sx={{ width: 50, display: 'block', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', wordWrap: 'normal', borderBottom: 'unset', verticalAlign: 'right' }}>
              {row.firstName}
            </Box>
          </TableCell>
          <TableCell align="left">
            <Box sx={{ width: 150, display: 'block', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', wordWrap: 'normal', borderBottom: 'unset', verticalAlign: 'right' }}>
              {row.email}
            </Box>
          </TableCell>
          <TableCell align="left" >
            {row.telephone}
          </TableCell>
          <TableCell align="left" >
            <Box sx={{ width: 150, display: 'block', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', wordWrap: 'normal', borderBottom: 'unset', verticalAlign: 'right' }}>
              {row.message}
            </Box>
          </TableCell>
          <TableCell align="left">
            <Box sx={{ width: 150, display: 'block', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', wordWrap: 'normal', borderBottom: 'unset', verticalAlign: 'right' }}>
              {row.DateSousmis}
            </Box>
          </TableCell>
          <TableCell align="left" width="50" sx={{paddingLeft:0,paddingRight:0}}>
            {row.imageModif}
          </TableCell>
          <TableCell align="left" width="50" sx={{paddingLeft:0,paddingRight:0}}>
            {row.imageDelete}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0, border: 'none' }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1, width: 250 }}>
                <Typography variant="h6" component="div">
                  Data du Candidat
                </Typography>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow >
                      <TableCell >Nom</TableCell>
                      <TableCell ></TableCell>
                    </TableRow>
                    <TableRow >
                      <TableCell >nom_fichier.pdf</TableCell>
                      <TableCell align='right'><Button>
                        <img src={imageDownload} alt="Pencil Icone" />
                      </Button></TableCell>
                    </TableRow>
                    <TableRow >
                      <TableCell >nom_fichier2.pdf</TableCell>
                      <TableCell align='right'><Button>
                        <img src={imageDownload} alt="Pencil Icone" />
                      </Button></TableCell>
                    </TableRow>
                    <TableRow >
                      <TableCell >nom_fichier3.pdf</TableCell>
                      <TableCell align='right'><Button>
                        <img src={imageDownload} alt="Pencil Icone" />
                      </Button></TableCell>
                    </TableRow>
                  </TableHead>

                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }
  const rows = [
    // eslint-disable-next-line jsx-a11y/alt-text
    createData('Tokiniaina Tiavina', 'Jean', 'framavozatovo@gmail.com', 261342978820, 'Voici mon dossier de candidature, et je souhaite intégrer GE-IT, merci', '11/12/2000',
      <Box>
        <Button sx={{minWidth:"unset", padding:0}}>
          <img src={imageModif} alt="Pencil Icone" />
        </Button>
      </Box>,
      <Box>
        <Button sx={{minWidth:"unset", padding:0}}>
          <img src={imageDel} alt="Del Icone" />
        </Button>
      </Box>,),
    createData('Faniry toky Fitiavana', 'Chris', 'framavozatovo@gmail.com', 261342978820, 'Voici mon dossier de candidature, et je souhaite intégrer GE-IT, merci', '11/12/2000',
      <Box>
        <Button sx={{minWidth:"unset", padding:0}}>
          <img src={imageModif} alt="Pencil Icone" />
        </Button>
      </Box>,
      <Box>
        <Button sx={{minWidth:"unset", padding:0}}>
          <img src={imageDel} alt="Del Icone" />
        </Button>

      </Box>,),
    createData('MAHARESY BIENVENU', 'Ariana', 'framavozatovo@gmail.com', 261342978820, 'Voici mon dossier de candidature, et je souhaite intégrer GE-IT, merci', '11/12/2000',
      <Box>
        <Button sx={{minWidth:"unset", padding:0}}>
          <img src={imageModif} alt="Pencil Icone" />
        </Button>
      </Box>,
      <Box>
        <Button sx={{minWidth:"unset", padding:0}}>
          <img src={imageDel} alt="Del Icone" />
        </Button>
      </Box>,),
    createData('Jean Léonce', 'Léonce', 'framavozatovo@gmail.com', 261342978820, 'Voici mon dossier de candidature, et je souhaite intégrer GE-IT, merci', '11/12/2000',
      <Box>
        <Button sx={{minWidth:"unset", padding:0}}>
          <img src={imageModif} alt="Pencil Icone" />
        </Button>
      </Box>,
      <Box>
        <Button sx={{minWidth:"unset", padding:0}}>
          <img src={imageDel} alt="Del Icone" />
        </Button>
      </Box>,),
    createData('Livarijaona Harena', 'Harena', " framavozatovo@gmail.com", 261342978820, 'Voici mon dossier de candidature, et je souhaite intégrer GE-IT, merci', '11/12/2000',

      <Button sx={{minWidth:"unset", padding:0}}>
        <img src={imageModif} alt="Pencil Icone" />
      </Button>
      ,
      <Button sx={{minWidth:"unset", padding:0}}>
        <img src={imageDel} alt="Del Icone" />
      </Button>
    ),
  ];
const styles = useStyles()
  return (
    <Box sx={{ height: 600, width: '100%' }} className={styles.wrapList}>
      <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Loader enable={waiting} />
        <UserCreate
          open={openCreaction}
          setOpen={setopenCreaction}
          userCreated={currentCreation}
          refetch={refetch}
        ></UserCreate>
        <Box component="span" sx={{ p: 2 }}>
          <Button
            data-testid="candidat-list-button-export"
            onClick={handleDownloadExcel}
            variant="outlined"
            color='primary'
            // color="warning"
            style={{ border: 'none', textTransform:"capitalize", fontSize:16, color: '#E85D04'}}
            endIcon={<ExportFile />}
          >
            Export
          </Button>
        </Box>
        <TextField
          id="input-with-icon-textfield"
          inputProps={{ 'data-testid': 'candidat-list-textfield-search' }}
          label=""
          className={styles.inputSearch}
          value={search}
          placeholder= 'Rechercher'
          onChange={handleSearch}
          InputProps={{
            startAdornment: (
              <InputAdornment position="end">
                <Search />
              </InputAdornment>
            ),
          }}
        />
      </Box>

      {/** Ancien Code GEIT*/}

      {/* <DataGrid
        rows={!loading ? (candidaty as any) : []}
        columns={columns}
        pageSize={8}
        rowsPerPageOptions={[5]}
        checkboxSelection
        disableSelectionOnClick
        experimentalFeatures={{ newEditingApi: true }}
      /> */}
      {/** Fin  Ancien Code GEIT*/}


      {/** TABLEAU Herilala*/}
      <Box>
        <TableContainer component={Paper} sx={{padding:'28px', borderRadius:'16px !important',boxShadow:'unset'}} >
          <Table aria-label="collapsible table" className={styles.wrapListTable}>
            <TableHead>
              <TableRow >
                <TableCell align='left' />
                <TableCell align='left'>Nom</TableCell>
                <TableCell align='left'>Prénom</TableCell>
                <TableCell align='left'>Email</TableCell>
                <TableCell align='left'>Téléphones</TableCell>
                <TableCell align='left' >Messages</TableCell>
                <TableCell align='left'>Date de sousmission</TableCell>
                <TableCell align='left'></TableCell>
                <TableCell align='left'></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <Row key={row.name} row={row} />
              ))}
            </TableBody>
          </Table>
          <TableFooter sx={{display:'flex', justifyContent:'flex-end', width:'100%'}}>
            <TableRow >
              <TablePagination
                component="div"
                count={rows.length}
                rowsPerPage={0}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableRow>
          </TableFooter>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default ListeCandidaties;
