import { ActionType } from '../../utils/constant';
import { ActualityType } from '../../utils/interface';
export const ADD_ACTUALITY = 'ADD_ACTUALITY';
export const ADD_ACTUALITIES = 'ADD_ACTUALITIES';
export const SHOW_DIALOG_ACTUALITY = 'SHOW_DIALOG_ACTUALITY';
export const BUTTON_ACTION_DIALOG = 'BUTTON_ACTION_DIALOG';

export const addActuality = (actuality: ActualityType) => {
  return {
    type: ADD_ACTUALITY,
    payload: {
      actuality,
    },
  };
};

export const addActualities = (actualities: ActualityType[]) => {
  return {
    type: ADD_ACTUALITIES,
    payload: { actualities: actualities },
  };
};

export const showDialogActuality = (show: boolean) => {
  return {
    type: SHOW_DIALOG_ACTUALITY,
    payload: {
      show,
    },
  };
};
export const buttonActionDialog = (actionType: ActionType) => {
  return {
    type: BUTTON_ACTION_DIALOG,
    payload: {
      actionType,
    },
  };
};
