import { gql } from '@apollo/client';

export const Books = gql`
  fragment Books on Book {
    id
    title
    description
    page_number
    referral_years
    thematicId
    thematic_title
    published_date
    createdAt
    updatedAt
    ressourceFiles {
      id
      link
      name
      path
      type
      createdAt
      updatedAt
      base64
    }
    thematic {
      id
      title
    }
    items {
      id
      price
      bookId
      createdAt
      updatedAt
    }
  }
`;
