import { gql } from '@apollo/client';

const UPDATE_USER_STATUS = gql`
  mutation UpdateUserStatus($id: String, $status: Boolean) {
    updateUserStatus(id: $id, status: $status)
  }
`;

const UPDATE_USER = gql`
  mutation UpdateUser(
    $id: ID
    $locationId: String
    $lastName: String
    $firstName: String
    $locationTown: String
    $locationCountryId: ID
    $role: String
    $address: String
    $tel: String
    $status: Boolean
    $username: String
    $email: String
    $educationName: String
    $educationYear: String
    $courseId: ID
  ) {
    updateUser(
      id: $id
      locationId: $locationId
      lastName: $lastName
      firstName: $firstName
      locationTown: $locationTown
      locationCountryId: $locationCountryId
      role: $role
      address: $address
      tel: $tel
      status: $status
      username: $username
      email: $email
      educationName: $educationName
      educationYear: $educationYear
      courseId: $courseId
    )
  }
`;

const CREATE_USER = gql`
  mutation CreateUser(
    $lastName: String
    $firstName: String
    $locationTown: String
    $locationCountryId: ID
    $role: String
    $address: String
    $tel: String
    $status: Boolean
    $username: String
    $email: String
    $password: String
    $files: [String]
    $path: [String]
    $educationYear: String
    $educationName: String
  ) {
    createUser(
      lastName: $lastName
      firstName: $firstName
      locationTown: $locationTown
      locationCountryId: $locationCountryId
      role: $role
      password: $password
      address: $address
      tel: $tel
      status: $status
      username: $username
      email: $email
      files: $files
      path: $path
      educationYear: $educationYear
      educationName: $educationName
    )
  }
`;

const UPDATE_USER_PICS = gql`
  mutation UpdateUserPicture($files: String, $userId: String, $previousPicsId: String, $fileName: String) {
    updateUserPicture(files: $files, userId: $userId, previousPicsId: $previousPicsId, fileName: $fileName)
  }
`;

export { UPDATE_USER_STATUS, UPDATE_USER, CREATE_USER, UPDATE_USER_PICS };
