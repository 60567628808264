import React, { FC } from 'react';
import { Drawer, CssBaseline, Box, Toolbar, List, Typography, Divider, IconButton, Button } from '@mui/material';

import { Menu, ChevronLeft, ChevronRight } from '@mui/icons-material';
import { styled, useTheme } from '@mui/material/styles';
import AppBar from '../AppBar/AppBar';
import ListItemAboveDivider from '../../containers/Candidaties/ListItemAboveDivider';
import ListItemUnderDivider from './ListItemUnderDivider';
import AccountMenu from '../AccountMenu/AccountMenu';
import logoWBG from './images/GE-IT_fond_Blanc.svg';
import useStyles from './styles';

interface Props {
  children: Node;
  title?: string;
}

const drawerWidth = 312;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

// Juste a margin header .
const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

const DrawerContainer: FC<Props> = ({ children, title }) => {
  const theme = useTheme();

  const [open, setOpen] = React.useState(true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const styles = useStyles();

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        open={open}
        sx={{ backgroundColor: '#fff', boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.04)' }}
      >
        <Toolbar sx={{ height: 58, minHeight: '58px !important' }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="end"
            sx={{ mr: 2, ...(open && { display: 'none' }) }}
          >
            <Menu />
          </IconButton>
          <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', width: '100%' }}>
            {/* <Typography variant="h6" noWrap component="div">
              {title ? title : ''}
            </Typography> */}
            <AccountMenu />
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <Box style={{ display: 'flex', justifyContent: 'space-between' }}></Box>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeft /> : <ChevronRight />}
          </IconButton>
        </DrawerHeader>
        <Box sx={{ display: 'flex', justifyContent: 'center', margin: 5, alignItems: 'center', marginTop: '-20px' }}>
          <img src={logoWBG} alt="Logo white_BG" />
        </Box>
        <List sx={{ paddingLeft: 2.5, paddingRight: 2.5, marginTop: 'unset' }}>
          <ListItemAboveDivider />
        </List>
        <Box sx={{ paddingLeft: 2.5, paddingRight: 2.5, marginTop: 'unset' }}>
          <Divider />
        </Box>
        <List sx={{ paddingLeft: 2.5, paddingRight: 2.5, marginTop: 'unset' }}>
          <ListItemUnderDivider />
        </List>
      </Drawer>
      <Main open={open} sx={{}}>
        <>
          <DrawerHeader />
          {children}
        </>
      </Main>
    </Box>
  );
};
export default DrawerContainer;
