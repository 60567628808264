import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export default function DeliveryNow(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 28 24" >
      <path d="M24.6665 0H3.33317C1.85317 0 0.666504 1.18667 0.666504 2.66667V21.3333C0.666504 22.8133 1.85317 24 3.33317 24H24.6665C26.1465 24 27.3332 22.8133 27.3332 21.3333V2.66667C27.3332 1.18667 26.1465 0 24.6665 0ZM4.6665 5.33333H11.3332V13.3333H4.6665V5.33333ZM23.3332 18.6667H4.6665V16H23.3332V18.6667ZM23.3332 13.3333H13.9998V10.6667H23.3332V13.3333ZM23.3332 8H13.9998V5.33333H23.3332V8Z" fill="#6C757D" />

    </SvgIcon>
  );
}
