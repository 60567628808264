import { useMutation, useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { downloadExcel } from 'react-export-table-to-excel';
import { useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';
import { UPDATE_USER_STATUS } from '../../../graphql/mutations/user';
import { GET_USERS } from '../../../graphql/querries/users';
import { RessourceFilesTypes, UserTypes } from '../../../utils/types';

const useLogic = () => {
  const { loading, data, refetch } = useQuery(GET_USERS);
  const [updateStatusUser] = useMutation(UPDATE_USER_STATUS);
  const { addToast } = useToasts();
  const { t } = useTranslation();
  const [search, setSearch] = useState<string>('');
  const [users, setUsers] = useState<UserTypes[]>();
  const [waiting, setWaiting] = useState<boolean>(false);
  const [openUserFilesDrawer, setOpenUserFilesDrawer] = useState<boolean>(false);
  const [openUserEdit, setOpenUserEdit] = useState<boolean>(false);
  const [userEdited, setUserEdited] = useState<UserTypes>();
  const [openCreaction, setOpenCreaction] = useState<boolean>(false);
  const [currentCreation, setCurrentCreation] = useState<UserTypes>({} as UserTypes);

  const [currentFiles, setcurrentFiles] = useState<RessourceFilesTypes[]>();
  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(() => event.target.value);
    if (event.target.value !== '') {
      let search_results = data.users.filter(
        (item: UserTypes) =>
          item?.firstName?.toLowerCase().includes(event.target.value.toLowerCase()) ||
          item?.lastName?.toLowerCase().includes(event.target.value.toLowerCase()) ||
          item?.email?.toLowerCase().includes(event.target.value.toLowerCase()),
      );
      setUsers(() => search_results);
    } else {
      setUsers(data.users);
    }
  };

  const handleEditDrawer = (user: UserTypes) => {
    setUserEdited(() => user);
  };

  const handleDownloadExcel = () => {
    const header = ['Pseudo', 'Nom', 'Prenom', 'Email', 'Tel', 'Date de soumission', 'Date de modification'];
    const table: any = [];
    data.users.forEach((element: UserTypes) => {
      table.push({
        username: element.username,
        name: element.firstName,
        surname: element.lastName,
        email: element.email,
        tel: element.tel + '',
        dateCreation: element?.createdAt?.toLocaleDateString(),
        dateModi: element?.updatedAt?.toLocaleDateString(),
      });
    });
    downloadExcel({
      fileName: 'Users' + new Date(Date.now()).toLocaleTimeString(),
      sheet: 'UserTypes',
      tablePayload: {
        header,
        body: table,
      },
    });
  };

  const handleDelete = async (user: UserTypes) => {
    setWaiting(true);
    await updateStatusUser({
      variables: {
        id: user.id,
        status: !user.status,
      },
    })
      .then(() => {
        if (!user.status)
          addToast(t('successActi'), {
            appearance: 'success',
          });
        else
          addToast(t('successDesact'), {
            appearance: 'success',
          });
        refetch();
        setWaiting(false);
      })
      .catch((error) => {
        setWaiting(false);
        addToast(error.message, {
          appearance: 'error',
        });
      });
  };

  const handleFilesDrawer = (files: RessourceFilesTypes[]) => {
    setcurrentFiles(files);
    setOpenUserFilesDrawer(true);
  };

  const handleNewUser = () => {
    setOpenCreaction(true);
  };

  useEffect(() => {
    if (!loading && data) {
      setUsers(data.users);
    }
  }, [data, loading]);

  return {
    loading,
    search,
    handleSearch,
    users,
    handleDownloadExcel,
    handleDelete,
    waiting,
    openUserFilesDrawer,
    handleFilesDrawer,
    setOpenUserFilesDrawer,
    currentFiles,
    setcurrentFiles,
    refetch,
    openUserEdit,
    setOpenUserEdit,
    userEdited,
    setUserEdited,
    handleEditDrawer,
    handleNewUser,
    openCreaction,
    setOpenCreaction,
    currentCreation,
    setCurrentCreation,
  };
};

export default useLogic;
