import { gql } from '@apollo/client';

const IMPORT_DATABASE = gql`
  mutation ImportData($data: String, $overwrite: Boolean) {
    importData(data: $data, overwrite: $overwrite)
  }
`;

const EXPORT_DATABASE = gql`
  mutation Mutation {
    exportData
  }
`;

const REST_DATABASE = gql`
  mutation Mutation($overwrite: Boolean) {
    revertData(overwrite: $overwrite)
  }
`;

export { EXPORT_DATABASE, IMPORT_DATABASE, REST_DATABASE };
