import { gql } from '@apollo/client';

export const Thematics = gql`
  fragment Thematics on Thematic {
    id
    title
    books {
      description
      id
      page_number
      published_date
      referral_years
      ressourceFiles {
        link
        name
        path
        id
      }
      thematicId
      title
    }
  }
`;
