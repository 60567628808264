import { ApolloQueryResult, OperationVariables, useMutation, useQuery } from '@apollo/client';
import { useFormik } from 'formik';
import react, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';
import { UPDATE_USER } from '../../../graphql/mutations/user';
import { GET_COUNTRY } from '../../../graphql/querries/country';
import { GET_DIPLOME } from '../../../graphql/querries/diplome';
import { GET_FORMATION } from '../../../graphql/querries/formation';
import { SelectChangeEvent } from '@mui/material/Select';

import { CountryTypes, FormationType, UserTypes } from '../../../utils/types';
import { validationSchema } from '../validation';

interface Props {
  userEdited: UserTypes | undefined;
  setOpen: react.Dispatch<react.SetStateAction<boolean>>;
  open: boolean;
  refetch: (variables?: Partial<OperationVariables> | undefined) => Promise<ApolloQueryResult<any>>;
}

const ROLE = {
  ADMIN: 'admin',
  STUDENT: 'student',
  MEMBER: 'member',
};

const useLogic = ({ userEdited, setOpen, refetch, open }: Props) => {
  const { addToast } = useToasts();
  const { t } = useTranslation();
  const [updateUser] = useMutation(UPDATE_USER);
  const steps = ['Information', 'Cursus'];
  const [activeStep, setActiveStep] = useState(0);

  const countryQuery = useQuery(GET_COUNTRY);
  const diplomeQuery = useQuery(GET_DIPLOME);
  const formationQuery = useQuery(GET_FORMATION);

  const [{ data: dataDiplome }, { data: dataFormation }, { data: dataCountry }] = [
    diplomeQuery,
    formationQuery,
    countryQuery,
  ];

  const diplome = dataDiplome?.getDeplomes;
  const formation: FormationType[] = (dataFormation?.getEducations as FormationType[]) || ([] as FormationType[]);
  const country: CountryTypes[] = (dataCountry?.getCountry as CountryTypes[]) || ([] as CountryTypes[]);

  const formik = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: (userEdited as UserTypes) || ({} as UserTypes),
    validationSchema,
    onSubmit: (values) => {
      handleEdit(values);
      handleClose();
    },
  });

  const handleClose = () => {
    setActiveStep(0);
    formik.setValues(() => {
      return {} as UserTypes;
    });
    setOpen(false);
  };

  const handleRoleSelect = (e: SelectChangeEvent<string>) => {
    if (e.target.value === ROLE.ADMIN) {
      formik.setFieldValue('Course', undefined);
      formik.setErrors({});
    }

    formik.setFieldValue('Role.type', e.target.value);
  };

  const stepError1 = () =>
    (formik?.errors?.firstName && formik?.errors?.firstName?.length > 0) ||
    (formik?.errors?.Role && formik?.errors?.Role?.length > 0) ||
    (formik?.errors?.lastName && formik?.errors?.lastName?.length > 0) ||
    (formik?.errors?.tel && formik?.errors?.tel?.length > 0) ||
    (formik?.errors?.email && formik?.errors?.email?.length > 0) ||
    (formik?.errors?.username && formik?.errors?.username?.length > 0)
      ? true
      : false;

  const stepError2 = () => (formik?.errors?.Course ? true : false);

  const isLastStep = () => {
    return activeStep === steps.length - 1;
  };

  const handleEdit = async (user: UserTypes) => {
    await updateUser({
      variables: {
        username: user?.username,
        role: user?.Role?.type,
        email: user?.email,
        id: user?.id,
        firstName: user?.firstName,
        lastName: user?.lastName,
        locationId: user?.Location?.id,
        locationTown: user?.Location?.town,
        locationCountryId: user?.Location?.Country?.id,
        address: user?.address,
        tel: user?.tel,
        status: user?.status,
        educationYear: user?.Course && user?.Course[0] && user?.Course[0]?.Curriculum?.year,
        educationName: user?.Course && user?.Course[0] && user?.Course[0]?.Curriculum?.Education?.name,
        courseId: user?.Course && user?.Course[0] && user?.Course[0]?.id,
      },
    })
      .then(() => {
        refetch();
        handleClose();
        addToast(t('successEdit'), {
          appearance: 'success',
        });
      })
      .catch((error) => {
        addToast(error.message, {
          appearance: 'error',
        });
      });
  };

  const handleNext = () => {
    if (!isLastStep()) setActiveStep(activeStep + 1);
    else {
      formik.submitForm();
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => {
      return prevActiveStep - 1;
    });
  };

  const handleStep = (step: number) => () => {
    setActiveStep(step);
  };

  const idDisabledYear = (current: string, year: string): boolean => {
    if (current && year) {
      const y = +current.charAt(1);
      if (current === 'L3' && year === 'M1') return false;
      if (year === current.charAt(0) + (y + 1) + '') return false;
    } else return false;

    return true;
  };

  useEffect(() => {
    if (userEdited && open) formik.setValues(() => userEdited);
  }, [userEdited, open]);

  return {
    country,
    formation,
    handleClose,
    handleNext,
    handleBack,
    handleStep,
    handleRoleSelect,
    ROLE,
    activeStep,
    steps,
    formik,
    stepError1,
    stepError2,
    diplome,
    idDisabledYear,
  };
};

export default useLogic;
