import { gql } from '@apollo/client';
import { Candidatures } from '../fragments/candidature';

const CREATE_CANDIDATY = gql`
  mutation CreateCandidacy(
    $name: String
    $surname: String
    $email: String
    $formation: String
    $tel: String
    $message: String
    $files: [String]
  ) {
    createCandidacy(
      name: $name
      surname: $surname
      email: $email
      formation: $formation
      tel: $tel
      message: $message
      files: $files
    ) {
      ...Candidatures
    }
  }
  ${Candidatures}
`;

const DELETE_CANDIDATY = gql`
  mutation Mutation($id: ID, $deleteFiles: Boolean) {
    deleteCandidacy(id: $id, deleteFiles: $deleteFiles)
  }
`;

export { CREATE_CANDIDATY, DELETE_CANDIDATY };
