import { useMutation } from '@apollo/client';
import { CREATE_ACTUALITY, UPDATE_ACTUALITY, DELETE_ACTUALITY } from '../mutations/actuality';

export const useCreateActualityMutation = () => {
  const [createActualityMutation] = useMutation(CREATE_ACTUALITY);

  return { createActualityMutation };
};

export const useUpdateActualityMutation = () => {
  const [updateActualityMutation] = useMutation(UPDATE_ACTUALITY);

  return { updateActualityMutation };
};

export const useDeleteActualityMutation = () => {
  const [deleteActualityMutation] = useMutation(DELETE_ACTUALITY);

  return { deleteActualityMutation };
};
