import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export default function DeliveryNow(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 32 24">
      <path d="M28 6.66667V5.33333L24 8L20 5.33333V6.66667L24 9.33333M29.3333 0H2.66667C1.95942 0 1.28115 0.280951 0.781049 0.781048C0.280952 1.28115 0 1.95942 0 2.66667L0 21.3333C0 22.0406 0.280952 22.7189 0.781049 23.219C1.28115 23.719 1.95942 24 2.66667 24H29.3333C30.0406 24 30.7189 23.719 31.219 23.219C31.719 22.7189 32 22.0406 32 21.3333V2.66667C32 1.95942 31.719 1.28115 31.219 0.781048C30.7189 0.280951 30.0406 0 29.3333 0ZM10.6667 4C11.7275 4 12.7449 4.42143 13.4951 5.17157C14.2452 5.92172 14.6667 6.93913 14.6667 8C14.6667 9.06087 14.2452 10.0783 13.4951 10.8284C12.7449 11.5786 11.7275 12 10.6667 12C9.6058 12 8.58839 11.5786 7.83824 10.8284C7.08809 10.0783 6.66667 9.06087 6.66667 8C6.66667 6.93913 7.08809 5.92172 7.83824 5.17157C8.58839 4.42143 9.6058 4 10.6667 4ZM18.6667 20H2.66667V18.6667C2.66667 16 8 14.5333 10.6667 14.5333C13.3333 14.5333 18.6667 16 18.6667 18.6667M29.3333 12H18.6667V4H29.3333" fill="#6C757D" />
    </SvgIcon>
  );
}
