import { ActionType } from '../../utils/constant';
import { BookType, ItemType } from '../../utils/interface';

export const ADD_BOOK = 'ADD_BOOK';
export const SHOW_DIALOG_BOOK = 'SHOW_DIALOG_BOOK';
export const BUTTON_ACTION_DIALOG = 'BUTTON_ACTION_DIALOG';
export const ADD_ITEM = 'ADD_ITEM';
export const SHOW_DIALOG_ITEM = 'SHOW_DIALOG_ITEM';
export const BUTTON_ACTION_DIALOG_ITEM = 'BUTTON_ACTION_DIALOG_ITEM';
export const SHOW_BOOK_DETAILS = 'SHOW_BOOK_DETAILS';
export const REFETCH_BOOK = 'REFETCH_BOOK';

export const addBook = (book: BookType) => {
  return {
    type: ADD_BOOK,
    payload: {
      book,
    },
  };
};

export const showDialogBook = (show: boolean) => {
  return {
    type: SHOW_DIALOG_BOOK,
    payload: {
      show,
    },
  };
};

export const showDialogItem = (showItem: boolean) => {
  return {
    type: SHOW_DIALOG_ITEM,
    payload: {
      showItem,
    },
  };
};

export const buttonActionDialog = (actionType: ActionType) => {
  return {
    type: BUTTON_ACTION_DIALOG,
    payload: {
      actionType,
    },
  };
};

export const buttonActionDialogItem = (actionTypeItem: ActionType) => {
  return {
    type: BUTTON_ACTION_DIALOG,
    payload: {
      actionTypeItem,
    },
  };
};

export const addItem = (item: ItemType) => {
  return {
    type: ADD_ITEM,
    payload: {
      item,
    },
  };
};

export const showBookDetails = (open: boolean) => {
  return {
    type: SHOW_BOOK_DETAILS,
    payload: {
      open,
    },
  };
};

export const refetchBook = (refetchBook: boolean) => {
  return {
    type: REFETCH_BOOK,
    payload: {
      refetchBook,
    },
  };
};
