import { DeleteOutline, EditOutlined, PriceChangeOutlined } from '@mui/icons-material';
import { Box, IconButton, Tooltip } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { addBook, showBookDetails } from '../../Redux/Actions/ActionBook';
import { IMAGE_EXTENSIONS } from '../../utils/constant';
import { BookType } from '../../utils/interface';
interface CardBookInterface {
  book: BookType;
  handleDeleteBook: (bookId: string) => Promise<void>;
  handleUpdateBook: (clickedRowBook: any) => Promise<void>;
  handleAddPrice: (bookId: string, price: number) => void;
}

const CardBook: FC<CardBookInterface> = ({ book, handleAddPrice, handleDeleteBook, handleUpdateBook }) => {
  const bookId = book.id ? book.id : '';
  const dispatch = useDispatch();

  const handleShowBookDetails = (book: BookType) => {
    dispatch(showBookDetails(true));
    dispatch(addBook(book));
  };

  const getLink = () => {
    if (book?.ressourceFiles?.length > 0)
      for (const ressource of book?.ressourceFiles) {
        const pathSplit = ressource?.link?.split('.');
        const extension = pathSplit[pathSplit.length - 1].toLowerCase().split('?')[0];
        if (IMAGE_EXTENSIONS.includes(extension)) {
          return ressource.link;
        }
      }
  };
  const linkFile = getLink();

  const price = book?.items?.length > 0 ? parseInt(book?.items[0]?.price) : 0;

  return (
    <Card
      style={{
        width: 230,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        height: 'auto',
        borderRadius: 20,
        // boxShadow: '0 8px 10px rgb(0 0 0 / 0.08)',
        padding: 10,
        margin: 15,
      }}
    >
      <CardMedia
        onClick={() => handleShowBookDetails(book)}
        component="img"
        alt="green iguana"
        height="140"
        src={linkFile}
        style={{ width: '96%', borderRadius: '20px', margin: '2% 2% 6% 2%', minHeight: 300 }}
      />
      <Box style={{ display: 'flex', flexDirection: 'column' }}>
        <CardContent
          style={{
            padding: 0,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: 'auto',
            width: 'auto',
            margin: 10,
          }}
        >
          <Typography variant="h6" style={{ fontWeight: 'bolder', marginBottom: 0 }}>
            {book?.title}
          </Typography>
          <Typography variant="body2" style={{ color: '#6C757D', textTransform: 'capitalize' }}>
            {book?.thematic_title}
          </Typography>
          <Box>
            <span style={{ color: '#e95d04', fontSize: 20, fontWeight: 'bold' }}>{price}</span>{' '}
            <sup style={{ color: '#e95d04', fontSize: 12, fontWeight: 'bold' }}>Ar</sup>
          </Box>
        </CardContent>
      </Box>
      <Box
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-around',
          width: '-webkit-fill-available',
          marginTop: 10,
        }}
      >
        <Tooltip title="Ajouter le prix" placement="bottom" components={{ Tooltip: 'p' }}>
          <IconButton onClick={() => handleAddPrice(bookId, price)}>
            <PriceChangeOutlined
              sx={{
                color: '#e85d04',
                ':hover': {
                  color: '#ff9800',
                },
                ':active': {
                  color: '#ce334d',
                },
              }}
            />
          </IconButton>
        </Tooltip>
        <Tooltip title="Modifier le livre" placement="bottom" components={{ Tooltip: 'p' }}>
          <IconButton
            onClick={() => {
              handleUpdateBook(book);
            }}
          >
            <EditOutlined
              sx={{
                color: '#e85d04',
                ':hover': {
                  color: '#ff9800',
                },
                ':active': {
                  color: '#ce334d',
                },
              }}
            />
          </IconButton>
        </Tooltip>
        <Tooltip title="Supprimer le livre" placement="bottom" components={{ Tooltip: 'p' }}>
          <IconButton onClick={() => handleDeleteBook(bookId)}>
            <DeleteOutline
              sx={{
                color: '#e85d04',
                ':hover': {
                  color: '#ff9800',
                },
                ':active': {
                  color: '#ce334d',
                },
              }}
            />
          </IconButton>
        </Tooltip>
      </Box>
    </Card>
  );
};

export default CardBook;
