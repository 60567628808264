import InputTextField from '../../../components/input/InputTextField';
import { Grid } from '@mui/material';
import { useState } from 'react';
import { CategoryType } from '../../../utils/interface';
import { addCategory } from '../../../Redux/Actions/ActionCategory';
import { useDispatch, useSelector } from 'react-redux';

const CategoryForm = () => {
  const dispatch = useDispatch();
  const category = useSelector((state: any) => state);
  const [value, setValue] = useState<CategoryType>({
    id: category.category.category.id ? category.category.category.id : '',
    name: category.category.category.name ? category.category.category.name : '',
  });
  const handleChange = (e: any) => {
    let next: CategoryType = {
      id: category.category.category.id ? category.category.category.id : '',
      name: category.category.category.name ? category.category.category.name : '',
    };
    switch (e.target.name) {
      case 'name':
        next = { ...value, name: e.target.value };
        setValue(next);
        dispatch(addCategory(next));
        break;

      default:
    }
  };

  return (
    <>
      <Grid container padding={'1% 10% 2% 10%'}>
        <Grid xs={12}>
          <InputTextField
            inputProps={{ 'data-testid': 'actuality-categoryform-field' }}
            style={{ width: '100%' }}
            name="name"
            label="Nom"
            value={value.name}
            onChange={handleChange}
            required
          />
        </Grid>
      </Grid>
    </>
  );
};

export default CategoryForm;
