export const fileToBase64 = (file: any) =>
  new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const { result } = reader;

      if (typeof result === 'string') {
        resolve(result);
      } else {
        reject(new Error('Échec de la lecture du fichier'));
      }
    };
    reader.onerror = (error) => reject(error);
  });
