import { useQuery } from '@apollo/client';
import { GET_PARAMETER } from '../querries/parameter';

export const useParameters = () => {
  const { data, loading, error } = useQuery(GET_PARAMETER, {
    fetchPolicy: 'network-only',
  });

  return { data: data?.getParameters, loading, error };
};
