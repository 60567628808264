import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import './App.css';
import DrawerContainer from './components/DrawerContainer';
import ForgotPassword from './containers/ForgotPassword';
import Login from './containers/Login';
import ResetPassword from './containers/ResetPassword';
import VerifyOTP from './containers/VerifyOTP';
import { GET_ME } from './graphql/querries/Me';
import './index.css';
import Store from './Redux/Store/Store';
import PrivateRoutes from './routes/privateRoutes';
import PrivateRoutesAuth from './routes/PrivateRoutesAuth';
import { LISTE_ROUTES } from './routes/route';
import AuthContext from './utils/AuthContext';
import { UserTypes } from './utils/constant';

const container = (chidren: any, title?: string) => {
  return <DrawerContainer title={title}>{chidren}</DrawerContainer>;
};

const App = () => {
  let authToken = '';
  const { privateRoutes } = PrivateRoutes();
  let tokenExp = 0;
  const initiat = () => {
    if (typeof window !== 'undefined') {
      authToken = localStorage.getItem('credentials') as string;
      tokenExp = authToken ? JSON.parse(atob(authToken.split('.')[1])).exp : 0;
      if (tokenExp && tokenExp * 1000 < Date.now()) localStorage.removeItem('credentials');
    }
  };

  initiat();
  const { data, refetch } = useQuery(GET_ME, {
    context: {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${authToken}`,
      },
    },
  });

  const [me, setMe] = useState<UserTypes | null>(data?.me);

  useEffect(() => {
    if (data?.me) setMe(data?.me);
  }, [authToken, data]);

  const logOut = () => {
    localStorage.removeItem('credentials');
    authToken = '';
    tokenExp = 0;
    setMe(null);
  };

  const login = (token: string, user: UserTypes) => {
    authToken = token;
    localStorage.setItem('credentials', token);
    setMe(user);
  };

  return (
    <Provider store={Store}>
      <AuthContext.Provider value={{ authToken, validity: tokenExp, me, refetch, logOut, login }}>
        <Router>
          <Routes>
            {privateRoutes.map((item) => (
              <Route
                key={item.key}
                path={item.path}
                element={<PrivateRoutesAuth>{container(<item.component />, item.key)}</PrivateRoutesAuth>}
              />
            ))}
            <Route element={<Login />} path={LISTE_ROUTES.LOGIN.path} />
            <Route element={<ForgotPassword />} path={LISTE_ROUTES.FORGOT_PASSWORD.path} />
            <Route element={<VerifyOTP />} path={LISTE_ROUTES.VERIFY_CODE.path} />
            <Route element={<ResetPassword />} path={LISTE_ROUTES.RESET_PASSWORD.path} />
          </Routes>
        </Router>
      </AuthContext.Provider>
    </Provider>
  );
};

export default App;
