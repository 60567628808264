import { FC } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import { Button, FormControl, Radio, Stack, Typography } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputTextField from '../../../components/input/InputTextField';
import { AntSwitch } from './style';
import useLogic from './useLogic';

interface Props {}

/// pkgxndlyecblotoe

const Parametre: FC<Props> = () => {
  const {
    checked,
    checkedSsl,
    handleChecked,
    typeButtonGmail,
    typeButtonSmtp,
    typeButtonCandidat,
    typeButtonContact,
    configGmail,
    configSmtp,
    handleCandidatEmailProvider,
    handleContactEmailProvider,
    handleSaveCandidatureEmail,
    handleSaveContactEmail,
    handleEditCandidatEmailProvider,
    handleEditContactEmailProvider,
    handleCheckedSsl,
    handleFormSmtp,
    handleFormGmail,
    readOnlyGmail,
    readOnlySmtp,
    readOnlyContact,
    candidatProviderEmail,
    contactProviderEmail,
    handleEditGmail,
    handleEditSmtp,
    handleSaveSmtp,
    handleSaveGmail,
    handleReadOnly,
  } = useLogic();

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        <Grid xs={4}>
          <FormControl style={{ display: 'flex' }}>
            <Box style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>
              <FormControlLabel
                control={
                  <Radio
                    data-testid="parameter-radio-base"
                    id="gmail"
                    checked={checked === 0}
                    onChange={(e) => {
                      handleChecked(e);
                    }}
                  />
                }
                label="Basic auth"
              />

              <Stack direction="row" spacing={1} alignItems="center">
                <AntSwitch
                  data-testid="parameter-switch-base"
                  id="readOnlyGmail"
                  checked={readOnlyGmail}
                  onChange={handleReadOnly}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              </Stack>
            </Box>

            <form>
              <Grid xs={12}>
                <InputTextField
                  inputProps={{ 'data-testid': 'parameter-field-base-host' }}
                  style={{ width: '100%' }}
                  name="service"
                  label="Host"
                  value={configGmail.service}
                  onChange={handleFormGmail}
                  disabled={readOnlyGmail}
                  required
                />
              </Grid>
              <Grid xs={12}>
                <InputTextField
                  inputProps={{ 'data-testid': 'parameter-field-base-user' }}
                  style={{ width: '100%' }}
                  name="user"
                  label="User"
                  value={configGmail.user}
                  onChange={handleFormGmail}
                  disabled={readOnlyGmail}
                  required
                />
              </Grid>
              <Grid xs={12}>
                <InputTextField
                  inputProps={{ 'data-testid': 'parameter-field-base-password' }}
                  style={{ width: '100%' }}
                  name="password"
                  label="Password"
                  value={configGmail.password}
                  onChange={handleFormGmail}
                  disabled={readOnlyGmail}
                  required
                />
              </Grid>
              <Grid xs={12}>
                <Button
                  data-testid="parameter-button-base-save"
                  style={{ width: '30%' }}
                  variant="contained"
                  color={typeButtonGmail ? 'primary' : 'success'}
                  disabled={readOnlyGmail}
                  onClick={(e: any) => {
                    typeButtonGmail ? handleEditGmail(e) : handleSaveGmail(e);
                  }}
                >
                  {typeButtonGmail ? 'Edit' : 'Save'}
                </Button>
              </Grid>
            </form>
          </FormControl>
        </Grid>
        <Grid xs={4}>
          <FormControl style={{ display: 'flex' }}>
            <Box style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>
              <FormControlLabel
                control={
                  <Radio
                    data-testid="parameter-radio-smtp"
                    id="smtp"
                    checked={checked === 1}
                    onChange={(e) => {
                      handleChecked(e);
                    }}
                  />
                }
                label="Smtp"
              />
              <Stack direction="row" spacing={1} alignItems="center">
                <AntSwitch
                  data-testid="parameter-switch-smtp"
                  id="readOnlySmtp"
                  checked={readOnlySmtp}
                  onChange={handleReadOnly}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              </Stack>
            </Box>

            <Grid xs={12}>
              <InputTextField
                inputProps={{ 'data-testid': 'parameter-field-smtp-host' }}
                style={{ width: '100%' }}
                name="service"
                label="Host"
                value={configSmtp.service}
                onChange={handleFormSmtp}
                disabled={readOnlySmtp}
                required
              />
            </Grid>
            <Grid xs={12}>
              <InputTextField
                inputProps={{ 'data-testid': 'parameter-field-smtp-user' }}
                style={{ width: '100%' }}
                name="user"
                label="User"
                value={configSmtp.user}
                onChange={handleFormSmtp}
                disabled={readOnlySmtp}
                required
              />
            </Grid>
            <Grid xs={12}>
              <InputTextField
                inputProps={{ 'data-testid': 'parameter-field-smtp-password' }}
                style={{ width: '100%' }}
                name="password"
                label="Password"
                value={configSmtp.password}
                onChange={handleFormSmtp}
                disabled={readOnlySmtp}
                required
              />
            </Grid>
            <Grid xs={12}>
              <InputTextField
                inputProps={{ 'data-testid': 'parameter-field-smtp-port' }}
                style={{ width: '100%' }}
                name="port"
                label="Port"
                value={configSmtp.port}
                onChange={handleFormSmtp}
                disabled={readOnlySmtp}
                required
              />
            </Grid>

            <Grid xs={12}>
              <Typography>SSL : </Typography>
              <Box style={{ display: 'flex', flexDirection: 'row' }}>
                <FormControlLabel
                  control={
                    <Radio
                      data-testid="parameter-radio-sslActive"
                      id="sslActive"
                      disabled={readOnlySmtp}
                      checked={checkedSsl === 0}
                      onClick={handleCheckedSsl}
                    />
                  }
                  label="Active"
                />

                <FormControlLabel
                  control={
                    <Radio
                      data-testid="parameter-radio-sslInactive"
                      id="sslInactive"
                      disabled={readOnlySmtp}
                      onClick={handleCheckedSsl}
                      checked={checkedSsl === 1}
                    />
                  }
                  label="Inactive"
                />
              </Box>
            </Grid>
            <Grid xs={12}>
              <Button
                data-testid="parameter-button-smtp-save"
                style={{ width: '30%' }}
                variant="contained"
                color={typeButtonSmtp ? 'primary' : 'success'}
                disabled={readOnlySmtp}
                onClick={(e: any) => {
                  typeButtonSmtp ? handleEditSmtp(e) : handleSaveSmtp(e);
                }}
              >
                {typeButtonSmtp ? 'Edit' : 'Save'}
              </Button>
            </Grid>
          </FormControl>
        </Grid>
        <Grid xs={4}>
          <FormControl style={{ display: 'flex' }}>
            <Box
              style={{ display: 'flex', justifyContent: 'space-between', paddingRight: '20%', flexDirection: 'row' }}
            >
              <Typography style={{ padding: '2% 0% 2.5% 0%' }}>Mail Notification</Typography>
              <Stack direction="row" spacing={1} alignItems="center">
                <AntSwitch
                  data-testid="parameter-switch-email"
                  id="readOnlyContact"
                  checked={readOnlyContact}
                  onChange={handleReadOnly}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              </Stack>
            </Box>

            <Grid xs={12}>
              <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <InputTextField
                  style={{ width: '100%', paddingRight: '3%' }}
                  inputProps={{ 'data-testid': 'parameter-field-email-email' }}
                  required={true}
                  id="outlined-required"
                  label="Candidature"
                  value={candidatProviderEmail?.candidature}
                  name="email"
                  onChange={handleCandidatEmailProvider}
                  disabled={readOnlyContact}
                />
                <Button
                  data-testid="parameter-button-email-email"
                  style={{ width: '20%', height: '20%' }}
                  variant="contained"
                  color={typeButtonCandidat ? 'primary' : 'success'}
                  disabled={readOnlyContact}
                  onClick={(e: any) => {
                    typeButtonCandidat ? handleEditCandidatEmailProvider(e) : handleSaveCandidatureEmail(e);
                  }}
                >
                  {typeButtonCandidat ? 'Edit' : 'Save'}
                </Button>
              </Box>
            </Grid>
            <Grid xs={12}>
              <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <InputTextField
                  inputProps={{ 'data-testid': 'parameter-field-email-contact' }}
                  style={{ width: '100%', paddingRight: '3%' }}
                  required={true}
                  id="outlined-required"
                  label="Contact"
                  value={contactProviderEmail?.contact}
                  name="email"
                  onChange={handleContactEmailProvider}
                  disabled={readOnlyContact}
                />
                <Button
                  data-testid="parameter-button-email-contact"
                  style={{ width: '20%', height: '20%' }}
                  variant="contained"
                  color={typeButtonContact ? 'primary' : 'success'}
                  disabled={readOnlyContact}
                  onClick={(e: any) => {
                    typeButtonContact ? handleEditContactEmailProvider(e) : handleSaveContactEmail(e);
                  }}
                >
                  {typeButtonContact ? 'Edit' : 'Save'}
                </Button>
              </Box>
            </Grid>
          </FormControl>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Parametre;
