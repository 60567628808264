import InputTextField from '../../../components/input/InputTextField';
import { Button, FormControl, Grid, InputLabel, MenuItem, Select, Typography, MenuProps } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useEffect, useState } from 'react';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { CKEditor } from 'ckeditor4-react';
import { EDITOR_CONFIG, REFERRAL_YEARS } from '../../../utils/constant';
import { useSelector, useDispatch } from 'react-redux';
import { useLogic } from './useLogic';
import { addBook } from '../../../Redux/Actions/ActionBook';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

const BibliothequeForm = () => {
  const { t } = useTranslation();

  const { handleChange, value, setValue, buttonFileNameImage, buttonFileNamePdf, fileUpload, fileUploadPdf } =
    useLogic();
  const action = useSelector((state: any) => state);
  const [description, setDescription] = useState<string>('');
  const dispatch = useDispatch();

  const onChangeContent = (content: string) => {
    setDescription(content);
  };

  useEffect(() => {
    if (description) dispatch(addBook({ ...value, content: description }));
  }, [description, value, dispatch]);

  useEffect(() => {
    if (action.thematic.dataThematics && action.book.actionType === 'SAVE') {
      dispatch(
        addBook({
          id: '',
          title: '',
          published_date: null,
          thematicId: action.thematic.dataThematics[0]?.id,
          referral_years: REFERRAL_YEARS[0],
          page_number: 1,
          image: { imageBase64: '', imageName: '' },
          pdf: { pdfBase64: '', pdfName: '' },
          ressourceFiles: [],
          thematic: { id: '', title: '' },
          thematic_title: '',
          content: '',
          items: [],
        }),
      );
    }
  }, [action.thematic.dataThematics, action.book.actionType, dispatch]);

  return (
    <>
      <Grid container padding={'1% 10% 2% 10%'}>
        <Grid item xs={12}>
          <InputTextField
            style={{ width: '100%' }}
            name="title"
            label="Titre"
            value={value.title}
            onChange={handleChange}
            inputProps={{ 'data-testid': 'title-input' }}
            required
          />
        </Grid>
        <Grid container paddingTop={'1%'}>
          <Grid item xs={6}>
            <label>{t('Library.referencePic')}*:</label>
          </Grid>
          <Grid item xs={6}>
            <label>Document *:</label>
          </Grid>

          <Grid item xs={3}>
            {value.image.imageName && <Typography style={{ color: 'blue' }}>{value.image.imageName}</Typography>}
            {!value.image.imageName && <Typography style={{ color: 'red' }}>{t('Library.noFile')}</Typography>}
          </Grid>
          <Grid item xs={3}>
            <Button variant="contained" style={{ display: 'flex', textTransform: 'none' }} onClick={fileUpload}>
              {buttonFileNameImage}
            </Button>
          </Grid>

          <Grid item xs={3}>
            {value.pdf.pdfName && <Typography style={{ color: 'blue' }}>{value.pdf.pdfName}</Typography>}
            {!value.pdf.pdfName && <Typography style={{ color: 'red' }}>{t('Library.noFile')}</Typography>}
          </Grid>
          <Grid item xs={3}>
            <Button variant="contained" style={{ display: 'flex', textTransform: 'none' }} onClick={fileUploadPdf}>
              {buttonFileNamePdf}
            </Button>
          </Grid>
        </Grid>
        <Grid item container paddingTop={'2%'}>
          <Grid item xs={2}>
            <FormControl fullWidth>
              <InputLabel>{t('Library.referenceYear')} *</InputLabel>
              <Select
                name="referral_years"
                value={value.referral_years}
                label="Année de référence *"
                onChange={handleChange}
                inputProps={{ 'data-testid': 'referral-years' }}
              >
                {REFERRAL_YEARS &&
                  REFERRAL_YEARS.length > 0 &&
                  REFERRAL_YEARS.map((item: any, index: any) => {
                    return (
                      <MenuItem key={index} value={item}>
                        {item}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={2}>
            {action.thematic.dataThematics && action.thematic.dataThematics.length > 0 ? (
              <FormControl fullWidth>
                <InputLabel>Thématique</InputLabel>
                <Select
                  name="thematic"
                  value={value.thematicId}
                  label={t('Library.thematic')}
                  role={'option'}
                  onChange={handleChange}
                  inputProps={{ 'data-testid': 'thematic' }}
                >
                  {action.thematic.dataThematics &&
                    action.thematic.dataThematics.map((item: any) => {
                      return (
                        <MenuItem role={'menuitem'} key={item.id} value={item.id}>
                          {item.title}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            ) : (
              <FormControl fullWidth>
                <InputLabel>{t('thematic')}</InputLabel>
                <Select
                  name="thematic"
                  value={value.thematicId}
                  label={t('Library.thematic')}
                  onChange={handleChange}
                  role={'option'}
                  inputProps={{ 'data-testid': 'thematic' }}
                  disabled={true}
                >
                  {action.thematic.dataThematics &&
                    action.thematic.dataThematics.map((item: any) => {
                      return (
                        <MenuItem role={'menuitem'} key={item.id} value={item.id}>
                          {item.title}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            )}
          </Grid>
          <Grid item xs={2}></Grid>
          <Grid item xs={2}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label={t('Library.publicationYear')}
                value={dayjs(value.published_date, 'DD/MM/YYYY').format('MM/DD/YYYY')}
                inputFormat="DD/MM/YYYY"
                onChange={(newValue) => {
                  setValue({
                    ...value,
                    published_date: dayjs(newValue, 'DD/MM/YYYY').format('DD/MM/YYYY'),
                  });
                  dispatch(
                    addBook({
                      ...value,
                      published_date: dayjs(newValue, 'DD/MM/YYYY').format('DD/MM/YYYY'),
                    }),
                  );
                }}
                renderInput={(params: TextFieldProps) => (
                  <TextField
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      readOnly: true,
                      'data-testid': 'published-date',
                    }}
                  />
                )}
                disableFuture
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={2}>
            <InputTextField
              type={'number'}
              style={{ width: '100%' }}
              name="page_number"
              label={t('Library.numberOfPage')}
              InputProps={{ inputProps: { min: 1, max: 10000 } }}
              value={value.page_number}
              onChange={handleChange}
            />
          </Grid>
        </Grid>

        <Grid item container paddingTop={'2%'}>
          <Grid item xs={12}>
            <label style={{ marginRight: '30px' }}>Description :</label>
          </Grid>
          <Grid item xs={12}>
            {/* <MyStatefulEditor markup={value.content} onchange={onChange} /> */}
            <CKEditor
              name={'content'}
              config={EDITOR_CONFIG}
              onChange={(e: any) => {
                onChangeContent(e.editor.getData());
              }}
              inputProps={{ 'data-testid': 'content' }}
              initData={value.content}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default BibliothequeForm;
