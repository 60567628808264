import { gql } from '@apollo/client';
import { Items } from '../fragments/item';

export const CREATE_ITEM = gql`
  mutation CreateItem($price: Float, $bookId: String) {
    createItem(price: $price, bookId: $bookId) {
      ...Items
    }
  }
  ${Items}
`;
