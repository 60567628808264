import { Box, Stack, IconButton, Switch, TextField } from '@mui/material';
import { Upload, Delete } from '@mui/icons-material';
import { FC } from 'react';
import useLogic from './useLogic';
import Loader from '../../components/Loader';
import { useTranslation } from 'react-i18next';
import useStyles from './styles';
import { AntSwitch } from './styledSwitch';
import Paper from '@mui/material/Paper';

interface Props { }

const ParamCandidaties: FC<Props> = () => {
  const {
    valueText,
    handleChangeParams,
    errorTextField,
    handleSubmitParams,
    handleDeleteFileLink,
    waiting,
    isLocked,
    handleLock,
  } = useLogic();

  const { t } = useTranslation();
  const styles = useStyles()
  return (
    <>
      <Loader enable={waiting} />
      {/* <FormControlLabel
        control={<Switch data-testid="candidat-params-switch" color="primary" onChange={handleLock} value={isLocked} />}
        label="" className={styles.switchMod}
      /> */}
      <Stack direction="row" spacing={1} alignItems="center">
        <AntSwitch
          data-testid="parameter-switch-base"
          id="readOnlyGmail"
          value={isLocked}
          // checked={readOnlyGmail}
          onChange={handleLock}
          inputProps={{ 'aria-label': 'controlled' }}
        />
      </Stack>
      <Paper className={styles.paperParam} sx={{ marginTop: '40px', borderRadius: '16px !important', boxShadow: 'unset' }}>
        <Box sx={{width:"100%", maxWidth:1053,}}>
          <form id="depotForm" style={{ width: '100%', margin: 10}} onSubmit={handleSubmitParams} >

            <Box sx={{ width: '100%', margin: 10,display:'flex', justifyContent:'center' }}>
              <TextField
                id="depot"
                inputProps={{ 'data-testid': 'candidat-params-field-depot' }}
                label={t('depositForm') + ''}
                placeholder="https://"

                value={valueText.depot.link}
                onChange={handleChangeParams}
                variant="outlined"
                sx={{ width: '100%' }}
                className={styles.inputText}
                error={errorTextField.depot}
                InputLabelProps={{ shrink: true }}
                required
                disabled={isLocked}
              />
              <IconButton
                aria-label="delete"
                type="submit"
                data-testid="candidat-params-button-upload-depot"
                disabled={errorTextField.depot || isLocked}
              >
                <Upload />
              </IconButton>
              <IconButton
                aria-label="delete"
                data-testid="candidat-params-button-delete-depot"
                color="error"
                disabled={!valueText.depot.id || isLocked}
                onClick={() => handleDeleteFileLink(valueText.depot)}
              >
                <Delete />
              </IconButton>

            </Box>
          </form>
          <form id="L1Form" style={{ width: '100%', margin: 10 }} onSubmit={handleSubmitParams}>
            <Box sx={{ width: '100%', margin: 10,display:'flex', justifyContent:'center' }}>
              <TextField
                id="L1"
                inputProps={{ 'data-testid': 'candidat-params-field-l1' }}
                label="L1"
                className={styles.inputText}
                placeholder="https://"
                value={valueText.L1.link}
                onChange={handleChangeParams}
                variant="outlined"
                sx={{ width: '100%' }}
                error={errorTextField.L1}
                InputLabelProps={{ shrink: true }}
                required
                disabled={isLocked}
              />
              <IconButton
                aria-label="delete"
                type="submit"
                data-testid="candidat-params-button-upload-l1"
                disabled={errorTextField.L1 || isLocked}
              >
                <Upload />
              </IconButton>
              <IconButton
                aria-label="delete"
                color="error"
                data-testid="candidat-params-button-delete-l1"
                disabled={!valueText.L1.id || isLocked}
                onClick={() => handleDeleteFileLink(valueText.L1)}
              >
                <Delete />
              </IconButton>

            </Box>
          </form>
          <form id="M1Form" style={{ width: '100%', margin: 10 }} onSubmit={handleSubmitParams}>
            <Box sx={{ width: '100%', margin: 10,display:'flex', justifyContent:'center'  }}>
              <TextField
                id="M1"
                inputProps={{ 'data-testid': 'candidat-params-field-m1' }}
                label="L2"
                placeholder="https://"
                className={styles.inputText}
                value={valueText.M1.link}
                onChange={handleChangeParams}
                variant="outlined"
                sx={{ width: '100%' }}
                error={errorTextField.M1}
                InputLabelProps={{ shrink: true }}
                required
                disabled={isLocked}
              />

              <IconButton
                aria-label="delete"
                data-testid="candidat-params-button-upload-m1"
                type="submit"
                disabled={errorTextField.M1 || isLocked}
              >
                <Upload />
              </IconButton>
              <IconButton
                aria-label="delete"
                color="error"
                data-testid="candidat-params-button-delete-m1"
                disabled={!valueText.M1.id || isLocked}
                onClick={() => handleDeleteFileLink(valueText.M1)}
              >
                <Delete />
              </IconButton>
            </Box>
          </form>
          <form id="L3Form" style={{ width: '100%', margin: 10 }} onSubmit={handleSubmitParams}>
            <Box sx={{ width: '100%', margin: 10,display:'flex', justifyContent:'center' }}>
              <TextField
                id="L3"
                inputProps={{ 'data-testid': 'candidat-params-field-l3' }}
                label="L3"
                className={styles.inputText}
                placeholder="https://"
                value={valueText.L3.link}
                onChange={handleChangeParams}
                variant="outlined"
                sx={{ width: '100%' }}
                error={errorTextField.L3}
                InputLabelProps={{ shrink: true }}
                required
                disabled={isLocked}
              />
              <IconButton
                aria-label="delete"
                type="submit"
                data-testid="candidat-params-button-upload-l3"
                disabled={errorTextField.L3 || isLocked}
              >
                <Upload />
              </IconButton>
              <IconButton
                aria-label="delete"
                color="error"
                data-testid="candidat-params-button-delete-l3"
                disabled={!valueText.L3.id || isLocked}
                onClick={() => handleDeleteFileLink(valueText.L3)}
              >
                <Delete />
              </IconButton>

            </Box>
          </form>
        </Box>
      </Paper>

    </>
  );
};

export default ParamCandidaties;
