import { gql } from '@apollo/client';

const UPDATE_PARAMETER_BY_STATUS = gql`
  mutation UpdateParameterStatus($type: String) {
    updateParameterStatus(type: $type)
  }
`;

export { UPDATE_PARAMETER_BY_STATUS };

const UPDATE_PARAMETER = gql`
  mutation UpdateParameter(
    $service: String
    $user: String
    $port: String
    $password: String
    $ssl: Boolean
    $type: String
  ) {
    updateParameter(service: $service, user: $user, port: $port, password: $password, ssl: $ssl, type: $type)
  }
`;

export { UPDATE_PARAMETER };

const CREATE_PARAMETER = gql`
  mutation CreateParameter(
    $type: String
    $service: String
    $port: String
    $user: String
    $password: String
    $status: String
    $ssl: Boolean
  ) {
    createParameter(
      type: $type
      service: $service
      port: $port
      user: $user
      password: $password
      status: $status
      ssl: $ssl
    )
  }
`;

export { CREATE_PARAMETER };
