// import { Settings } from '@mui/icons-material';
import Logout from '@mui/icons-material/Logout';
import { Badge, Divider } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { LISTE_ROUTES } from '../../routes/route';
import AuthContext from '../../utils/AuthContext';
import { getFirstLetter, getName } from '../../utils/avatar';
import { EditOutlined } from '@mui/icons-material';
import ProfileCard from '../ProfilCard/ProfilCard';
import { Typography } from '@mui/material';

import { getUserPicture } from '../../utils/constant';

const AccountMenu = () => {
  const { logOut, me, refetch } = useContext(AuthContext);

  const [openProfilCard, setOpenProfilCard] = useState(false);
  refetch();
  const navigate = useNavigate();

  const email = me?.email ?? '';
  const firstName = me?.firstName;
  const lastName = me?.lastName;
  const username = me?.username;
  const name = getName({ firstName, lastName, username });
  const firstLetter = getFirstLetter({ firstName, lastName, username });
  const handleLogOut = () => {
    logOut();
    navigate(LISTE_ROUTES.LOGIN.path);
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const pictureUrl = getUserPicture(me)?.link;

  return (
    <React.Fragment>
      <ProfileCard setOpen={setOpenProfilCard} open={openProfilCard} initiale={firstLetter} />
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }} onClick={handleClick}>
        <Tooltip title="Account settings">
          <IconButton
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <Avatar
              sx={{
                width: 42,
                height: 42,
                color: '#FF9800',
              }}
              src={pictureUrl || ''}
            // style={{ background: 'linear-gradient(to right, rgba(255,255,255), rgba(255,255,255)' }}
            >
              {firstLetter}
            </Avatar>
          </IconButton>
        </Tooltip>
        <Typography sx={{ fontSize: 16, fontWeight: '600', marginLeft: 2.5, '&:hover': { cursor: 'pointer' } }}>Hawkins</Typography>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={handleClose} style={{ fontSize: 18, color: '#828da0' }}>
          Compte
        </MenuItem>
        <MenuItem onClick={handleClose} style={{ display: 'flex', justifyContent: 'flex-start' }}>
          <Box>
            <Badge
              overlap="circular"
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              onClick={() => {
                setOpenProfilCard(true);
              }}
              badgeContent={
                <EditOutlined
                  sx={{
                    height: 40,
                    width: 40,
                    color: 'black',
                  }}
                />
              }
            >
              <Avatar
                onClick={() => {
                  setOpenProfilCard(true);
                }}
                style={{
                  background: 'linear-gradient(to right, rgb(255,152,0), rgba(255,152,0)',
                  height: 60,
                  width: 60,
                }}
                src={pictureUrl || ''}
              >
                {firstLetter}
              </Avatar>
            </Badge>
          </Box>
          <Box sx={{ marginLeft: 2 }}>
            <Box style={{ color: '#00194e', textTransform: 'capitalize' }}>{name}</Box>
            <Box style={{ color: '#b9c0ca' }}>{email}</Box>
          </Box>
        </MenuItem>
        <Divider />
        {/* <MenuItem onClick={handleGoToSetting}>
          <ListItemIcon>
            <Settings fontSize="small" />
          </ListItemIcon>
          Settings
        </MenuItem> */}
        <MenuItem onClick={handleLogOut}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
};

export default AccountMenu;
