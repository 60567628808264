import { gql } from '@apollo/client';

const GET_NOTIF_CANDIDAT = gql`
  query GetNotifCandidat {
    getNotifCandidat {
      candidature
      type
    }
  }
`;

export { GET_NOTIF_CANDIDAT };

const GET_NOTIF_CONTACT = gql`
  query GetNotifCandidat {
    getNotifContact {
      contact
      type
    }
  }
`;

export { GET_NOTIF_CONTACT };
