import { TextFieldProps, TextField } from '@mui/material';
import * as React from 'react';

interface inputProps extends Omit<TextFieldProps, ''> {
  label: string;
}

const InputTextField = ({ label, ...otherProps }: inputProps) => {
  return <TextField {...{ label }} {...otherProps} />;
};

export default InputTextField;
