import { DeleteOutlined } from '@mui/icons-material';
import { Avatar, IconButton, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material';
import { FC } from 'react';

import { getColor } from '../../utils/avatar';

interface ListItemInterface {
  date: Date | string;
  name: string;
  email: string;
  phone: string;
  firstLetter: string;
  id: string;
  handleDeleteContact: (id: number) => void;
}

const ListItemComponent: FC<ListItemInterface> = ({
  date,
  name,
  email,
  phone,
  firstLetter,
  id,
  handleDeleteContact,
}) => {
  return (
    <>
      <ListItem
        alignItems="center"
        secondaryAction={
          <IconButton aria-label="comment" onClick={() => handleDeleteContact(parseInt(id))}>
            <DeleteOutlined sx={{ fontSize: 28 }} />
          </IconButton>
        }
      >
        <ListItemAvatar>
          <Avatar style={{ background: getColor(name) }}>{firstLetter}</Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={`${date}`}
          secondary={
            <>
              <Typography sx={{ display: 'inline' }} component="span" variant="body2" color="text.primary">
                {name}
              </Typography>
              <Typography>
                {email} / {phone}
              </Typography>
            </>
          }
        />
      </ListItem>
    </>
  );
};

export default ListItemComponent;
