import { LISTE_ROUTES } from '../../routes/route';
import { useLocation, useNavigate } from 'react-router-dom';
import { ListItemText, ListItem, ListItemButton, ListItemIcon } from '@mui/material';
import { PermContactCalendar, Dashboard, Person, LibraryBooks, FiberNew, ThreeP } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
// import styles from '../Card/styles';
import Candidature from '../../components/DrawerContainer/icons/Candidature';
import Bibliotheque from '../../components/DrawerContainer/icons/Bibliotheque';
import Contact from '../../components/DrawerContainer/icons/Contact';
import Actu from '../../components/DrawerContainer/icons/Actualite';
import useStyles from '../../components/DrawerContainer/styles';

const ListItemAboveDivider = () => {

  const location = useLocation()

  const navigate = useNavigate();
  const { t } = useTranslation();
  const goTo = async (path: string) => {
    navigate(path);
  };
  const styles = useStyles()
  

  return (
    <>
      {[
        {
          key: LISTE_ROUTES.DASHBOARD.name,
          path: LISTE_ROUTES.DASHBOARD.path,
          name: t('Divider.dashBoard'),
          icon: <Dashboard />,          
        },
        {
          key: LISTE_ROUTES.CANDIDATY.name,
          path: LISTE_ROUTES.CANDIDATY.path,
          name: t('Divider.candidaty'),
          icon: <Candidature />,
        },
        {
          key: LISTE_ROUTES.CONTACT.name,
          path: LISTE_ROUTES.CONTACT.path,
          name: t('Divider.contact'),
          icon: <Contact />,
        },
        {
          key: LISTE_ROUTES.ACTUALITY.name,
          path: LISTE_ROUTES.ACTUALITY.path,
          name: t('Divider.actuality'),
          icon: <Actu />,
        },
        {
          key: LISTE_ROUTES.BIBLIOTHEQUE.name,
          path: LISTE_ROUTES.BIBLIOTHEQUE.path,
          name: t('Divider.library'),
          icon: <Bibliotheque />,
        },
        { key: LISTE_ROUTES.USER.name, path: LISTE_ROUTES.USER.path, name: t('Divider.user'), icon: <Person /> },
      ].map((item: any) => (
        <ListItem key={item.key} disablePadding className={location.pathname === item.path ? `${styles.active}`: ''} style={{borderRadius:12, height:64,}}>
          <ListItemButton onClick={() => goTo(item.path)} className={styles.texwwwt}>
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText primary={item.name} className={location.pathname === item.path ? `${styles.spanActive}`: ''}/>
          </ListItemButton>
        </ListItem>
      ))}
    </>
  );
};

export default ListItemAboveDivider;
