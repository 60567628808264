import { gql } from '@apollo/client';

const CREATE_CATEGORY = gql`
  mutation CreateCategory($name: String) {
    createCategory(name: $name) {
      id
      name
    }
  }
`;

export { CREATE_CATEGORY };

const UPDATE_CATEGORY = gql`
  mutation UpdateCategory($updateCategoryId: String, $name: String) {
    updateCategory(id: $updateCategoryId, name: $name)
  }
`;

export { UPDATE_CATEGORY };

const DELETE_CATEGORY = gql`
  mutation DeleteCategory($deleteCategoryId: String) {
    deleteCategory(id: $deleteCategoryId) {
      id
      name
      actualities {
        title
      }
    }
  }
`;

export { DELETE_CATEGORY };
