import { Button } from '@mui/material';
import { useEffect, useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import { ActionType } from '../../../utils/constant';
import { useCreateItemMutation } from '../../../graphql/mutationHooks/item';
import { GET_BOOKS } from '../../../graphql/querries/book';
import { useDispatch, useSelector } from 'react-redux';
import { addItem, showDialogItem } from '../../../Redux/Actions/ActionBook';
import { useTranslation } from 'react-i18next';

const ActionItem = () => {
  const { createItemMutation } = useCreateItemMutation();
  const { addToast } = useToasts();
  const { t } = useTranslation();

  const [isDisabled, setDisabled] = useState<boolean>(true);
  const dispatch = useDispatch();
  const action = useSelector((state: any) => state);

  const handleClose = () => {
    dispatch(showDialogItem(false));
    dispatch(
      addItem({
        id: '',
        price: '',
        bookId: '',
      }),
    );
  };

  const handleClicked = async () => {
    if (action.book.actionTypeItem === 'SAVE') {
      await createItemMutation({
        variables: {
          price: parseFloat(action.book.item.price),
          bookId: action.book.item.bookId,
        },
        refetchQueries: [{ query: GET_BOOKS }],
      })
        .then((res: any) => {
          addToast(t('successSaved'), {
            appearance: 'success',
          });
          setDisabled(true);
          handleClose();
        })
        .catch((error: any) => {
          addToast(error.message, {
            appearance: 'error',
          });
        });
      setDisabled(false);
    }
    dispatch(
      addItem({
        id: '',
        price: '',
        bookId: '',
      }),
    );
  };

  useEffect(() => {
    !action.book.item.price ? setDisabled(true) : setDisabled(false);
  }, [action.book.item.price]);

  return (
    <>
      <Button
        variant="contained"
        color={action.book.actionTypeItem === ActionType.SAVE ? 'primary' : 'success'}
        onClick={handleClicked}
        disabled={isDisabled}
        style={{ textTransform: 'none' }}
      >
        {action.book.actionTypeItem === ActionType.SAVE ? ActionType.SAVE : ActionType.EDIT}
      </Button>
    </>
  );
};

export default ActionItem;
