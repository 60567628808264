import * as React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import { FC, useState } from 'react';
import { Breakpoint, Dialog } from '@mui/material';
import { TitleOfDialog } from '../../utils/interface';

export interface DialogTitleProps {
  children?: React.ReactNode;
  onClose: () => void;
  dialogSize: Breakpoint;
  toggleDialogSize: () => void;
}

interface DialogCustomType {
  title: TitleOfDialog;
  Content: () => JSX.Element;
  open: boolean;
  handleClose: () => void;
  Action: () => JSX.Element;
}

const DialogCustomTitle: FC<DialogTitleProps> = ({ toggleDialogSize, dialogSize, children, onClose, ...other }) => {
  return (
    <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} {...other}>
      <div>{children}</div>
      <div>
        <IconButton onClick={toggleDialogSize}>
          {dialogSize === 'md' ? <FullscreenIcon /> : <FullscreenExitIcon />}
        </IconButton>
        {onClose && (
          <IconButton
            onClick={onClose}
            sx={{
              position: 'relative',
              right: '-16px', // positionnement relatif sur l'axe x pour se superposer légèrement sur l'icône d'agrandissement / réduction
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        )}
      </div>
    </DialogTitle>
  );
};

const DialogCustom: FC<DialogCustomType> = ({ Content, title, Action, handleClose, open }) => {
  const [dialogSize, setDialogSize] = useState<Breakpoint>('md');
  const toggleDialogSize = () => {
    if (dialogSize === 'md') {
      setDialogSize('xl');
    } else {
      setDialogSize('md');
    }
  };

  return (
    <div>
      <Dialog maxWidth={dialogSize} fullWidth={true} onClose={() => open} open={open}>
        <DialogCustomTitle onClose={handleClose} dialogSize={dialogSize} toggleDialogSize={toggleDialogSize}>
          {title.title}
        </DialogCustomTitle>
        <DialogContent dividers>
          <Content />
        </DialogContent>
        <DialogActions>
          <Action />
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DialogCustom;
