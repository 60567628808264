import { Download, Search } from '@mui/icons-material';
import { Box, Button, InputAdornment, TextField } from '@mui/material';
import FlatList from 'flatlist-react/lib';
import { useTranslation } from 'react-i18next';
import ListItemComponent from '../../../components/ListItem/ListItem';
import { getFirstLetter, getName } from '../../../utils/avatar';
import { dateAndTimeToString } from '../../../utils/date';
import { ContactTypes } from '../../../utils/types';
import useLogic from './useLogic';

const Contact = () => {
  const { search, contacts, totalCount, handleSearch, handleDownloadExcel, handleLoadMore, handleDelete } = useLogic();
  const { t } = useTranslation();
  const renderContact = (contact: ContactTypes) => {
    const firstName = contact.name;
    const lastName = contact.surname;
    return (
      <ListItemComponent
        key={contact.id}
        date={dateAndTimeToString(contact.createdAt)}
        firstLetter={getFirstLetter({ firstName, lastName })}
        name={getName({ firstName, lastName })}
        email={contact.email}
        phone={contact.tel}
        id={contact.id}
        handleDeleteContact={handleDelete}
      />
    );
  };

  const renderEmptyContact = () => {
    return (
      <Box
        style={{
          textAlign: 'center',
          verticalAlign: 'middle',
          lineHeight: 3,
          color: '#525252',
          fontSize: 18,
        }}
      >
        {t('Contact.noContact')}
      </Box>
    );
  };

  return (
    <Box sx={{ height: 600, width: '100%' }} style={{ padding: '15px 5%' }}>
      <Box
        style={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginBottom: 40,
        }}
      >
        <Button
          onClick={handleDownloadExcel}
          variant="contained"
          endIcon={<Download />}
          style={{ width: '20%', height: 60 }}
          sx={{
            bgcolor: '#388fb1',
            ':hover': {
              bgcolor: '#4c5b69',
            },
            ':active': {
              bgcolor: '#4c5b69',
            },
          }}
        >
          {t('Contact.download')}
        </Button>
        <TextField
          style={{ width: '40%' }}
          sx={{
            '& .MuiInputBase-root.MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: '#ce334d',
                borderWidth: 2,
              },
              '&:hover fieldset': {
                borderColor: '#ff9800',
              },
              '&.Mui-focused fieldset': {
                borderColor: '#ed6c02',
              },
            },
          }}
          id="input-with-icon-textfield"
          label=""
          color="warning"
          value={search}
          onChange={handleSearch}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
          inputProps={{ 'data-testid': 'contact-field-search' }}
          variant="outlined"
          placeholder={t('search') + ''}
        />
      </Box>
      <Box
        style={{
          width: '100%',
          // boxShadow: '0 3px 7px rgb(0 0 0 / 0.2)',
          borderRadius: 5,
          minWidth: 450,
          maxHeight: 580,
          minHeight: 60,
          overflowY: 'auto',
        }}
      >
        <FlatList
          list={contacts}
          renderItem={renderContact}
          renderWhenEmpty={renderEmptyContact}
          loadMoreItems={handleLoadMore}
          hasMoreItems={totalCount !== contacts?.length}
        />
      </Box>
    </Box>
  );
};

export default Contact;
