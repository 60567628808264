import { gql } from "@apollo/client";

const GET_DIPLOME = gql`
  query GetDeplomes {
    getDeplomes {
      createdAt
      id
      type
      updatedAt
    }
  }
`;

export { GET_DIPLOME };
