import { Button } from '@mui/material';
import { useEffect, useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import { ActionType } from '../../../utils/constant';
import { useCreateThematicMutation, useUpdateThematicMutation } from '../../../graphql/mutationHooks/thematic';
import { GET_THEMATICS } from '../../../graphql/querries/thematic';
import { useSelector, useDispatch } from 'react-redux';
import { addThematic, showDialogThematic } from '../../../Redux/Actions/ActionsThematic';
import { showLoader } from '../../../Redux/Actions/ActionLoader';

import { useTranslation } from 'react-i18next';

const Action = () => {
  const { createThematicMutation } = useCreateThematicMutation();
  const { updateThematicMutation } = useUpdateThematicMutation();
  const { addToast } = useToasts();

  const { t } = useTranslation();

  const [isDisabled, setDisabled] = useState<boolean>(true);
  const dispatch = useDispatch();
  const action = useSelector((state: any) => state);

  const handleClose = () => {
    dispatch(showDialogThematic(false));
    dispatch(
      addThematic({
        id: '',
        title: '',
      }),
    );
  };

  const handleClicked = async () => {
    if (action.thematic.actionType === 'EDIT') {
      dispatch(showLoader(true));
      await updateThematicMutation({
        variables: {
          updateThematicId: action.thematic.thematic.id,
          title: action.thematic.thematic.title,
        },
        refetchQueries: [{ query: GET_THEMATICS }],
      })
        .then((res: any) => {
          addToast(t('successEdit'), {
            appearance: 'success',
          });
          setDisabled(false);
          dispatch(showLoader(false));
          handleClose();
        })
        .catch((error) => {
          addToast(error.message, {
            appearance: 'error',
          });
        });
    }
    if (action.thematic.actionType === 'SAVE') {
      dispatch(showLoader(true));
      await createThematicMutation({
        variables: {
          title: action.thematic.thematic.title,
        },
        refetchQueries: [{ query: GET_THEMATICS }],
      })
        .then((res: any) => {
          addToast(t('successCreat'), {
            appearance: 'success',
          });
          setDisabled(true);
          dispatch(showLoader(false));
          handleClose();
        })
        .catch((error: any) => {
          addToast(error.message, {
            appearance: 'error',
          });
        });
      setDisabled(false);
    }

    dispatch(addThematic({ id: '', title: '' }));
  };

  useEffect(() => {
    !action.thematic.thematic.title ? setDisabled(true) : setDisabled(false);
  }, [action.thematic.thematic.title]);

  return (
    <>
      <Button
        variant="contained"
        color={action.thematic.actionType === ActionType.SAVE ? 'primary' : 'success'}
        onClick={handleClicked}
        disabled={isDisabled}
        style={{ textTransform: 'none' }}
      >
        {action.thematic.actionType === ActionType.SAVE ? ActionType.SAVE : ActionType.EDIT}
      </Button>
    </>
  );
};

export default Action;
