import { gql } from '@apollo/client';

export const Items = gql`
  fragment Items on Item {
    bookId
    id
    price
    createdAt
    updatedAt
  }
`;
