import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export default function DeliveryNow(props: SvgIconProps) {
  return (
    <SvgIcon {...props} width="24" height="29" viewBox="0 0 24 29">
      <path d="M12 8.66666C13.0609 8.66666 14.0783 8.24523 14.8284 7.49508C15.5786 6.74494 16 5.72752 16 4.66666C16 3.60579 15.5786 2.58838 14.8284 1.83823C14.0783 1.08808 13.0609 0.666656 12 0.666656C10.9391 0.666656 9.92172 1.08808 9.17157 1.83823C8.42143 2.58838 8 3.60579 8 4.66666C8 5.72752 8.42143 6.74494 9.17157 7.49508C9.92172 8.24523 10.9391 8.66666 12 8.66666ZM12 13.3867C8.85333 10.4667 4.66667 8.66666 0 8.66666V23.3333C4.66667 23.3333 8.85333 25.1333 12 28.0533C15.1467 25.1333 19.3333 23.3333 24 23.3333V8.66666C19.3333 8.66666 15.1467 10.4667 12 13.3867Z" fill="#6C757D" />
    </SvgIcon>
  );
}
