import { BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip } from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

interface ChartInterface {
  title: string;
  labels: string[];
  candidacyLabel: string;
  candidacyData: number[];
  enrolledStudentLabel: string;
  enrolledStudentData: number[];
}
const Chart = ({
  title,
  labels,
  candidacyLabel,
  candidacyData,
  enrolledStudentLabel,
  enrolledStudentData,
}: ChartInterface) => {
  const data = {
    labels: labels,
    datasets: [
      {
        label: candidacyLabel,
        data: candidacyData,
        backgroundColor: '#DC2F02',
        borderColor: 'transparent',
        borderWidth: 5,
        barPercentage: 10,
        barThickness: 20,
        maxBarThickness: 20,
      },
      {
        label: enrolledStudentLabel,
        data: enrolledStudentData,
        backgroundColor: '#FAA307',
        borderColor: 'transparent',
        borderWidth: 5,
        barPercentage: 10,
        barThickness: 20,
        maxBarThickness: 20,
      },
    ],
  };
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    layout: {
      padding: 20,
      borderRadius: 16,
    },
    elements: {
      bar: {
        borderRadius: 50,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          font: {
              size: 16,
          }
        }
      },
      y: {
        beginAtZero: true,
        border: {
          display: false,
          dash: [8, 4],
        },
        ticks: {
          font: {
              size: 16,
          }
        }
      },
    },
    plugins: {
      legend: {
        labels: {
          boxWidth: 10,
          boxHeight: 10,
          useBorderRadius: true,
          borderRadius: 5,
          color: '#6C757D',
          padding: 20,
          font: {
            size: 16,
            family: "'SF Pro Text', sans-serif",
            weight: '400'
          },
        },
      },
      title: {
        display: true,
        text: title,
        color: '#36414C',
        font: {
          size: 24,
          weight: 'bold',
          family: "'SF Pro Text', sans-serif"
        },
      },
    },
  };
  return (
    <div style={{ width: '100%', height: '500px',boxShadow:'0 !important' }}>
      <Bar data={data} options={options} style={{boxShadow:'unset',}} />
    </div>
  );
};
export default Chart;
