import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { useSigninMutation } from '../../graphql/mutationHooks/auth';
import { LISTE_ROUTES } from '../../routes/route';
import AuthContext from '../../utils/AuthContext';
import { containsSpecialChars, validateEmail, validatePassword } from '../../utils/Validation';

interface SignInProps {
  email: string;
  password: string;
}

const useLogic = () => {
  const { login } = useContext(AuthContext);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [signInData, setSignInData] = useState<SignInProps>({
    email: '',
    password: '',
  });
  const [showPassword, setShowPassword] = useState(false);

  const { addToast } = useToasts();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setSignInData({ ...signInData, [name]: value.trim() });
  };

  const emptyErrorMessage = t('Login.errorPass');
  const invalidEmailErrorMessage = t('Login.errorMail');
  const invalidPasswordErrorMessage = t('Login.errorInvalidPass');
  const loginSuccessMessage = t('Login.successAuth');

  const passwordType = showPassword ? 'text' : 'password';

  const handleToggleVisibility = () => {
    setShowPassword(!showPassword);
  };

  const { signinMutation } = useSigninMutation();

  const handleSubmit = async () => {

      // For super admin test -----------------------------------
     if (signInData.email === process.env.REACT_APP_SUPER_ADMIN_EMAIL && signInData.password === process.env.REACT_APP_SUPER_ADMIN_PASS) {
      await signinMutation({
        variables: { email: signInData.email, password: signInData.password },
      })
        .then((res) => {
          setSignInData({ email: '', password: '' });
          login(res.data.signInBack.token, res.data.signInBack.user);
          addToast(loginSuccessMessage, {
            appearance: 'success',
          });
          setSignInData({ email: '', password: '' });
          navigate(LISTE_ROUTES.DASHBOARD.path);
        })
        .catch((error) => {
          addToast(error.message, {
            appearance: 'error',
          });
        });
      return; 
    }
    //-------------------------------------------------------

    if (!signInData.email || !signInData.password) {
      addToast(emptyErrorMessage, {
        appearance: 'error',
      });
    } else if (signInData.email && containsSpecialChars(signInData.email) && !validateEmail(signInData.email)) {
      addToast(invalidEmailErrorMessage, {
        appearance: 'error',
      });
    } else if (signInData.password && !validatePassword(signInData.password)) {
      addToast(invalidPasswordErrorMessage, {
        appearance: 'error',
      });
    } else {
      await signinMutation({
        variables: { email: signInData.email, password: signInData.password },
      })
        .then((res) => {
          setSignInData({ email: '', password: '' });
          login(res.data.signInBack.token, res.data.signInBack.user);
          addToast(loginSuccessMessage, {
            appearance: 'success',
          });
          setSignInData({ email: '', password: '' });
          navigate(LISTE_ROUTES.DASHBOARD.path);
        })
        .catch((error) => {
          addToast(error.message, {
            appearance: 'error',
          });
        });
    }
  };
  return {
    signInData,
    passwordType,
    showPassword,
    handleToggleVisibility,
    handleChange,
    handleSubmit,
  };
};

export default useLogic;
