import { Button } from '@mui/material';
import { useEffect, useState } from 'react';
import { useCreateCategoryMutation, useUpdateCategoryMutation } from '../../../graphql/mutationHooks/category';
import { useToasts } from 'react-toast-notifications';

import { ActionType } from '../../../utils/constant';
import { GET_CATEGORIES } from '../../../graphql/querries/category';
import { useDispatch, useSelector } from 'react-redux';
import { addCategory, showDialogCategory } from '../../../Redux/Actions/ActionCategory';
import { showLoader } from '../../../Redux/Actions/ActionLoader';

import { useTranslation } from 'react-i18next';

const ActionCategory = () => {
  const { createCategoryMutation } = useCreateCategoryMutation();
  const { updateCategoryMutation } = useUpdateCategoryMutation();
  const [isDisabled, setDisabled] = useState<boolean>(false);
  const { addToast } = useToasts();
  const { t } = useTranslation();

  const action = useSelector((state: any) => state);
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(showDialogCategory(false));
    dispatch(
      addCategory({
        id: '',
        name: '',
      }),
    );
  };

  const handleClicked = async () => {
    if (action.category.actionType === 'EDIT') {
      dispatch(showLoader(true));
      await updateCategoryMutation({
        variables: { updateCategoryId: action.category.category.id, name: action.category.category.name },
        refetchQueries: [{ query: GET_CATEGORIES }],
      })
        .then((res: any) => {
          setDisabled(true);
          dispatch(
            addCategory({
              id: '',
              name: '',
            }),
          );
          addToast(t('successEdit'), {
            appearance: 'success',
          });
          dispatch(showLoader(false));
          handleClose();
        })
        .catch((err) => {
          addToast(err, {
            appearance: 'error',
          });
        });
    }
    if (action.category.actionType === 'SAVE') {
      dispatch(showLoader(true));
      await createCategoryMutation({
        variables: {
          name: action.category.category.name,
        },
        refetchQueries: [{ query: GET_CATEGORIES }],
      })
        .then((res: any) => {
          setDisabled(true);
          dispatch(
            addCategory({
              id: '',
              name: '',
            }),
          );
          addToast(t('successCreat'), {
            appearance: 'success',
          });
          dispatch(showLoader(false));
          handleClose();
        })
        .catch((err) => {
          addToast(err, {
            appearance: 'error',
          });
        });
    }
  };
  useEffect(() => {
    if (!action.category.category.name) setDisabled(true);
    else setDisabled(false);
  }, [action.category.category.name]);
  return (
    <>
      <Button
        variant="contained"
        color={action.category.actionType === ActionType.SAVE ? 'primary' : 'success'}
        onClick={handleClicked}
        disabled={isDisabled}
      >
        {action.category.actionType === ActionType.SAVE ? ActionType.SAVE : ActionType.EDIT}
      </Button>
    </>
  );
};

export default ActionCategory;
