import { Button } from '@mui/material';
import { useEffect, useState } from 'react';
import { useCreateActualityMutation, useUpdateActualityMutation } from '../../../graphql/mutationHooks/actuality';
import { useToasts } from 'react-toast-notifications';

import { ActionType, DATE_NOW } from '../../../utils/constant';
import dayjs from 'dayjs';
import { GET_ACTUALITIES } from '../../../graphql/querries/actuality';
import { useSelector, useDispatch } from 'react-redux';
import { addActuality, showDialogActuality } from '../../../Redux/Actions/ActionActuality';
import { ActualityType } from '../../../utils/interface';
import { INITIAL_VALUE_ACTUALITY } from '../../../Redux/Reducers/ReducerActuality';

import { useTranslation } from 'react-i18next';

const Action = () => {
  const { createActualityMutation } = useCreateActualityMutation();
  const { updateActualityMutation } = useUpdateActualityMutation();
  const [actualities, setActualities] = useState<ActualityType>(INITIAL_VALUE_ACTUALITY);
  const [isDisabled, setDisabled] = useState<boolean>(false);
  const [actionType, setActionType] = useState<string>('');
  const { addToast } = useToasts();
  const { t } = useTranslation();
  const action = useSelector((state: any) => state);
  const dispatch = useDispatch();

  useEffect(() => {
    setActualities(action.actuality.actuality);
    setActionType(action.actuality.actionType);
  }, [action.actuality]);

  const handleClose = () => {
    dispatch(showDialogActuality(false));
    dispatch(
      addActuality({
        id: '',
        active: true,
        categoryId: action.category.dataCategories[0]?.id,
        checked: 1,
        default_date: DATE_NOW.format('DD/MM/YYYY'),
        end_date: DATE_NOW.format('DD/MM/YYYY'),
        start_date: DATE_NOW.format('DD/MM/YYYY'),
        image: '',
        title: '',
        content: '',
      }),
    );
  };
  const handleClicked = async () => {
    if (actualities.title === '' || actualities.image === '' || actualities.categoryId === '') {
      return;
    }
    if (actionType === 'EDIT') {
      setDisabled(true);
      handleClose();

      if (actualities.checked === 1) {
        await updateActualityMutation({
          variables: {
            updateActualityId: actualities.id,
            title: actualities.title,
            content: actualities.content,
            image: actualities.image,
            defaultDate: dayjs(actualities.default_date, 'DD/MM/YYYY').format('YYYY/MM/DD'),
            endDate: '',
            startDate: '',
            active: actualities.active,
            categoryId: actualities.categoryId,
          },
          refetchQueries: [{ query: GET_ACTUALITIES }],
        })
          .then((res: any) => {
            addToast(t('successEdit'), {
              appearance: 'success',
            });
            setDisabled(false);
          })
          .catch((error) => {
            addToast(error.message, {
              appearance: 'error',
            });
          });
      }
      if (actualities.checked === 0) {
        setDisabled(true);
        handleClose();
        await updateActualityMutation({
          variables: {
            updateActualityId: actualities.id,
            title: actualities.title,
            content: actualities.content,
            image: actualities.image,
            defaultDate: '',
            endDate: dayjs(actualities.end_date, 'DD/MM/YYYY').format('YYYY/MM/DD'),
            startDate: dayjs(actualities.start_date, 'DD/MM/YYYY').format('YYYY/MM/DD'),
            active: actualities.active,
            categoryId: actualities.categoryId,
          },
          refetchQueries: [{ query: GET_ACTUALITIES }],
        })
          .then((res: any) => {
            addToast(t('successEdit'), {
              appearance: 'success',
            });
            setDisabled(false);
          })
          .catch((error) => {
            addToast(error.message, {
              appearance: 'error',
            });
          });
      }
    }
    if (actionType === 'SAVE') {
      if (actualities.title === '' || actualities.image === '' || actualities.categoryId === '') {
        return;
      }
      if (action.actuality.actuality.checked === 1) {
        setDisabled(true);
        handleClose();
        await createActualityMutation({
          variables: {
            title: actualities.title,
            content: actualities.content,
            image: actualities.image,
            defaultDate: dayjs(actualities.default_date, 'DD/MM/YYYY').format('YYYY/MM/DD'),
            endDate: '',
            startDate: '',
            active: actualities.active,
            categoryId: actualities.categoryId,
          },
          refetchQueries: [{ query: GET_ACTUALITIES }],
        })
          .then((res: any) => {
            addToast(t('successSaved'), {
              appearance: 'success',
            });
            setDisabled(false);
          })
          .catch((error) => {
            addToast(error.message, {
              appearance: 'error',
            });
          });
      }
      if (action.actuality.actuality.checked === 0) {
        setDisabled(true);
        handleClose();
        await createActualityMutation({
          variables: {
            title: actualities.title,
            image: actualities.image,
            defaultDate: '',
            endDate: dayjs(actualities.end_date, 'DD/MM/YYYY').format('YYYY/MM/DD'),
            startDate: dayjs(actualities.start_date, 'DD/MM/YYYY').format('YYYY/MM/DD'),
            active: actualities.active,
            categoryId: actualities.categoryId,
            content: actualities.content,
          },
          refetchQueries: [{ query: GET_ACTUALITIES }],
        })
          .then((res: any) => {
            addToast(t('successSaved'), {
              appearance: 'success',
            });
            setDisabled(false);
          })
          .catch((error) => {
            addToast(error.message, {
              appearance: 'error',
            });
          });
      }
    }

    dispatch(
      addActuality({
        id: '',
        active: true,
        categoryId: action.category.dataCategories[0]?.id,
        checked: 1,
        default_date: DATE_NOW.format('DD/MM/YYYY'),
        end_date: DATE_NOW.format('DD/MM/YYYY'),
        start_date: DATE_NOW.format('DD/MM/YYYY'),
        image: '',
        title: '',
        content: '',
      }),
    );
  };

  useEffect(() => {
    if (!action.actuality.actuality.image || !action.actuality.actuality.categoryId) setDisabled(true);
    else setDisabled(false);
  }, [action.actuality.actuality.image, action.actuality.actuality.categoryId]);
  return (
    <>
      <Button
        data-testid="actuality-form-save"
        variant="contained"
        color={actionType === ActionType.SAVE ? 'primary' : 'success'}
        onClick={handleClicked}
        disabled={isDisabled}
        style={{ textTransform: 'none' }}
      >
        {actionType === ActionType.SAVE ? ActionType.SAVE : ActionType.EDIT}
      </Button>
    </>
  );
};

export default Action;
