import { gql } from '@apollo/client';

const SIGNINFRONT = gql`
  mutation SignInFront($email: String!, $password: String!) {
    signInFront(email: $email, password: $password) {
      token
    }
  }
`;

const SIGNINBACK = gql`
  mutation SignInBack($email: String!, $password: String!) {
    signInBack(email: $email, password: $password) {
      user {
        username
        updatedAt
        tel
        status
        password
        lastName
        id
        firstName
        email
        createdAt
        address
        Role {
          id
          type
          createdAt
          updatedAt
        }
        RessourceFiles {
          id
          link
          path
          name
          base64
          createdAt
          updatedAt
        }
        PaymentAccount {
          id
          status
          createdAt
          updatedAt
        }
        Location {
          id
          town
          createdAt
          updatedAt
        }
        Course {
          id
          status
          createdAt
          updatedAt
        }
      }
      token
    }
  }
`;

export { SIGNINFRONT, SIGNINBACK };
