import { LISTE_ROUTES } from '../../routes/route';
import { useLocation, useNavigate } from 'react-router-dom';
import { ListItemText, ListItem, ListItemButton, ListItemIcon } from '@mui/material';
import { Settings } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import useStyles from './styles';

const ListItemUnderDivider = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const goTo = async (path: string) => {
    navigate(path);
  };

  const styles = useStyles()

  const location = useLocation();
  return (
    <>
      {[
        {
          key: LISTE_ROUTES.PARAMETRE.name,
          path: LISTE_ROUTES.PARAMETRE.path,
          name: t('Divider.parameter'),
          icon: <Settings />,
        },
      ].map((item: any) => (
        <ListItem key={item.key} disablePadding className={location.pathname === item.path ? `${styles.active}`: ''} style={{borderRadius:12, height:64,}}>
          <ListItemButton onClick={() => goTo(item.path)} className={styles.texwwwt}>
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText primary={item.name} className={location.pathname === item.path ? `${styles.spanActive}`: ''}/>
          </ListItemButton>
        </ListItem>
      ))}
    </>
  );
};

export default ListItemUnderDivider;
