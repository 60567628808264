type InputEvent = Event & { target: HTMLInputElement };

export const openFileUploadDialog = (): Promise<InputEvent> => {
  return new Promise((resolve) => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/jpg, image/png, image/jpeg';
    input.onchange = (event) => resolve(event as InputEvent);
    input.click();
  });
};

export const openPdfUploadDialog = (): Promise<InputEvent> => {
  return new Promise((resolve) => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = '.pdf,.pptx,.odp';
    input.onchange = (event) => resolve(event as InputEvent);
    input.click();
  });
};
