import { useMutation } from '@apollo/client';
import {
  CREATE_NOTIF_CANDIDAT,
  CREATE_NOTIF_CONTACT,
  UPDATE_NOTIF_CANDIDAT,
  UPDATE_NOTIF_CONTACT,
} from '../mutations/notification';

export const useCreateNotifCandidatMutation = () => {
  const [createNotifCandidatMutation] = useMutation(CREATE_NOTIF_CANDIDAT);

  return { createNotifCandidatMutation };
};

export const useCreateNotifContactMutation = () => {
  const [createNotifContactMutation] = useMutation(CREATE_NOTIF_CONTACT);

  return { createNotifContactMutation };
};

export const useUpdateNotifCandidatMutation = () => {
  const [updateNotifCandidatMutation] = useMutation(UPDATE_NOTIF_CANDIDAT);

  return { updateNotifCandidatMutation };
};

export const useUpdateNotifContactMutation = () => {
  const [updateNotifContactMutation] = useMutation(UPDATE_NOTIF_CONTACT);

  return { updateNotifContactMutation };
};
