import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../utils/localStorage';
import { LISTE_ROUTES } from './route';

const PrivateRoutesAuth = ({ children }: any) => {
  const auth = useAuth();
  const token = localStorage.getItem('credentials');
  const tokenExp = token ? JSON.parse(atob(token.split('.')[1])).exp : 0;
  if (!auth || (tokenExp && tokenExp * 1000 < Date.now())) {
    return <Navigate to={LISTE_ROUTES.LOGIN.path} />;
  }
  return children;
};

export default PrivateRoutesAuth;
