import { useMutation } from '@apollo/client';
import react, { useState } from 'react';
import AvatarEditor from 'react-avatar-editor';
import { useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';
import { UPDATE_USER_PICS } from '../../graphql/mutations/user';
import { UserTypes } from '../../utils/constant';
import { fileToBase64 } from '../../utils/fileToBase64';
import { RessourceFilesTypes } from '../../utils/types';
interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  me: UserTypes | null;
  refetch: () => void;
  picsRessouce: RessourceFilesTypes | null | undefined;
}

const useLogic = ({ open, setOpen, me, refetch, picsRessouce }: Props) => {
  const { t } = useTranslation();
  const [updatePics, { loading }] = useMutation(UPDATE_USER_PICS);
  const { addToast } = useToasts();
  const [photo, setPhoto] = useState<File>();
  const [picsScale, setPicsScale] = useState<number>(1);

  const handleValueScale = (e: any) => {
    if (e?.target?.value) {
      setPicsScale(e?.target?.value);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleUpload = (e: react.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files) {
      setPhoto(files[0]);
    }
  };

  const handleDrop = (e: any) => {
    if (e && e?.length) setPhoto(e[0]);
  };

  const handleUpdate = async (editorRef?: React.RefObject<AvatarEditor>) => {
    if (editorRef?.current) {
      const canvas: HTMLCanvasElement = editorRef?.current?.getImage();
      fetch(canvas.toDataURL())
        .then((res) => res.blob())
        .then(async (blob) => {
          const fileString = await fileToBase64(blob).catch(console.error);
          await updatePics({
            variables: {
              files: fileString?.split(',')[1],
              userId: me?.id,
              previousPicsId: picsRessouce?.id ? picsRessouce?.id + '' : undefined,
              fileName: photo?.name,
            },
          })
            .then(() => {
              addToast(t('successAdd'), {
                appearance: 'success',
              });
              refetch();
              setPhoto(undefined);
              handleClose();
            })
            .catch((error) => {
              addToast(error.message, {
                appearance: 'error',
              });
              handleClose();
            });
        });
    }
  };

  return {
    handleClose,
    photo,
    picsScale,
    handleUpload,
    handleUpdate,
    handleValueScale,
    loading,
    handleDrop,
  };
};

export default useLogic;
