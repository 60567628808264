import { gql } from '@apollo/client';
import { Thematics } from '../fragments/thematic';

const GET_THEMATICS = gql`
  query GetThematics {
    getThematics {
      ...Thematics
    }
  }
  ${Thematics}
`;

export { GET_THEMATICS };
