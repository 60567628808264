import { gql } from '@apollo/client';
import { Categories } from '../fragments/category';

const GET_CATEGORIES = gql`
  query GetCategories {
    getCategories {
      ...Categories
    }
  }
  ${Categories}
`;

export { GET_CATEGORIES };
