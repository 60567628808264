import React, { useEffect, useState } from 'react';
import { useParameters } from '../../../graphql/consumerHooks/parameter';
import {
  useCreateParameterMutation,
  useParameterByStatusMutation,
  useParameterMutation,
} from '../../../graphql/mutationHooks/parameter';
import {
  useCreateNotifCandidatMutation,
  useCreateNotifContactMutation,
  useUpdateNotifCandidatMutation,
  useUpdateNotifContactMutation,
} from '../../../graphql/mutationHooks/notification';
import { useNotifCandidat, useNotifContact } from '../../../graphql/consumerHooks/notification';
import { configCandidatProviderMailType, configContactProviderMailType, configType, parameterType } from './interface';
import { useToasts } from 'react-toast-notifications';
import { useTranslation } from 'react-i18next';

const INTIALISATION_CONFIG_GMAIL: configType = {
  type: 'gmail',
  service: '',
  user: '',
  password: '',
  port: '',
  ssl: false,
};
const INTIALISATION_CONFIG_SMTP: configType = {
  type: 'smtp',
  service: '',
  user: '',
  password: '',
  port: '',
  ssl: false,
};

const useLogic = () => {
  const { t } = useTranslation();
  const [checked, setChecked] = useState<number>(0);
  const [typeButtonCandidat, setTypeButtonCandidat] = useState<boolean>(false);
  const [typeButtonContact, setTypeButtonContact] = useState<boolean>(false);
  const [typeButtonGmail, setTypeButtonGmail] = useState<boolean>(false);
  const [typeButtonSmtp, setTypeButtonSmtp] = useState<boolean>(false);

  const [readOnlyGmail, setReadOnlyGmail] = useState<boolean>(true);
  const [readOnlySmtp, setReadOnlySmtp] = useState<boolean>(true);
  const [readOnlyCandidature, setReadOnlyCandidature] = useState<boolean>(true);
  const [readOnlyContact, setReadOnlyContact] = useState<boolean>(true);
  const [checkedSsl, setCheckedSsl] = useState<number>(0);

  const [configGmail, setConfigGmail] = useState<configType>(INTIALISATION_CONFIG_GMAIL);

  const [configSmtp, setConfigSmtp] = useState<configType>(INTIALISATION_CONFIG_SMTP);

  const [candidatProviderEmail, setCandidatProviderEmail] = useState<configCandidatProviderMailType>({
    candidature: '',
    type: 'emailCandidatureProvider',
  });

  const [contactProviderEmail, setContactProviderEmail] = useState<configContactProviderMailType>({
    contact: '',
    type: 'emailContactProvider',
  });

  const { updateParameterByStatusMutation } = useParameterByStatusMutation();
  const { updateParameterMutation } = useParameterMutation();
  const { createParameterMutation } = useCreateParameterMutation();
  const { createNotifCandidatMutation } = useCreateNotifCandidatMutation();
  const { createNotifContactMutation } = useCreateNotifContactMutation();
  const { updateNotifCandidatMutation } = useUpdateNotifCandidatMutation();
  const { updateNotifContactMutation } = useUpdateNotifContactMutation();
  const { data: dataNotifCandidat, error, loading: loadingCandidat } = useNotifCandidat();
  const { data: dataNotifContact, loading: loadingContact } = useNotifContact();
  const { data: dataParameters, loading: loadingParameters } = useParameters();
  const { addToast } = useToasts();

  const handleChecked = (e: any) => {
    if (e.target.id === 'smtp') {
      setChecked(1);
      updateParameterByStatusMutation({
        variables: { type: 'smtp' },
      });
    } else if (e.target.id === 'gmail') {
      setChecked(0);
      updateParameterByStatusMutation({
        variables: { type: 'gmail' },
      });
    }
  };

  const handleReadOnly = (e: any) => {
    if (e.target.id === 'readOnlyGmail') setReadOnlyGmail(!readOnlyGmail);
    if (e.target.id === 'readOnlySmtp') setReadOnlySmtp(!readOnlySmtp);
    if (e.target.id === 'readOnlyCandidature') setReadOnlyCandidature(!readOnlyCandidature);
    if (e.target.id === 'readOnlyContact') setReadOnlyContact(!readOnlyContact);
  };

  const handleEditCandidatEmailProvider = async (e: React.FormEvent<HTMLFormElement>) => {
    await updateNotifCandidatMutation({
      variables: {
        candidature: candidatProviderEmail.candidature,
        type: candidatProviderEmail.type,
      },
    }).then((res) => {
      setReadOnlyContact(true);
      addToast(t('successEdit'), {
        appearance: 'success',
      });
    });
  };

  const handleEditContactEmailProvider = async (e: React.FormEvent<HTMLFormElement>) => {
    await updateNotifContactMutation({
      variables: {
        contact: contactProviderEmail.contact,
        type: contactProviderEmail.type,
      },
    }).then((res) => {
      setReadOnlyContact(true);
      addToast(t('successEdit'), {
        appearance: 'success',
      });
    });
  };

  const handleSaveCandidatureEmail = async (e: React.FormEvent<HTMLFormElement>) => {
    await createNotifCandidatMutation({
      variables: {
        candidature: candidatProviderEmail.candidature,
        type: candidatProviderEmail.type,
      },
    }).then((res) => {
      setTypeButtonCandidat(true);
      setReadOnlyContact(true);
      addToast(t('successSaved'), {
        appearance: 'success',
      });
    });
  };
  const handleSaveContactEmail = async (e: React.FormEvent<HTMLFormElement>) => {
    await createNotifContactMutation({
      variables: {
        contact: contactProviderEmail.contact,
        type: contactProviderEmail.type,
      },
    }).then((res) => {
      setTypeButtonContact(true);
      setReadOnlyContact(true);
      addToast(t('successSaved'), {
        appearance: 'success',
      });
    });
  };

  const handleCheckedSsl = (e: any) => {
    if (e.target.id === 'sslActive') {
      setCheckedSsl(0);
      setConfigSmtp({ ...configSmtp, ssl: true });
    } else if (e.target.id === 'sslInactive') {
      setCheckedSsl(1);
      setConfigSmtp({ ...configSmtp, ssl: false });
    }
  };

  const handleSaveGmail = async (value: any) => {
    let status: string = '';
    if (!configGmail.service || !configGmail.user || !configGmail.password) {
      addToast(t('errorCompleteField'), {
        appearance: 'error',
      });
      return;
    }

    if (checked === 0) status = '1';
    if (checked === 1) status = '0';
    await createParameterMutation({
      variables: {
        service: configGmail.service,
        user: configGmail.user,
        port: configGmail.port,
        password: configGmail.password,
        ssl: configGmail.ssl,
        status: status,
        type: 'gmail',
      },
    }).then((res) => {
      setTypeButtonGmail(true);
      setReadOnlyGmail(true);
      addToast(t('successSaved'), {
        appearance: 'success',
      });
    });
  };

  const handleSaveSmtp = async (e: React.FormEvent<HTMLFormElement>) => {
    let status: string = '';
    if (!configSmtp.service || !configSmtp.user || !configSmtp.password || !configSmtp.port) {
      addToast(t('errorCompleteField'), {
        appearance: 'error',
      });
      return;
    }
    if (checked === 1) status = '1';
    if (checked === 0) status = '0';
    await createParameterMutation({
      variables: {
        service: configSmtp.service,
        port: configSmtp.port,
        user: configSmtp.user,
        password: configSmtp.password,
        ssl: configSmtp.ssl,
        status: status,
        type: 'smtp',
      },
    }).then((res) => {
      setTypeButtonSmtp(true);
      setReadOnlySmtp(true);
      addToast(t('successSaved'), {
        appearance: 'success',
      });
    });
  };

  const handleEditGmail = async (e: any) => {
    if (!configGmail.service || !configGmail.user || !configGmail.password) {
      addToast(t('errorCompleteField'), {
        appearance: 'error',
      });
      return;
    }
    await updateParameterMutation({
      variables: {
        service: configGmail.service,
        user: configGmail.user,
        port: configGmail.port,
        password: configGmail.password,
        ssl: configGmail.ssl,
        type: 'gmail',
      },
    }).then((res) => {
      setReadOnlyGmail(true);
      addToast(t('successEdit'), {
        appearance: 'success',
      });
    });
  };

  const handleEditSmtp = async (e: React.FormEvent<HTMLFormElement>) => {
    if (!configSmtp.service || !configSmtp.user || !configSmtp.password) {
      addToast(t('errorCompleteField'), {
        appearance: 'error',
      });
      return;
    }
    await updateParameterMutation({
      variables: {
        service: configSmtp.service,
        port: configSmtp.port,
        user: configSmtp.user,
        password: configSmtp.password,
        ssl: configSmtp.ssl,
        type: 'smtp',
      },
    }).then((res) => {
      setReadOnlySmtp(true);
      addToast(t('successEdit'), {
        appearance: 'success',
      });
    });
  };

  useEffect(() => {
    if (checked === 0 && dataParameters) {
      dataParameters.map((item: parameterType) => {
        if (item.status === '1' && item.type === 'smtp') {
          setChecked(1);
        }
        if (item.status === '1' && item.type === 'gmail') {
          setChecked(0);
        }
      });
    }
  }, [dataParameters, checked]);

  useEffect(() => {
    if (dataNotifCandidat)
      dataNotifCandidat.map((res: configCandidatProviderMailType) => {
        setCandidatProviderEmail({
          candidature: res.candidature,
          type: res.type,
        });
      });

    if (!loadingCandidat && dataNotifCandidat?.length === 0) setTypeButtonCandidat(false);
    else if (!loadingCandidat && dataNotifCandidat?.length > 0) setTypeButtonCandidat(true);
  }, [dataNotifCandidat, loadingCandidat]);

  useEffect(() => {
    if (dataNotifContact)
      dataNotifContact.map((res: configContactProviderMailType) => {
        setContactProviderEmail({
          contact: res.contact,
          type: res.type,
        });
      });

    if (!loadingContact && dataNotifContact?.length === 0) setTypeButtonContact(false);
    else if (!loadingContact && dataNotifContact?.length > 0) setTypeButtonContact(true);
  }, [dataNotifContact, loadingContact]);

  useEffect(() => {
    if (!loadingParameters && dataParameters?.length === 0) {
      setTypeButtonGmail(false);
      setTypeButtonSmtp(false);
    } else if (!loadingParameters && dataParameters?.length > 0) {
      dataParameters.map((res: configType) => {
        res.type === 'smtp' ? setTypeButtonSmtp(true) : setTypeButtonGmail(true);
      });
    }

    if (!loadingParameters && dataParameters) {
      dataParameters.map((res: configType) => {
        if (res.type === 'gmail') {
          setConfigGmail({
            type: res.type,
            service: res.service,
            port: res.port,
            password: res.password,
            ssl: false,
            user: res.user,
          });
        } else if (res.type === 'smtp') {
          res.ssl ? setCheckedSsl(0) : setCheckedSsl(1);
          setConfigSmtp({
            type: res.type,
            service: res.service,
            port: res.port,
            password: res.password,
            ssl: res.ssl,
            user: res.user,
          });
        }
      });
    }
  }, [dataParameters, loadingParameters]);

  const handleCandidatEmailProvider = (e: React.ChangeEvent<HTMLInputElement>) => {
    let next = { candidature: '', type: 'emailCandidatureProvider' };
    switch (e.target.name) {
      case 'email':
        next = { ...candidatProviderEmail, candidature: e.target.value };
        setCandidatProviderEmail(() => next);
        break;

      default:
    }
  };

  const handleContactEmailProvider = (e: React.ChangeEvent<HTMLInputElement>) => {
    let next = { contact: '', type: 'emailContactProvider' };
    switch (e.target.name) {
      case 'email':
        next = { ...contactProviderEmail, contact: e.target.value };
        setContactProviderEmail(() => next);
        break;

      default:
    }
  };

  const handleFormGmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    let next = { type: 'gmail', service: '', user: '', password: '', port: '', ssl: false };
    switch (e.target.name) {
      case 'service':
        next = { ...configGmail, service: e.target.value };
        setConfigGmail(() => next);
        break;

      case 'user':
        next = { ...configGmail, user: e.target.value };
        setConfigGmail(() => next);
        break;

      case 'password':
        next = { ...configGmail, password: e.target.value };
        setConfigGmail(() => next);
        break;

      default:
    }
  };

  const handleFormSmtp = (e: React.ChangeEvent<HTMLInputElement>) => {
    let next = { type: 'smtp', service: '', user: '', password: '', port: '', ssl: false };
    switch (e.target.name) {
      case 'service':
        next = { ...configSmtp, service: e.target.value };
        setConfigSmtp(() => next);
        break;
      case 'port':
        next = { ...configSmtp, port: e.target.value };
        setConfigSmtp(() => next);
        break;

      case 'user':
        next = { ...configSmtp, user: e.target.value };
        setConfigSmtp(() => next);
        break;

      case 'password':
        next = { ...configSmtp, password: e.target.value };
        setConfigSmtp(() => next);
        break;

      default:
    }
  };

  return {
    handleSaveGmail,
    handleSaveSmtp,
    handleEditSmtp,
    handleEditGmail,
    handleChecked,
    handleReadOnly,
    handleCandidatEmailProvider,
    handleContactEmailProvider,
    handleSaveCandidatureEmail,
    handleSaveContactEmail,
    handleEditCandidatEmailProvider,
    handleEditContactEmailProvider,
    checked,
    checkedSsl,
    typeButtonGmail,
    typeButtonSmtp,
    typeButtonCandidat,
    typeButtonContact,
    configGmail,
    configSmtp,
    handleCheckedSsl,
    handleFormGmail,
    handleFormSmtp,
    readOnlyGmail,
    readOnlySmtp,
    readOnlyContact,
    candidatProviderEmail,
    contactProviderEmail,
  };
};

export default useLogic;
