import { useQuery } from '@apollo/client';
import {
  Box,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputAdornment,
  LinearProgress,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material';
import { useEffect, useState, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import CardBook from '../../../components/Card/CardBook';
import { useDeleteBookMutation } from '../../../graphql/mutationHooks/Book';
import { GET_BOOKS } from '../../../graphql/querries/book';
import {
  addBook,
  addItem,
  buttonActionDialog,
  buttonActionDialogItem,
  refetchBook,
  showDialogBook,
  showDialogItem,
} from '../../../Redux/Actions/ActionBook';
import { ActionType, REFERRAL_YEARS } from '../../../utils/constant';
import { BookType, RessourceFileType } from '../../../utils/interface';

import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import CardAddBook from '../../../components/Card/CardAddBook';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Search } from '@mui/icons-material';
import { ChangeEvent } from 'react';

const BibliothequeList = () => {
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const dispatch = useDispatch();
  const action = useSelector((state: any) => state);
  const [books, setBooks] = useState<BookType[]>([]);
  const [isDeleted, setIsDeleted] = useState(false);
  const [page, setPage] = useState(1);
  const [isEnd, setisEnd] = useState(false);
  const [search, setSearch] = useState('');
  const [inSearch, setInSearch] = useState(false);
  const [filter, setFilter] = useState('');
  const [searchText, setSearchText] = useState('');

  const limit = 10;

  const { deleteBookMutation } = useDeleteBookMutation();

  const { refetch, loading } = useQuery(GET_BOOKS, {
    fetchPolicy: `network-only`,
    variables: {
      limit,
      page,
      filter,
      search,
    },
    onCompleted: (data) => {
      if (!inSearch) {
        setBooks((books) => [...books, ...data.getBooks.books]);
      } else {
        setBooks(() => [...data.getBooks.books]);
      }
      setInSearch(false);
      if (data?.getBooks?.books?.length < 1) setisEnd(true);
      else setisEnd(false);
    },
  });

  const handleUpdateBook = async (clickedRowBook: any) => {
    let isImageFound: boolean = false;
    const bookWithImage = { imageUrl: '', pdfUrl: '', pathImage: '', pathPdf: '' };
    // eslint-disable-next-line array-callback-return
    clickedRowBook.ressourceFiles.map((item: RessourceFileType) => {
      const length = item?.path?.split('/').length - 1;
      if (item?.path?.includes('.jpg') || item?.path?.includes('.jpeg') || item?.path?.includes('.png')) {
        if (!isImageFound) {
          bookWithImage.imageUrl = item?.path?.split('/')[length];
          bookWithImage.pathImage = item?.path;
        }
        isImageFound = true;
      } else {
        if (!bookWithImage.pdfUrl) {
          bookWithImage.pdfUrl = item?.path?.split('/')[length];
          bookWithImage.pathPdf = item?.path;
        }
      }
    });
    dispatch(
      addBook({
        id: clickedRowBook.id,
        title: clickedRowBook.title,
        published_date: clickedRowBook.published_date
          ? dayjs(clickedRowBook.published_date).format('DD/MM/YYYY')
          : null,
        thematicId: clickedRowBook.thematicId,
        referral_years: clickedRowBook.referral_years,
        page_number: clickedRowBook.page_number,
        image: { imageBase64: '', imageName: bookWithImage.imageUrl, path: bookWithImage.pathImage },
        pdf: { pdfBase64: '', pdfName: bookWithImage.pdfUrl, path: bookWithImage.pathPdf },
        ressourceFiles: [],
        thematic: { id: '', title: '' },
        thematic_title: '',
        content: clickedRowBook.description,
        items: [],
      }),
    );
    dispatch(buttonActionDialog(ActionType.EDIT));
    dispatch(showDialogBook(true));
  };

  const handleAddPrice = (bookId: string, price: number) => {
    dispatch(buttonActionDialogItem(ActionType.SAVE));
    dispatch(
      addItem({
        id: '',
        price: price ? price.toString() : '',
        bookId,
      }),
    );
    dispatch(showDialogItem(true));
  };

  const handleDeleteBook = async (bookId: string) => {
    setIsDeleted(true);
    await deleteBookMutation({
      variables: {
        deleteBookId: bookId,
      },
      refetchQueries: [{ query: GET_BOOKS }],
    })
      .then((res: any) => {
        addToast(t('successDelete'), {
          appearance: 'success',
        });
        refetch();
      })
      .catch((error) => {
        addToast(error.message, {
          appearance: 'error',
        });
      });
  };

  const handleAddBook = () => {
    dispatch(buttonActionDialog(ActionType.SAVE));
    dispatch(
      addBook({
        id: '',
        title: '',
        published_date: null,
        thematicId: action.thematic.dataThematics[0]?.id,
        referral_years: REFERRAL_YEARS[0],
        page_number: 1,
        image: { imageBase64: '', imageName: '' },
        pdf: { pdfBase64: '', pdfName: '' },
        ressourceFiles: [],
        thematic: { id: '', title: '' },
        thematic_title: '',
        content: '',
        items: [],
      }),
    );
    dispatch(showDialogBook(true));
  };

  const handleNext = () => {
    if (!loading) {
      setPage((page) => page + 1);
    }
  };

  const handleSearch = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setInSearch(() => true);
    setSearchText(() => e.target.value);
  };

  const handleFilter = (e: ChangeEvent<HTMLInputElement>) => {
    setInSearch(() => true);
    setPage(() => 1);

    setFilter(() => e.target.value);
  };

  useEffect(() => {
    if (isDeleted) {
      setInSearch(() => true);
      refetch();
      setIsDeleted(false);
    }
  }, [isDeleted, refetch]);

  useEffect(() => {
    if (action.book.refetchBook) {
      setInSearch(() => true);
      dispatch(refetchBook(false));
      refetch();
    }
  }, [action.book.refetchBook, dispatch, refetch]);

  // SEARCH DEBOUNCE
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setPage(1);
      setSearch(() => searchText);
    }, 1500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchText]);

  return (
    <Box sx={{ height: '100%', width: '100%' }}>
      <Grid2 container rowSpacing={1}>
        <Grid2 sx={{ alignContent: 'start' }} xs={6} sm={6} md={6} xl={6}>
          <Box display="flex" justifyContent="flex-start">
            <TextField
              id="input-with-icon-textfield"
              inputProps={{ 'data-testid': 'candidat-list-textfield-search' }}
              label=""
              color="warning"
              value={searchText}
              onChange={handleSearch}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
              variant="outlined"
            />
          </Box>
        </Grid2>
        <Grid2 xs={6} sm={6} md={6} xl={6}>
          <Box display="flex" justifyContent="flex-end">
            <FormControl>
              <FormLabel>{t('Library.filter')}</FormLabel>
              <RadioGroup row defaultValue="medium" name="radio-buttons-group">
                <FormControlLabel
                  value=""
                  control={<Radio value="" color="primary" onChange={handleFilter} checked={filter === ''} />}
                  label="All"
                />
                <FormControlLabel
                  value="L1"
                  control={<Radio value="L1" color="primary" onChange={handleFilter} checked={filter === 'L1'} />}
                  label="L1"
                />
                <FormControlLabel
                  value="L2"
                  control={<Radio value="L2" color="success" onChange={handleFilter} checked={filter === 'L2'} />}
                  label="L2"
                />
                <FormControlLabel
                  value="L3"
                  control={<Radio value="L3" color="success" onChange={handleFilter} checked={filter === 'L3'} />}
                  label="L3"
                />
              </RadioGroup>
            </FormControl>
          </Box>
        </Grid2>
        <Grid2></Grid2>
      </Grid2>

      {loading && (
        <p style={{ textAlign: 'center' }}>
          <LinearProgress color="warning" sx={{ marginTop: 5 }} />
        </p>
      )}

      <Fragment>
        <InfiniteScroll
          dataLength={books?.length} //This is important field to render the next data
          next={handleNext}
          hasMore={!isEnd}
          style={{ height: '100%', width: '100%' }}
          loader={
            <p style={{ textAlign: 'center' }}>
              <CircularProgress sx={{ color: '#E95D04' }} />
            </p>
          }
          endMessage={
            <p style={{ textAlign: 'center' }}>
              <b>{t('Library.seen')}</b>
            </p>
          }
        >
          {
            <>
              <Grid2 container rowSpacing={1}>
                <Grid2 xs={12} sm={4} md={3} xl={2}>
                  <CardAddBook handleAddBook={handleAddBook} />{' '}
                </Grid2>

                {books.map((book: BookType) => {
                  return (
                    <Grid2 xs={12} sm={4} md={3} xl={2}>
                      <CardBook {...{ book, handleAddPrice, handleUpdateBook, handleDeleteBook }} />;
                    </Grid2>
                  );
                })}
              </Grid2>
            </>
          }
        </InfiniteScroll>
      </Fragment>
    </Box>
  );
};

export default BibliothequeList;
