import { useQuery } from '@apollo/client';
import { GET_NOTIF_CANDIDAT, GET_NOTIF_CONTACT } from '../querries/notification';

export const useNotifCandidat = () => {
  const { data, loading, error } = useQuery(GET_NOTIF_CANDIDAT, {
    fetchPolicy: 'network-only',
  });

  return { data: data?.getNotifCandidat, loading, error };
};

export const useNotifContact = () => {
  const { data, loading, error } = useQuery(GET_NOTIF_CONTACT, {
    fetchPolicy: 'network-only',
  });

  return { data: data?.getNotifContact, loading, error };
};
