import { Box, Tab, Tabs } from '@mui/material';
import { FC, useState } from 'react';
import Migration from './Migration';
import Parametre from './Parametre/Parametre';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel: FC<TabPanelProps> = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
};

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const Parametres = () => {
  const [panel, setPanel] = useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setPanel(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={panel} onChange={handleChange}>
          <Tab label="Mail notification" {...a11yProps(0)} data-test data-testid="parameter-notification-tab" />
          <Tab label="Migration" {...a11yProps(0)} data-test data-testid="parameter-migration-tab" />
        </Tabs>
      </Box>
      {/* <Loader enable={waiting} /> */}

      <TabPanel value={panel} index={0}>
        <Parametre />
      </TabPanel>

      <TabPanel value={panel} index={1}>
        <Migration />
      </TabPanel>
    </Box>
  );
};

export default Parametres;
