import { useQuery } from '@apollo/client';
import { GET_THEMATICS } from '../querries/thematic';

export const useThematics = () => {
  const { data, loading, error } = useQuery(GET_THEMATICS, {
    fetchPolicy: 'network-only',
  });

  return { data: data?.getThematics, loading, error };
};
