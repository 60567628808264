import { useMutation } from '@apollo/client';
import { CREATE_THEMATIC, DELETE_THEMATIC, UPDATE_THEMATIC } from '../mutations/thematic';

export const useCreateThematicMutation = () => {
  const [createThematicMutation] = useMutation(CREATE_THEMATIC);

  return { createThematicMutation };
};

export const useUpdateThematicMutation = () => {
  const [updateThematicMutation] = useMutation(UPDATE_THEMATIC);

  return { updateThematicMutation };
};

export const useDeleteThematicMutation = () => {
  const [deleteThematicMutation] = useMutation(DELETE_THEMATIC);

  return { deleteThematicMutation };
};
