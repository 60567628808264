import { useLazyQuery, useMutation } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { downloadExcel } from 'react-export-table-to-excel';
import { useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';
import { DELETE_CONTACT } from '../../../graphql/mutations/Contact';
import { GET_CONTACT } from '../../../graphql/querries/Contact';
import { ContactTypes } from '../../../utils/types';

const useLogic = () => {
  const { addToast } = useToasts();
  const { t } = useTranslation();
  const [contacts, setContacts] = useState<ContactTypes[]>([]);
  const [search, setSearch] = useState<string>('');
  const [totalCount, setTotalCount] = useState<number>(0);
  const [isDownloadCheck, setIsDownloadCheck] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  const [page, setPage] = useState(1);
  const limit = 10;

  const [getContacts, { loading, data, refetch }] = useLazyQuery(GET_CONTACT, {
    onCompleted: (data) => {
      if (data && data.getContacts && data.getContacts.contacts) {
        if (!isDownloadCheck) {
          setContacts((prevContact) => [
            ...(search.trim().length || page === 1 ? [] : prevContact),
            ...data.getContacts.contacts,
          ]);
          setTotalCount(data.getContacts.total);
        } else {
          const header = ['Nom', 'Prenom', 'Email', 'Tel', 'Message', 'Date de soumission'];
          const table: any = [];

          data.getContacts.contacts.forEach((element: ContactTypes) => {
            table.push({
              name: element.name,
              surname: element.surname,
              email: element.email,
              tel: element.tel + '',
              message: element.message,
              date: new Date(element.createdAt).toLocaleDateString(),
            });
          });

          downloadExcel({
            fileName: 'Candidatures' + Date.now().toLocaleString(),
            sheet: 'CandidatyTypes',
            tablePayload: {
              header,
              body: table,
            },
          });
          setIsDownloadCheck(false);
        }
      }
    },
  });

  const handleLoadMore = () => {
    if (page >= 1 && page < data?.getContacts?.pageCount) {
      getContacts({ variables: { page: page + 1, limit } });
      setPage(page + 1);
    }
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value.trim());
    if (!event.target.value.length) setPage(1);
  };

  const handleDownloadExcel = () => {
    setIsDownloadCheck(true);
  };

  const [deleteContact] = useMutation(DELETE_CONTACT);
  const handleDelete = async (id: number) => {
    const successDeletedMessage = t('successDelete');
    await deleteContact({
      variables: {
        id,
      },
    })
      .then((response) => {
        if (response?.data?.deleteContact === 'success') {
          setIsDeleted(true);
          setPage(1);
          addToast(successDeletedMessage, { appearance: 'success' });
        }
      })
      .catch((err: any) => {
        addToast(err.message, { appearance: 'error' });
      });
  };

  useEffect(() => {
    if (page === 1)
      getContacts({
        variables: {
          limit,
          page,
        },
      });
    if (isDownloadCheck) {
      getContacts({ variables: { limit: null } });
    }
    if (isDeleted) {
      refetch();
    }
    setIsDeleted(false);
  }, [page, isDeleted, isDownloadCheck]);

  useEffect(() => {
    if (search.trim().length) getContacts({ variables: { filter: search } });
    else setPage(1);
  }, [search]);

  return {
    data,
    loading,
    search,
    contacts,
    totalCount,
    handleSearch,
    handleDownloadExcel,
    handleDelete,
    handleLoadMore,
    refetch,
  };
};

export default useLogic;
