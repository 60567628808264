import dayjs from 'dayjs';
import { TitleOfDialog } from './interface';
import { RessourceFilesTypes } from './types';

export const DATE = new Date();
export const DATE_NOW = dayjs(`${DATE.getFullYear()}-${DATE.getMonth() + 1}-${DATE.getDate()}`);

export const enum ActionType {
  SAVE = 'SAVE',
  EDIT = 'EDIT',
}
export const REFERRAL_YEARS = ['L1', 'L2', 'L3', 'M1', 'M2'];

export type Title = { [key: string]: TitleOfDialog };

export const TITLE: Title = {
  actuality: { title: 'Actualité' },
  category: { title: 'Catégorie' },
  book: { title: 'Book' },
  thematic: { title: 'Thématique' },
  item: { title: 'Prix de book' },
};

export const RESSOURCE_TYPE = {
  USER_PICTURE: 'USER_PICTURE',
};

export const getUserPicture = (me: UserTypes | undefined | null): RessourceFilesTypes | null | undefined => {
  let pictureRessource = null;
  if (me?.RessourceFiles) {
    pictureRessource = me?.RessourceFiles?.find((ressource) => ressource?.type === RESSOURCE_TYPE.USER_PICTURE);
  }
  return pictureRessource;
};
export const EDITOR_CONFIG = {
  toolbar: [
    {
      name: 'clipboard',
      items: ['Cut', 'Copy', 'Paste', 'PasteText', 'PasteFromWord', '-', 'Undo', 'Redo'],
    },
    {
      name: 'editing',
      items: ['Scayt'],
    },
    {
      name: 'insert',
      items: ['Image', 'Link', 'Unlink', 'Anchor', 'Table', 'HorizontalRule', 'SpecialChar'],
    },
    {
      name: 'colorStyles',
      items: ['TextColor', 'BGColor'],
    },
    { name: 'source', items: ['Source'] },
    '/',
    {
      name: 'basicstyles',
      items: ['Bold', 'Italic', 'Underline', 'Strike'],
    },
    {
      name: 'paragraph',
      items: ['JustifyLeft', 'JustifyCenter', 'JustifyRight', '-', 'Outdent', 'Indent', '-', 'Blockquote'],
    },
    {
      name: 'lists',
      items: ['NumberedList', 'BulletedList'],
    },
    {
      name: 'tools',
      items: ['Maximize'],
    },
    {
      name: 'fontStyles',
      items: ['Font', 'FontSize'],
    },
    {
      name: 'styles',
      items: ['Format'],
    },
    {
      name: 'about',
      items: ['About'],
    },
  ],
  extraPlugins: 'justify, colorbutton, colordialog, font',
  removeButtons: '',
  filebrowserUploadUrl: [['/group/opconnect/documents/-/document_library/']],
  filebrowserBrowseUrl: [['/group/opconnect/documents/-/document_library/']],
  colorButton_historyRowLimit: 6,
};

export const COLORS = [
  'linear-gradient(to right, #efefef, #010101',
  'linear-gradient(to right, #10BCB4, #1A8ADE',
  'linear-gradient(to right, #DECBA4, #3E5151',
  'linear-gradient(to right, #ffe6f2, #ff4da6',
  'linear-gradient(to right, #e8be91, #674a3d',
  'linear-gradient(to right, #ced4d8, #1b2d50',
  'linear-gradient(to right, #13547a, #80d0c7',
  'linear-gradient(to right, #f5f7fa, #c3cfe2',
  'linear-gradient(to right, #E6E1CA, #e8bef1',
  'linear-gradient(to right, #dd5e89, #f7bb97',
  'linear-gradient(to right, #2DB057, #98C9A9',
  'linear-gradient(to right, #ff5b5f, #fefb7b',
  'linear-gradient(to right, #f8d2f9, #D34DDB',
  'linear-gradient(to right, #403B4A, #E7E9BB',
  'linear-gradient(to right, #EB5757, #D34DDB',
  'linear-gradient(to right, #ddd6f3, #faaca8',
  'linear-gradient(to right, #0099cc, #004d66',
  'linear-gradient(to right, #7b4397, #dc2430',
  'linear-gradient(to right, #B7F8DB, #50A7C2',
  'linear-gradient(to right, #F09819, #EDDE5D',
  'linear-gradient(to right, #000022, #336699',
  'linear-gradient(to right, #ff512f, #dd2476',
  'linear-gradient(to right, #4568DC, #B06AB3',
  'linear-gradient(to right, #283048, #859398',
  'linear-gradient(to right, #4ca1af, #c4e0e5',
  'linear-gradient(to right, #a8ff78, #78ffd6',
];

export const IMAGE_EXTENSIONS = ['gif', 'jpg', 'jpeg', 'jfif', 'pjpeg', 'pjp', 'png', 'svg'];

interface RoleType {
  updatedAt?: string;
  type?: string;
  id?: string;
  createdAt?: string;
}

interface LocationTypes {
  createdAt?: string;
  id?: string;
  town?: string;
  updatedAt?: string;
  Country?: CountryTypes;
}

interface CountryTypes {
  id?: string;
  isoCode?: string;
  name?: string;
  updatedAt?: string;
  createdAt?: string;
}

interface CourseType {
  id?: number;
  status?: string;
  createdAt?: string;
  updatedAt?: string;
  UserId?: string;
  Curriculum?: CurriculumTypes;
}

interface EducationType {
  id?: number;
  name?: string;
  title?: string;
  description?: string;
  createdAt?: string;
  updatedAt?: string;
}

interface CurriculumTypes {
  id?: number;
  year?: string;
  createdAt?: Date;
  updatedAt?: Date;
  Education?: EducationType;
  Diploma?: DiplomaTypes;
}

interface DiplomaTypes {
  id?: number;
  type?: string;
  createdAt?: string;
  updatedAt?: string;
}
export interface UserTypes {
  Role?: RoleType;
  RessourceFiles?: [RessourceFilesTypes];
  Location?: LocationTypes;
  address?: string;
  createdAt?: Date;
  email: string;
  firstName?: string;
  id?: string;
  lastName?: string;
  password?: string;
  status?: boolean;
  tel?: string;
  updatedAt?: Date;
  username?: string;
  Course?: CourseType[];
}
