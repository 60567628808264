import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export default function DeliveryNow(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 20 30">
      <path d="M16.6665 3.00001H12.6665V7.00001H7.33317V3.00001H3.33317C2.62593 3.00001 1.94765 3.28096 1.44755 3.78106C0.947455 4.28116 0.666504 4.95943 0.666504 5.66668V27C0.666504 27.7073 0.947455 28.3855 1.44755 28.8856C1.94765 29.3857 2.62593 29.6667 3.33317 29.6667H16.6665C17.3737 29.6667 18.052 29.3857 18.5521 28.8856C19.0522 28.3855 19.3332 27.7073 19.3332 27V5.66668C19.3332 4.95943 19.0522 4.28116 18.5521 3.78106C18.052 3.28096 17.3737 3.00001 16.6665 3.00001ZM9.99984 9.66668C10.7071 9.66668 11.3854 9.94763 11.8855 10.4477C12.3856 10.9478 12.6665 11.6261 12.6665 12.3333C12.6665 13.0406 12.3856 13.7189 11.8855 14.219C11.3854 14.7191 10.7071 15 9.99984 15C9.29259 15 8.61432 14.7191 8.11422 14.219C7.61412 13.7189 7.33317 13.0406 7.33317 12.3333C7.33317 11.6261 7.61412 10.9478 8.11422 10.4477C8.61432 9.94763 9.29259 9.66668 9.99984 9.66668ZM15.3332 20.3333H4.6665V19C4.6665 17.2267 8.2265 16.3333 9.99984 16.3333C11.7732 16.3333 15.3332 17.2267 15.3332 19V20.3333ZM11.3332 5.66668H8.6665V0.333344H11.3332V5.66668ZM15.3332 24.3333H4.6665V23H15.3332V24.3333ZM9.99984 27H4.6665V25.6667H9.99984V27Z" fill="#6C757D" />
    </SvgIcon>
  );
}
