import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export default function DeliveryNow(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24" >
      <path d="M14 2H6C4.9 2 4 2.9 4 4V20C4 21.1 4.9 22 6 22H18C19.1 22 20 21.1 20 20V8L14 2ZM18 20H6V4H13V9H18V20ZM16 11V18.1L13.9 16L11.1 18.8L8.3 16L11.1 13.2L8.9 11H16Z" fill="#E85D04" />
    </SvgIcon>
  );
}
