import { useMutation } from '@apollo/client';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { RESET_PASSWORD } from '../../graphql/mutations/ResetPassword';
import { LISTE_ROUTES } from '../../routes/route';
import { validatePassword } from '../../utils/Validation';

interface IResetpassword {
  newPassword: string;
  confirmPassword: string;
}

const useLogic = () => {
  const navigate = useNavigate();
  const [passwordData, setPasswordData] = useState<IResetpassword>({
    newPassword: '',
    confirmPassword: '',
  });
  const { state } = useLocation();
  const [showPassword, setShowPassword] = useState(false);

  const passwordType = showPassword ? 'text' : 'password';

  const handleToggleVisibility = () => {
    setShowPassword(!showPassword);
  };

  const { addToast } = useToasts();
  const { t } = useTranslation();
  const updatedPasswordSuccessMessage = t('successUppdatePass');
  const updatePasswordErrorMessage = t('errorUpdatePass');
  const emptyFieldErrorMessage = t('errorEmptyPass');
  const invalidPasswordErrorMessage = t('errorCasePass');
  const differFieldErrorMessage = t('errorDistinctPass');

  const [ResetPassword, {}] = useMutation(RESET_PASSWORD);

  const handleGoTo = (link: string) => {
    navigate(link);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setPasswordData({ ...passwordData, [name]: value.trim() });
  };

  const handleValidate = async () => {
    if (!passwordData.newPassword || !passwordData.confirmPassword) {
      addToast(emptyFieldErrorMessage, { appearance: 'error' });
    } else if (passwordData.newPassword && !validatePassword(passwordData.newPassword)) {
      addToast(invalidPasswordErrorMessage, { appearance: 'error' });
    } else if (passwordData.newPassword && passwordData.newPassword !== passwordData.confirmPassword) {
      addToast(differFieldErrorMessage, { appearance: 'error' });
    } else {
      await ResetPassword({
        variables: {
          email: state,
          newPassword: passwordData.newPassword,
        },
      })
        .then((response: any) => {
          if (response.data.resetPassword) {
            addToast(updatedPasswordSuccessMessage, { appearance: 'success' });
            handleGoTo(LISTE_ROUTES.LOGIN.path);
          } else addToast(updatePasswordErrorMessage, { appearance: 'warning' });
        })
        .catch((err: any) => console.log('error:', err));
      setPasswordData({ newPassword: '', confirmPassword: '' });
    }
  };

  return {
    passwordData,
    passwordType,
    showPassword,
    handleToggleVisibility,
    handleGoTo,
    handleChange,
    handleValidate,
  };
};

export default useLogic;
