import { useQuery } from '@apollo/client';
import { Box, Paper, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Card from '../../components/Card/Card';
import Chart from '../../components/Chart/Chart';
import Candidaties from './images/CandidatieImage.svg';
import Bookpurple from './images/BookRed.svg';
import BoogGreen from './images/BookGreen.svg';
import fluentPersonMoney from './images/FluentPersonMoney.svg';
import ListComponent from '../../components/List/List';
import { GET_CANDIDATY_COUNT, GET_GROUP_CANDIDATY_COUNT } from '../../graphql/querries/Candidature';
import { GET_CONTACT_COUNT } from '../../graphql/querries/Contact';
import { GET_ENROLLED_USER } from '../../graphql/querries/users';

import useStyles from './style';

interface Props {}

const Home: FC<Props> = () => {
  const { t } = useTranslation();
  const [value, setValue] = useState(2023);
  const { data: candidacy, refetch: refetchCandidacy } = useQuery(GET_CANDIDATY_COUNT, {
    variables: { year: `${value}` },
  });
  const totalCandidacy = candidacy?.getCandidaciesCount?.totalCount ?? 0;
  const filteredCandidacy = candidacy?.getCandidaciesCount?.filteredCount ?? 0;
  const { data: contact, refetch: refetchContact } = useQuery(GET_CONTACT_COUNT, {
    variables: { year: `${value}` },
  });
  const totalContact = contact?.getContactsCount?.totalCount ?? 0;
  const filteredContact = contact?.getContactsCount?.filteredCount ?? 0;

  const start = 2023;
  const end = 2026;
  const { data: candidacyGroup } = useQuery(GET_GROUP_CANDIDATY_COUNT, {
    variables: { start, end },
  });
  const { data: user } = useQuery(GET_ENROLLED_USER, {
    variables: { start, end },
  });

  const handleGetDatas = (labels: string[], datas: any) => {
    return labels.reduce((counts: any, label: any) => {
      const years = datas?.find(({ year }: any) => year?.toString() === label);
      counts.push(years ? years.count : 0);
      return counts;
    }, []);
  };
  const labels = ['2023', '2024', '2025', '2026'];
  const candidacies = handleGetDatas(labels, candidacyGroup?.getCandidaciesGroupByYear);
  const enrolledStudents = handleGetDatas(labels, user?.getEnrolledUserCount);
  const handleChange = (e: any) => {
    setValue(e.target.value);
  };
  useEffect(() => {
    refetchCandidacy();
    refetchContact();
  }, [candidacy, contact]);

  const dataCardHome = [
    {
      total:totalCandidacy,
      title:t('Home.totalNumberOfApplicants'),
      filtered:filteredCandidacy,
      img:Candidaties,
      background:"#E4EEFF"
    },
    {
      total:totalContact,
      title:t('Home.totalNumberOfContact'),
      filtered:filteredContact,
      img:Bookpurple,
      background:"#EEE5FF"
    },
    {
      total:0,
      title:t('Home.totalNumberOfBook'),
      filtered:0,
      img:BoogGreen,
      background:"#D7F0E2"
    },
    {
      total:0,
      title:t('Home.totalNumberOfStudent'),
      filtered:0,
      img:fluentPersonMoney,
      background:"#FFE9ED"
    },
  ]

  const styles = useStyles();
  return (
    <Box
      className={styles.wrapper}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
        maxWidth: 1160,
        margin: 'auto',
      }}
    >
      <Box
        className={styles.title}
        sx={{ display: 'flex', flexDirection: 'column', width: '100%', margin: 'auto' }}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Box sx={{marginLeft: 11}}>
            <DatePicker
              views={['year']}
              minDate="2023"
              value={value}
              onChange={(newValue: any) => {
                setValue(new Date(newValue).getFullYear());
              }}
              renderInput={({ inputRef, InputProps }) => (
                <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'revert-layer', width: '100%' }}>
                  <span style={{ marginRight: 20, fontSize: 28, color: '#36414C', fontWeight: '700' }}>
                    {t('Home.academicYear')}
                  </span>
                  <TextField
                    variant="standard"
                    type="number"
                    fullWidth={false}
                    ref={inputRef}
                    InputProps={{
                      endAdornment: null,
                      inputProps: {
                        min: 2023,
                        style: {
                          fontSize: 28,
                          color: '#DC2F02',
                          width: '100%',
                          maxWidth: '85px',
                        },
                      },
                      disableUnderline: true,
                    }}
                    value={value}
                    onChange={handleChange}
                  />
                  {InputProps?.endAdornment}
                </Box>
              )}
            />
          </Box>
        </LocalizationProvider>
      </Box>
      <Box style={{ display: 'flex', flexShrink: 1, flexDirection: 'column' }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
          }}
        >
            {dataCardHome.map((item, index) => {
              return(
                <>
                  <Card key={index}
                    total={item.total}
                    title={item.title}
                    filtered={item.filtered}
                    img={item.img}
                    background={item.background}
                  />
                </>                
              )
            })}
        </Box>
        <Paper style={{ width: '100%', margin: '30px auto', boxShadow: 'unset', borderRadius: 16 }}>
          <Chart
            title={t('Home.GraphOnStudents')}
            labels={labels}
            candidacyLabel={t('Home.numberCandidature')}
            candidacyData={candidacies}
            enrolledStudentLabel={t('Home.numberOfStudent')}
            enrolledStudentData={enrolledStudents}
          />
        </Paper>
      </Box>
      <Box sx={{ margin: '20px 0px', boxShadow: 'unset !important', border: 'unset' }}>
        <Paper sx={{ width: '100%', margin: 'auto', boxShadow: 'unset !important', border: 'unset', borderRadius: 4 }}>
          <ListComponent />
        </Paper>
      </Box>
    </Box>
  );
};
export default Home;
