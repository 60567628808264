import { makeStyles } from '@mui/styles'
export default makeStyles(() => ({
  wrapLogin: {
    display: 'flex !important',
  },
  titleConnection: {
    color: '#36414C !important',
    marginBottoom: 19,
    fontWeight: "700 !important",
    fontSize: '28px !important'
  },
  ContentLogin: {
    alignItems: 'center',
    height: '100% !important',
    minHeight: "100vh !important",
    width: "100%",
    margin: 'auto',
    maxWidth: "452px",
    justifyContent: 'center',
    display: 'flex',
    // marginTop: 8,
    flexDirection: 'column',
    "@media (max-width:450px)": {
      alignItems: 'start',
      justifyContent: 'start',

    },
    "& > div": {
      display: 'flex',
      borderRadius: 28,
      flexDirection: "column",
      border: '1px solid #C6CAD0',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '58px 44px',
      marginTop:60,
      "@media (max-width:450px)": {
        padding: '5px 24px',
      }
    },
    '&>h1': {
      fontSize: "24px",
      color: "#808080",
    },
  },
  bgOrange: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: " 8px 22px",
    height:52,
    margin: "0",
    background: " linear-gradient(90deg, #D00000 15.29%, #E85D04 80.72%, #E85D04 80.72%)",
    boxShadow: "0px 1px 5px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.2)",
    marginBottom:'25px',
    borderRadius: "12px !important",
    fontSize:"16px !important",
  },
  input: {
    '& div': {
      borderRadius: '10px !important',
    }
  },
  forgotPassword: {
    textDecoration: 'none !important',
    color: "#36414C !important",
    fontSize: "16px !important"
  },
  rememberMe:{
    margin:'32px 0px 24px 0px'
  }
}))
