import { gql } from '@apollo/client';
import { Actualities } from '../fragments/actuality';

const GET_ACTUALITIES = gql`
  query GetActualitiesBack($limit: Int, $page: Int, $filter: String) {
    getActualitiesBack(limit: $limit, page: $page, filter: $filter) {
      actualities {
        ...Actualities
      }
      pageCount
      total
    }
  }
  ${Actualities}
`;

export { GET_ACTUALITIES };

const GET_ACTUALITY = gql`
  query GetActuality {
    getActuality {
      ...Actualities
    }
  }
  ${Actualities}
`;

export { GET_ACTUALITY };
