import { useContext, useRef, createRef } from 'react';
import Dialog from '@mui/material/Dialog';
import {
  Badge,
  Button,
  Card,
  CardContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Slider,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { blue } from '@mui/material/colors';
import { AddAPhoto } from '@mui/icons-material';

import { Save } from '@mui/icons-material';
import useLogic from './useLogic';
import AuthContext from '../../utils/AuthContext';
import { getUserPicture } from '../../utils/constant';
import Loader from '../Loader';
import AvatarEditor from 'react-avatar-editor';
import Dropzone from 'react-dropzone';
import { useTranslation } from 'react-i18next';

interface props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  initiale?: string;
  src?: string;
}

const ProfileCard = ({ open, setOpen, initiale, src }: props) => {
  const { me, refetch } = useContext(AuthContext);

  const { t } = useTranslation();

  // REFERENCES
  const inputFileRef = useRef<HTMLInputElement | null>(null);
  const editorRef: React.RefObject<AvatarEditor> = createRef();

  const picsRessouce = getUserPicture(me);
  const { handleClose, photo, handleUpload, handleUpdate, handleDrop, handleValueScale, picsScale, loading } = useLogic(
    {
      open,
      setOpen,
      me,
      refetch,
      picsRessouce,
    },
  );

  return (
    <>
      {!loading && (
        <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
          <DialogTitle sx={{ paddingBottom: 0, paddingLeft: 2 }}>
            <Typography variant="h6" color="text.secondary">
              {t('Pics.pics')}
            </Typography>

            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <Card sx={{ maxWidth: 400 }}>
            <Grid container spacing={2} justifyContent="center">
              <Grid item xs={12}>
                <CardContent>
                  <Typography variant="caption" display="block" gutterBottom color="text.secondary">
                    {t('Pics.your_pics')}
                  </Typography>
                  <Divider />
                </CardContent>
              </Grid>
              <Grid item xs={8}>
                <Slider
                  aria-label="Temperature"
                  defaultValue={1}
                  valueLabelDisplay="auto"
                  value={picsScale}
                  onChange={handleValueScale}
                  step={0.1}
                  marks
                  min={1}
                  max={10}
                />
              </Grid>

              <Grid item xs={8}>
                <Dropzone onDrop={handleDrop} noClick noKeyboard>
                  {({ getRootProps, getInputProps }) => (
                    <section>
                      <div {...getRootProps()}>
                        <input
                          {...getInputProps()}
                          ref={inputFileRef}
                          hidden
                          accept="image/*"
                          multiple
                          type="file"
                          onChange={handleUpload}
                        />

                        <Badge
                          overlap="circular"
                          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                          badgeContent={
                            <AddAPhoto
                              sx={{
                                width: 50,
                                height: 50,
                                color: blue[900],
                              }}
                              onClick={() => {
                                inputFileRef?.current?.click();
                              }}
                            />
                          }
                        >
                          <AvatarEditor
                            ref={editorRef}
                            image={photo ? URL.createObjectURL(photo) : ''}
                            border={50}
                            color={[255, 255, 255, 0.6]} // RGBA
                            scale={picsScale}
                            rotate={0}
                            borderRadius={100}
                            style={{
                              width: '100%',
                              height: '100%',
                            }}
                          />
                        </Badge>
                      </div>
                    </section>
                  )}
                </Dropzone>
              </Grid>

              <Grid item xs={10} sx={{ marginY: 1, marginBottom: 2 }}>
                <Button
                  onClick={() => {
                    handleUpdate(editorRef);
                  }}
                  variant="outlined"
                  component="label"
                  sx={{ width: '100%' }}
                  startIcon={<Save />}
                  disabled={!photo}
                >
                  <Typography variant="caption" display="block">
                    {t('Pics.save')}
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          </Card>
        </Dialog>
      )}
      {loading && <Loader enable={loading} />}
    </>
  );
};

export default ProfileCard;
