import { useMutation } from '@apollo/client';
import { CREATE_CATEGORY, UPDATE_CATEGORY, DELETE_CATEGORY } from '../mutations/category';

export const useCreateCategoryMutation = () => {
  const [createCategoryMutation] = useMutation(CREATE_CATEGORY);

  return { createCategoryMutation };
};

export const useUpdateCategoryMutation = () => {
  const [updateCategoryMutation] = useMutation(UPDATE_CATEGORY);

  return { updateCategoryMutation };
};

export const useDeleteCategoryMutation = () => {
  const [deleteCategoryMutation] = useMutation(DELETE_CATEGORY);

  return { deleteCategoryMutation };
};
