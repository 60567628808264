import { gql } from '@apollo/client';

const GET_USERS = gql`
  query GetCurriculums {
    users {
      Location {
        createdAt
        Country {
          name
          isoCode
          id
        }
        id
        town
        updatedAt
      }
      RessourceFiles {
        link
        id
        createdAt
        name
        path
        updatedAt
      }
      Role {
        updatedAt
        type
        createdAt
      }
      address
      createdAt
      email
      firstName
      id
      lastName
      password
      status
      tel
      updatedAt
      username
      Course {
        Curriculum {
          Diploma {
            createdAt
            id
            type
            updatedAt
          }
          Education {
            createdAt
            description
            id
            name
            title
            updatedAt
          }
          amout
          createdAt
          id
          updatedAt
          year
        }
        createdAt
        id
        status
        updatedAt
      }
    }
  }
`;

const GET_ENROLLED_USER = gql`
  query GetEnrolledUserCount($start: Int, $end: Int) {
    getEnrolledUserCount(start: $start, end: $end) {
      year
      count
    }
  }
`;

export { GET_USERS, GET_ENROLLED_USER };
