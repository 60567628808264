import { ActionType, REFERRAL_YEARS } from '../../utils/constant';
import { BookType, ItemType } from '../../utils/interface';
import {
  ADD_BOOK,
  SHOW_DIALOG_BOOK,
  BUTTON_ACTION_DIALOG,
  SHOW_DIALOG_ITEM,
  ADD_ITEM,
  BUTTON_ACTION_DIALOG_ITEM,
  SHOW_BOOK_DETAILS,
  REFETCH_BOOK,
} from '../Actions/ActionBook';

interface Book {
  book: BookType;
  show: boolean;
  item: ItemType;
  showItem: boolean;
  actionTypeItem: ActionType;
  actionType: ActionType;
  refetchBook: Boolean;
}

export const INITIAL_VALUE_BOOK: BookType = {
  id: '',
  title: '',
  published_date: null,
  thematicId: '',
  referral_years: REFERRAL_YEARS[0],
  page_number: 1,
  image: { imageBase64: '', imageName: '', path: '' },
  pdf: { pdfBase64: '', pdfName: '', path: '' },
  ressourceFiles: [],
  thematic: { id: '', title: '' },
  thematic_title: '',
  content: '',
  items: [],
};

const initialState: Book = {
  book: INITIAL_VALUE_BOOK,
  show: false,
  item: { id: '', price: '', bookId: '' },
  showItem: false,
  refetchBook: false,
  actionTypeItem: ActionType.SAVE,
  actionType: ActionType.SAVE,
};

export const bookReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case ADD_BOOK:
      return { ...state, book: action.payload.book };
    case ADD_ITEM:
      return { ...state, item: action.payload.item };
    case SHOW_DIALOG_BOOK:
      return { ...state, show: action.payload.show };
    case SHOW_DIALOG_ITEM:
      return { ...state, showItem: action.payload.showItem };
    case BUTTON_ACTION_DIALOG:
      return { ...state, actionType: action.payload.actionType };
    case BUTTON_ACTION_DIALOG_ITEM:
      return { ...state, actionTypeItem: action.payload.actionTypeItem };
    case SHOW_BOOK_DETAILS:
      return { ...state, open: action.payload.open };
    case REFETCH_BOOK:
      return { ...state, refetchBook: action.payload.refetchBook };
    default:
      return state;
  }
};
