import { gql } from '@apollo/client';

const GET_CONTACT = gql`
  query GetContacts($limit: Int, $page: Int, $filter: String) {
    getContacts(limit: $limit, page: $page, filter: $filter) {
      total
      pageCount
      contacts {
        id
        name
        surname
        email
        message
        tel
        createdAt
        updatedAt
      }
    }
  }
`;

const GET_CONTACT_COUNT = gql`
  query GetContactsCount($year: String) {
    getContactsCount(year: $year) {
      totalCount
      filteredCount
    }
  }
`;

export { GET_CONTACT, GET_CONTACT_COUNT };
