import InputTextField from '../../../components/input/InputTextField';
import {
  Button,
  Card,
  CardActionArea,
  CardMedia,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import { Radio, FormControlLabel } from '@mui/material';
import { CKEditor } from 'ckeditor4-react';
import { EDITOR_CONFIG } from '../../../utils/constant';
import { useLogic } from './useLogic';
import { useSelector, useDispatch } from 'react-redux';
import { addActuality } from '../../../Redux/Actions/ActionActuality';
import dayjs from 'dayjs';

const ActualityForm = () => {
  const { buttonFileName, fileUpload, handleChange, setValue, value } = useLogic();
  const categories = useSelector((state: any) => state);

  const [description, setDescription] = useState<string>('');
  const dispatch = useDispatch();
  const handleChecked = (e: any) => {
    if (e.target.id === 'defaultDate') {
      setValue({ ...value, checked: 1 });
      dispatch(
        addActuality({
          ...value,
          checked: 1,
        }),
      );
    } else if (e.target.id === 'periode') {
      setValue({ ...value, checked: 0 });
      dispatch(
        addActuality({
          ...value,
          checked: 0,
        }),
      );
    }
  };

  const onChangeContent = (content: any) => {
    setDescription(content);
  };

  useEffect(() => {
    if (description) dispatch(addActuality({ ...value, content: description }));
  }, [description, dispatch, value]);

  return (
    <>
      <Grid container padding={'1% 10% 2% 10%'}>
        <Grid xs={12}>
          <InputTextField
            inputProps={{ 'data-testid': 'actuality-form-field-title' }}
            style={{ width: '100%' }}
            name="title"
            label="Titre"
            value={value.title}
            onChange={handleChange}
            required
          />
        </Grid>
        <Grid container paddingTop={'1%'}>
          <Grid xs={7}>
            <FormControlLabel
              control={
                <Radio
                  id="defaultDate"
                  onChange={handleChecked}
                  checked={value.checked === 1}
                  data-testid="actuality-form-radio-defaultDate"
                />
              }
              label="Default Date"
            />
          </Grid>
          <Grid xs={5}>
            <FormControlLabel
              control={
                <Radio
                  id="periode"
                  onChange={handleChecked}
                  checked={value.checked === 0}
                  data-testid="actuality-form-radio-periode"
                />
              }
              label="Période"
            />
          </Grid>
          <Grid container>
            <Grid xs={2}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Default Date"
                  inputFormat="DD/MM/YYYY"
                  value={dayjs(value.default_date, 'DD/MM/YYYY').format('MM/DD/YYYY')}
                  onChange={(newValue) => {
                    setValue({ ...value, default_date: dayjs(newValue, 'DD/MM/YYYY').format('DD/MM/YYYY') });
                    dispatch(
                      addActuality({
                        ...value,
                        default_date: dayjs(newValue, 'DD/MM/YYYY').format('DD/MM/YYYY'),
                      }),
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        'data-testid': 'actuality-form-date-defautlt',
                      }}
                    />
                  )}
                  disablePast
                />
              </LocalizationProvider>
            </Grid>
            <Grid xs={5}></Grid>
            <Grid xs={2}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Start Date"
                  inputFormat="DD/MM/YYYY"
                  value={dayjs(value.start_date, 'DD/MM/YYYY').format('MM/DD/YYYY')}
                  onChange={(newValue) => {
                    setValue({
                      ...value,
                      start_date: dayjs(newValue, 'DD/MM/YYYY').format('DD/MM/YYYY'),
                      end_date: dayjs(newValue, 'DD/MM/YYYY').format('DD/MM/YYYY'),
                    });
                    dispatch(
                      addActuality({
                        ...value,
                        start_date: dayjs(newValue, 'DD/MM/YYYY').format('DD/MM/YYYY'),
                        end_date: dayjs(newValue, 'DD/MM/YYYY').format('DD/MM/YYYY'),
                      }),
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        'data-testid': 'actuality-form-date-start',
                      }}
                    />
                  )}
                  disablePast
                />
              </LocalizationProvider>
            </Grid>
            <Grid xs={1}></Grid>
            <Grid xs={2}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="End Date"
                  inputFormat="DD/MM/YYYY"
                  // data-testid="actuality-form-date-end"
                  // eslint-disable-next-line no-self-compare
                  value={
                    value?.end_date && value?.start_date && value?.end_date < value?.start_date
                      ? dayjs(value.start_date, 'DD/MM/YYYY').format('MM/DD/YYYY')
                      : dayjs(value.end_date, 'DD/MM/YYYY').format('MM/DD/YYYY')
                  }
                  onChange={(newValue) => {
                    setValue({ ...value, end_date: dayjs(newValue, 'DD/MM/YYYY').format('DD/MM/YYYY') });

                    dispatch(
                      addActuality({
                        ...value,
                        end_date: dayjs(newValue, 'DD/MM/YYYY').format('DD/MM/YYYY'),
                      }),
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      name="endDate"
                      {...params}
                      // @ts-ignore
                      inputProps={{
                        ...params.inputProps,
                        'data-testid': 'actuality-form-date-end',
                      }}
                    />
                  )}
                  minDate={dayjs(value.start_date, 'DD/MM/YYYY').format('MM/DD/YYYY')}
                  disablePast
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
        </Grid>
        <Grid container paddingTop={'2%'}>
          <Grid xs={12}>
            {categories?.category?.dataCategories?.length === 0 ? (
              <Typography style={{ color: 'red' }}>Véillez créer un catégorie</Typography>
            ) : (
              <FormControl fullWidth>
                <InputLabel>Catégories</InputLabel>
                <Select
                  data-testid="actuality-form-select-category"
                  style={{ width: '19%' }}
                  defaultValue={categories?.category?.dataCategories[0]?.id}
                  name="category"
                  value={value.categoryId}
                  label="Catégories"
                  onChange={handleChange}
                >
                  {categories?.category?.dataCategories?.length > 0 &&
                    categories?.category?.dataCategories?.map((item: any) => {
                      return (
                        <MenuItem key={item.id} value={item.id}>
                          {item.name}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            )}
          </Grid>
        </Grid>
        <Grid container paddingTop={'1%'}>
          <Grid xs={12}>
            <label>Image référence :</label>
          </Grid>
          <Grid xs={6}>
            {value.image && (
              <Card sx={{ maxWidth: 180, maxHeight: 90 }}>
                <CardActionArea>
                  <CardMedia component="img" height="140" image={value.image} alt="green iguana" />
                </CardActionArea>
              </Card>
            )}
            {!value.image && <Typography style={{ color: 'red' }}>{'Aucun fichier'}</Typography>}
          </Grid>
          <Grid xs={6}>
            <Button
              variant="contained"
              style={{ display: 'flex', textTransform: 'none' }}
              onClick={fileUpload}
              data-testid="actuality-form-button-fileUpload"
            >
              {buttonFileName}
            </Button>
          </Grid>
        </Grid>
        <Grid container>
          <Grid xs={12}>
            <label style={{ marginRight: '30px' }}>Content :</label>
          </Grid>
          <Grid xs={12}>
            {/* <MyStatefulEditor markup={value.content} onchange={onChange} /> */}
            <CKEditor
              data-testid="actuality-form-CKEditor"
              name="content"
              config={EDITOR_CONFIG}
              onChange={(e: any) => {
                onChangeContent(e.editor.getData());
              }}
              initData={value.content}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default ActualityForm;
