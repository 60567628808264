import { gql } from '@apollo/client';
import { Books } from '../fragments/book';

const GET_BOOKS = gql`
  query GetBooks($limit: Int, $page: Int, $filter: String, $search: String) {
    getBooks(limit: $limit, page: $page, filter: $filter, search: $search) {
      total
      pageCount
      books {
        ...Books
      }
    }
  }
  ${Books}
`;

export { GET_BOOKS };
