import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
  firstName: Yup.string().required('Ce champ est obligatoire'),

  lastName: Yup.string().required('Ce champ est obligatoire'),

  email: Yup.string().required('Ce champ est obligatoire'),

  address: Yup.string().required('Ce champ est obligatoire'),

  tel: Yup.string().required('Ce champ est obligatoire'),

  username: Yup.string().required('Ce champ est obligatoire'),

  Location: Yup.object().shape({
    town: Yup.string().required('Ce champ est obligatoire'),
    Country: Yup.object().shape({
      name: Yup.string(),
      isoCode: Yup.string(),
    }),
  }),
  Role: Yup.object().shape({
    type: Yup.string().required('Ce champ est obligatoire'),
    id: Yup.string(),
  }),

  Course: Yup.array()
    .of(
      Yup.object().shape({
        Curriculum: Yup.object().shape({
          year: Yup.string().required('Ce champ est obligatoire'),
          Education: Yup.object()
            .shape({
              name: Yup.string().required('Ce champ est obligatoire'),
            })
            .required('Ce champ est obligatoire'),
        }),
      }),
    )
    .when('Role', (role, schema) => {
      if (role && role?.type && role?.type !== 'admin') {
        return schema?.required('obligatoire pour etudiant et membre').min(1, 'Ce champ est obligatoire');
      } else schema?.notRequired().nullable();
    }),
});
