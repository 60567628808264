import { PersonAdd, Delete, Search, FileOpen, Edit, RestoreFromTrash } from '@mui/icons-material';
import { Box, Button, IconButton, InputAdornment, TextField } from '@mui/material';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { FC } from 'react';
import Loader from '../../../components/Loader';
import UserCreate from '../../../components/User/UserCreate';
import UserEdit from '../../../components/User/UserEdit';
import UserFiles from '../../../components/User/UserFiles';
import useLogic from './useLogic';
import { useTranslation } from 'react-i18next';

interface Props {}

const User: FC<Props> = () => {
  const {
    loading,
    search,
    handleSearch,
    users,
    openUserFilesDrawer,
    handleFilesDrawer,
    handleNewUser,
    handleDelete,
    waiting,
    setOpenUserFilesDrawer,
    currentFiles,
    setcurrentFiles,
    refetch,
    openUserEdit,
    setOpenUserEdit,
    userEdited,
    handleEditDrawer,
    openCreaction,
    setOpenCreaction,
    currentCreation,
  } = useLogic();

  const { t } = useTranslation();

  const columns: GridColDef[] = [
    {
      field: 'username',
      headerName: t('User.nickname') + '',
      flex: 1,
      editable: false,
    },
    {
      field: 'firstName',
      headerName: t('User.firstName') + '',
      flex: 1,
      editable: false,
    },
    {
      field: 'lastName',
      headerName: t('User.lastName') + '',
      flex: 1,
      editable: false,
    },
    {
      field: 'email',
      headerName: 'Email',
      type: 'number',
      flex: 1,
      editable: false,
    },
    {
      field: 'tel',
      headerName: t('User.phone') + '',
      type: 'number',
      flex: 1,
      editable: false,
    },

    {
      field: 'role',
      headerName: t('User.role') + '',
      type: 'number',
      flex: 1,
      editable: false,
      valueGetter: (params: GridValueGetterParams) => params.row.Role.type,
    },
    {
      field: 'createdAt',
      headerName: t('User.submission_date') + '',
      type: 'string',
      flex: 1,
      editable: false,
      valueGetter: (params: GridValueGetterParams) => new Date(params.row.createdAt).toLocaleDateString(),
    },

    {
      field: 'updatedAt',
      headerName: t('User.update_date') + '',
      type: 'string',
      flex: 1,
      editable: false,
      valueGetter: (params: GridValueGetterParams) => new Date(params.row.updatedAt).toLocaleDateString(),
    },
    {
      field: ' ',
      headerName: '',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      width: 50,
      renderCell: (params: any) => {
        return (
          <div>
            <IconButton
              color="primary"
              aria-label="open drawer"
              data-testid={`useList-files-${params.row.id}`}
              onClick={() => {
                handleFilesDrawer(params.row.RessourceFiles);
              }}
              edge="end"
              sx={{ mr: 2 }}
            >
              <FileOpen />
            </IconButton>
          </div>
        );
      },
    },
    {
      field: '   ',
      headerName: '',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      width: 50,
      renderCell: (params: any) => {
        return (
          <div>
            <IconButton
              color="warning"
              aria-label="open drawer"
              data-testid={`useList-edit-${params.row.id}`}
              onClick={() => {
                setOpenUserEdit(true);
                handleEditDrawer(params.row);
              }}
              edge="end"
              sx={{ mr: 2 }}
            >
              <Edit />
            </IconButton>
          </div>
        );
      },
    },
    {
      field: '  ',
      headerName: '',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      width: 50,
      renderCell: (params: any) => {
        return (
          <div>
            {params.row.status ? (
              <IconButton
                color="error"
                aria-label="open drawer"
                onClick={() => {
                  handleDelete(params.row);
                }}
                edge="end"
                sx={{ mr: 2 }}
              >
                <Delete />
              </IconButton>
            ) : (
              <IconButton
                color="success"
                aria-label="open drawer"
                onClick={() => {
                  handleDelete(params.row);
                }}
                edge="end"
                sx={{ mr: 2 }}
              >
                <RestoreFromTrash />
              </IconButton>
            )}

            <IconButton
              color="error"
              aria-label="open drawer"
              onClick={() => {
                handleDelete(params.row);
              }}
              edge="end"
              sx={{ mr: 2 }}
            >
              <Delete />
            </IconButton>
          </div>
        );
      },
    },
  ];

  return (
    <Box sx={{ height: 600, width: '100%' }}>
      <Loader enable={waiting || loading} />
      <UserFiles
        setcurrentFiles={setcurrentFiles}
        files={currentFiles}
        open={openUserFilesDrawer}
        setOpen={setOpenUserFilesDrawer}
        refetch={refetch}
      />
      <UserCreate
        open={openCreaction}
        setOpen={setOpenCreaction}
        userCreated={currentCreation}
        refetch={refetch}
      ></UserCreate>
      <UserEdit refetch={refetch} open={openUserEdit} setOpen={setOpenUserEdit} userEdited={userEdited} />
      <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box component="span" sx={{ p: 2, align: 'end' }}>
          <Button
            data-testid="user-button-new"
            onClick={handleNewUser}
            variant="contained"
            color="warning"
            endIcon={<PersonAdd />}
          >
            {t('User.nouveau')}
          </Button>
        </Box>

        <TextField
          sx={{ p: 2 }}
          id="input-with-icon-textfield"
          inputProps={{ 'data-testid': 'user-textfield-search' }}
          label=""
          color="warning"
          value={search}
          onChange={handleSearch}
          InputProps={{
            startAdornment: (
              <InputAdornment position="end">
                <Search />
              </InputAdornment>
            ),
          }}
          variant="outlined"
        />
      </Box>
      <DataGrid
        rows={!loading && users ? (users as any) : []}
        columns={columns}
        columnBuffer={11}
        pageSize={8}
        rowsPerPageOptions={[5]}
        checkboxSelection
        disableSelectionOnClick
        experimentalFeatures={{ newEditingApi: true }}
      />
    </Box>
  );
};

export default User;
