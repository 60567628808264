import { gql } from '@apollo/client';
import { Books } from '../fragments/book';

export const CREATE_BOOK = gql`
  mutation CreateBook(
    $title: String
    $referralYears: String
    $pageNumber: String
    $publishedDate: Date
    $description: String
    $pdf: String
    $image: String
    $thematicId: String
  ) {
    createBook(
      title: $title
      referral_years: $referralYears
      page_number: $pageNumber
      published_date: $publishedDate
      description: $description
      pdf: $pdf
      image: $image
      thematicId: $thematicId
    ) {
      ...Books
    }
  }
  ${Books}
`;

export const UPDATE_BOOK = gql`
  mutation UpdateBook(
    $updateBookId: String
    $title: String
    $referralYears: String
    $publishedDate: Date
    $pageNumber: String
    $description: String
    $image: String
    $pdf: String
    $thematicId: String
  ) {
    updateBook(
      id: $updateBookId
      title: $title
      referral_years: $referralYears
      published_date: $publishedDate
      page_number: $pageNumber
      description: $description
      image: $image
      pdf: $pdf
      thematicId: $thematicId
    ) {
      ...Books
    }
  }
  ${Books}
`;

export const DELETE_BOOK = gql`
  mutation DeleteBook($deleteBookId: String) {
    deleteBook(id: $deleteBookId) {
      ...Books
    }
  }
  ${Books}
`;
