import { configureStore } from '@reduxjs/toolkit';
import { thematicReducer } from '../Reducers/ReducerThematic';
import { actualityReducer } from '../Reducers/ReducerActuality';
import { categoryReducer } from '../Reducers/ReducerCategory';
import { bookReducer } from '../Reducers/ReducerBook';
import { loaderReducer } from '../Reducers/ReducerLoader';

const store = configureStore({
  reducer: {
    thematic: thematicReducer,
    actuality: actualityReducer,
    category: categoryReducer,
    book: bookReducer,
    loader: loaderReducer,
  },
});

export default store;
