import React from 'react';
import { LISTE_ROUTES } from './route';
import Home from '../containers/Home';
import Candidaties from '../containers/Candidaties';
import Bibliotheque from '../containers/Bibliotheques';
import Actualities from '../containers/Actualities';
import Contacts from '../containers/Contacts';
import Users from '../containers/Users';
import Parametres from '../containers/Parametres';
import { useTranslation } from 'react-i18next';

interface RouteInterface {
  key: string;
  path: string;
  component: React.ComponentType<any> | React.LazyExoticComponent<React.ComponentType<any>>;
}

const PrivateRoutes = () => {
  const { t } = useTranslation();

  const privateRoutes: RouteInterface[] = [
    { key: t('home'), path: LISTE_ROUTES.DASHBOARD.path, component: Home },
    { key: t('candidaties'), path: LISTE_ROUTES.CANDIDATY.path, component: Candidaties },
    { key: t('actualities'), path: LISTE_ROUTES.ACTUALITY.path, component: Actualities },
    { key: t('library'), path: LISTE_ROUTES.BIBLIOTHEQUE.path, component: Bibliotheque },
    { key: t('contacts'), path: LISTE_ROUTES.CONTACT.path, component: Contacts },
    { key: t('parameter'), path: LISTE_ROUTES.PARAMETRE.path, component: Parametres },
    { key: t('user'), path: LISTE_ROUTES.USER.path, component: Users },
  ];

  return { privateRoutes };
};

export default PrivateRoutes;
