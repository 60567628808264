import { gql } from '@apollo/client';

const CREATE_CONTACT = gql`
  mutation CreateContact($name: String, $surname: String, $email: String, $tel: String, $message: String) {
    createContact(name: $name, surname: $surname, email: $email, tel: $tel, message: $message) {
      name
      surname
      email
      id
      tel
      message
    }
  }
`;

const DELETE_CONTACT = gql`
  mutation Mutation($id: ID) {
    deleteContact(id: $id)
  }
`;

export { CREATE_CONTACT, DELETE_CONTACT };
