import {
  Avatar,
  Box,
  Button,
  Container,
  CssBaseline,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import React from 'react';
import { ArrowBackIos } from '@mui/icons-material';
import useLogic from './useLogic';
import { LISTE_ROUTES } from '../../routes/route';
import GEIT_BO_Logo from '../Login/images/LogoBO.svg';

import Visibility from '../Login/images/MdiEyeOtline/MdiEyeOtline';
import VisibilityOff from '../Login/images/MdiEyeOtlineOff/MdiEyeOtlineOff';
import useStyles from './styles';

const theme = createTheme();

const Resetpassword = () => {
  const {
    passwordData,
    passwordType,
    showPassword,
    handleGoTo,
    handleChange,
    handleValidate,
    handleToggleVisibility,
  } = useLogic();
  const styles = useStyles()
  return (
    <ThemeProvider theme={theme}>
      <Box style={{ backgroundColor: '#ffffff', maxHeight: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
        <Container component="main" maxWidth="md" >
          <CssBaseline />
          <Box style={{width:'100%',}}  >
            <Box className={styles.ContentLogin}>
            <Button
              startIcon={<ArrowBackIos />}
              style={{ color: '#959595' }}
              onClick={() => handleGoTo(LISTE_ROUTES.LOGIN.path)}
            >
              Back
            </Button>
              <img src={GEIT_BO_Logo} alt="logo BO" style={{ alignSelf: 'center' }} />
              <Box className={styles.wrapLogin}>

                <Box
                  sx={{
                    // marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  {/* <Avatar sx={{ m: 1, bgcolor: '#ff9800' }}>
                  <LockOutlinedIcon />
                </Avatar> */}
                  <Typography component="h1" variant="h5">
                    Reset password
                  </Typography>
                  <Box component="form" noValidate sx={{ mt: 1 }}>
                    <TextField
                      autoFocus
                      fullWidth
                      id="newPassword"
                      label="New password"
                      margin="normal"
                      name="newPassword"
                      onChange={handleChange}
                      className={styles.input}
                      placeholder="Enter the new password"
                      value={passwordData.newPassword}
                      type={passwordType}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            position="end"
                            onClick={handleToggleVisibility}
                          >
                            {!showPassword ? <Visibility /> : <VisibilityOff />}
                          </InputAdornment>
                        ),
                      }}
                    />
                    <TextField
                      autoFocus
                      fullWidth
                      id="confirmPassword"
                      label="Confirm password"
                      margin="normal"
                      name="confirmPassword"
                      onChange={handleChange}
                      className={styles.input}
                      placeholder="Confirm your password"
                      value={passwordData.confirmPassword}
                    />
                    <Button
                      fullWidth
                      variant="contained"
                      style={{ marginTop: '32px' }}
                      // sx={{ mt: , mb: 2 }}
                      onClick={handleValidate}
                      className={styles.bgOrange}
                    >
                      Validate
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Container>
      </Box>
    </ThemeProvider>
  );
};

export default Resetpassword;