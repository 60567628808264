import { ActionType } from '../../utils/constant';
import { CategoryType } from '../../utils/interface';

export const ADD_CATEGORY = 'ADD_CATEGORY';
export const CATEGORIES_LIST = 'CATEGORIES_LIST';
export const SHOW_DIALOG_CATEGORY = 'SHOW_DIALOG_CATEGORY';
export const BUTTON_ACTION_DIALOG = 'BUTTON_ACTION_DIALOG';
export const addCategory = (category: any) => {
  return {
    type: ADD_CATEGORY,
    payload: {
      category,
    },
  };
};

export const showDialogCategory = (show: boolean) => {
  return {
    type: SHOW_DIALOG_CATEGORY,
    payload: {
      show,
    },
  };
};

export const categoriesList = (categories: CategoryType[]) => {
  return {
    type: CATEGORIES_LIST,
    payload: {
      categories: categories,
    },
  };
};

export const buttonActionDialog = (actionType: ActionType) => {
  return {
    type: BUTTON_ACTION_DIALOG,
    payload: {
      actionType,
    },
  };
};
