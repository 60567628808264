import { Delete, Edit } from '@mui/icons-material';
import { Box, Button, Grid, IconButton } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import { useThematics } from '../../../graphql/consumerHooks/thematic';
import { useDeleteThematicMutation } from '../../../graphql/mutationHooks/thematic';
import { GET_THEMATICS } from '../../../graphql/querries/thematic';
import { addThematic, buttonActionDialog, showDialogThematic } from '../../../Redux/Actions/ActionsThematic';
import { ActionType } from '../../../utils/constant';
import { ThematicType } from '../../../utils/interface';

const ThematicList = () => {
  const [thematics, setThematics] = useState<ThematicType[]>([]);
  const { data: dataThematics, loading: loadingThematics } = useThematics();
  const { deleteThematicMutation } = useDeleteThematicMutation();

  const { addToast } = useToasts();

  const handleUpdateThematic = async (clickedRowBook: ThematicType) => {
    dispatch(addThematic({ id: clickedRowBook.id, title: clickedRowBook.title }));
    dispatch(buttonActionDialog(ActionType.EDIT));
    dispatch(showDialogThematic(true));
  };

  const handleDeleteThematic = async (clickedRow: ThematicType) => {
    await deleteThematicMutation({
      variables: {
        deleteThematicId: clickedRow.id,
      },
      refetchQueries: [{ query: GET_THEMATICS }],
    })
      .then((res: any) => {
        addToast('Suppression avec succès', {
          appearance: 'success',
        });
      })
      .catch((error) => {
        addToast(error.message, {
          appearance: 'error',
        });
      });
  };

  const IconAction = (params: any) => {
    return (
      <Box>
        <IconButton
          style={{ color: 'green' }}
          onClick={() => {
            handleUpdateThematic(params.row);
          }}
        >
          <Edit />
        </IconButton>
        <IconButton
          style={{ color: 'red' }}
          onClick={() => {
            handleDeleteThematic(params.row);
          }}
        >
          <Delete />
        </IconButton>
      </Box>
    );
  };

  const columns: GridColDef[] = [
    { field: 'title', headerName: 'Titre', flex: 1 },
    { field: 'action', headerName: 'Action', renderCell: IconAction, flex: 1 },
  ];

  useEffect(() => {
    if (!loadingThematics && dataThematics) {
      const tabTemp: any[] = [];
      // eslint-disable-next-line array-callback-return
      dataThematics.map((item: any) => {
        tabTemp.push(item);
      });
      setThematics(tabTemp);
    }
  }, [dataThematics, loadingThematics]);

  const dispatch = useDispatch();

  const handleOpen = () => {
    dispatch(buttonActionDialog(ActionType.SAVE));
    dispatch(showDialogThematic(true));
  };

  return (
    <Box sx={{ height: 600, width: '100%' }}>
      <Grid xs={5}>
        <Button
          onClick={handleOpen}
          style={{ display: 'flex', marginBottom: '2%', textTransform: 'none' }}
          variant="contained"
        >
          Création
        </Button>
      </Grid>
      <DataGrid
        rows={thematics ? thematics : []}
        columns={columns}
        pageSize={8}
        rowsPerPageOptions={[5]}
        disableSelectionOnClick
        experimentalFeatures={{ newEditingApi: true }}
      />
    </Box>
  );
};

export default ThematicList;
