import { useMutation } from '@apollo/client';
import { UPDATE_PARAMETER_BY_STATUS, UPDATE_PARAMETER, CREATE_PARAMETER } from '../mutations/parameter';

export const useParameterByStatusMutation = () => {
  const [updateParameterByStatusMutation] = useMutation(UPDATE_PARAMETER_BY_STATUS);

  return { updateParameterByStatusMutation };
};

export const useParameterMutation = () => {
  const [updateParameterMutation] = useMutation(UPDATE_PARAMETER);

  return { updateParameterMutation };
};

export const useCreateParameterMutation = () => {
  const [createParameterMutation] = useMutation(CREATE_PARAMETER);

  return { createParameterMutation };
};
