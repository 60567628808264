import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { openFileUploadDialog } from '../../../components/UploadFile/UploadfileDialog';
import { useImageUploadMutation } from '../../../graphql/mutationHooks/image';
import { addActuality } from '../../../Redux/Actions/ActionActuality';
import { fileToBase64 } from '../../../utils/fileToBase64';
import { ActualityType } from '../../../utils/interface';
import { FetchResult } from '@apollo/client';

interface Image {
  uploadImage: string;
}

export const useLogic = () => {
  const action = useSelector((state: any) => state);

  const INITIAL_VALUE_ACTUALITY: ActualityType = {
    id: action.actuality.actuality.id ? action.actuality.actuality.id : '',
    title: action.actuality.actuality.id ? action.actuality.actuality.title : '',
    categoryId: action.actuality.actuality.categoryId ? action.actuality.actuality.categoryId : '',
    default_date: action.actuality.actuality.default_date ? action.actuality.actuality.default_date : null,
    end_date: action.actuality.actuality.end_date ? action.actuality.actuality.end_date : null,
    start_date: action.actuality.actuality.start_date ? action.actuality.actuality.start_date : null,
    image: action.actuality.actuality.image ? action.actuality.actuality.image : '',
    checked: action.actuality.actuality.checked ? action.actuality.actuality.checked : 0,
    active: action.actuality.actuality.active ? action.actuality.actuality.active : true,
    content: action.actuality.actuality.content ? action.actuality.actuality.content : '',
  };

  const dispatch = useDispatch();
  const [value, setValue] = useState<ActualityType>(INITIAL_VALUE_ACTUALITY);
  const [buttonFileName, setButtonFileName] = useState<string>('Fichier');
  const { imageUploadMutation } = useImageUploadMutation();

  const handleChange = (e: any) => {
    let next: ActualityType = INITIAL_VALUE_ACTUALITY;
    switch (e.target.name) {
      case 'title':
        next = { ...value, title: e.target.value };
        setValue(next);
        dispatch(addActuality(next));
        break;
      case 'category':
        next = { ...value, categoryId: e.target.value };
        setValue(next);
        dispatch(addActuality(next));
        break;

      default:
    }
  };

  const fileUpload = async () => {
    let file: string = '';
    let tab: any[] = [];
    setButtonFileName('Loading...');
    await openFileUploadDialog().then(async (event: any) => {
      file = (event.target.files || [])[0];
      if (file) {
        tab.push(file);
        await Promise.all(
          tab.map((item: any) => {
            return fileToBase64(item).then(async (res: string) => {
              await imageUploadMutation({
                variables: {
                  imageBase64: res.split(',')[1],
                  imageName: item.name,
                  nameFolder: false,
                },
              }).then((res: FetchResult<Image, Record<string, Image>, Record<string, Image>>) => {
                setValue({ ...value, image: res.data?.uploadImage ? res.data?.uploadImage : '' });
                dispatch(addActuality({ ...value, image: res.data?.uploadImage ? res.data?.uploadImage : '' }));
                setButtonFileName('Fichier');
              });
            });
          }),
        );
      }
    });
  };

  return {
    handleChange,
    value,
    setValue,
    fileUpload,
    buttonFileName,
  };
};
