import InputTextField from '../../../components/input/InputTextField';
import { Grid } from '@mui/material';
import { useState } from 'react';
import { ThematicType } from '../../../utils/interface';
import { useDispatch, useSelector } from 'react-redux';
import { addThematic } from '../../../Redux/Actions/ActionsThematic';

const ThematicForm = () => {
  const dispatch = useDispatch();
  const thematic = useSelector((state: any) => state);
  const [value, setValue] = useState<ThematicType>({
    id: thematic.thematic.thematic.id ? thematic.thematic.thematic.id : '',
    title: thematic.thematic.thematic.title ? thematic.thematic.thematic.title : '',
  });
  const handleChange = (e: any) => {
    let next: ThematicType = {
      id: thematic.thematic.thematic.id ? thematic.thematic.thematic.id : '',
      title: thematic.thematic.thematic.title ? thematic.thematic.thematic.title : '',
    };
    switch (e.target.name) {
      case 'title':
        next = { ...value, title: e.target.value };
        setValue(next);
        dispatch(addThematic(next));
        break;

      default:
    }
  };

  return (
    <>
      <Grid container padding={'1% 10% 2% 10%'}>
        <Grid xs={12}>
          <InputTextField
            style={{ width: '100%' }}
            name="title"
            label="Titre"
            value={value.title}
            onChange={handleChange}
            required
          />
        </Grid>
      </Grid>
    </>
  );
};

export default ThematicForm;
