import { gql } from '@apollo/client';

const GET_FILE_LINK = gql`
  query Query {
    getFileLinks {
      id
      link
      tier
    }
  }
`;

export { GET_FILE_LINK };
