import { makeStyles } from '@mui/styles'
export default makeStyles(() => ({
  wrapAllCard:{
    display:"flex"
  },
  wrapCard: {
    display: 'flex !important',
    justifyContent:"space-between",
    alignItems:'center'
  },
  rightSideCard:{
    display:'flex',
    flexDirection:'column',
    justifyContent:'space-between'
  }
 
}))
