import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { openFileUploadDialog, openPdfUploadDialog } from '../../../components/UploadFile/UploadfileDialog';
import { addBook } from '../../../Redux/Actions/ActionBook';
import { REFERRAL_YEARS } from '../../../utils/constant';
import { fileToBase64 } from '../../../utils/fileToBase64';
import { BookType } from '../../../utils/interface';

export const useLogic = () => {
  const action = useSelector((state: any) => state);
  const dispatch = useDispatch();

  const INITIAL_VALUE_BOOK: BookType = {
    id: action.book.book.id ? action.book.book.id : '',
    title: action.book.book.title ? action.book.book.title : '',
    published_date: action.book.book.published_date ? action.book.book.published_date : '',
    thematicId: action.book.book.thematicId ? action.book.book.thematicId : '',
    referral_years: action.book.book.referral_years ? action.book.book.referral_years : REFERRAL_YEARS[0],
    page_number: action.book.book.page_number ? action.book.book.page_number : 1,
    image: {
      imageBase64: action.book.book.image.imageBase64 ? action.book.book.image.imageBase64 : '',
      imageName: action.book.book.image.imageName ? action.book.book.image.imageName : '',
      path: action.book.book.image.path,
    },
    pdf: {
      pdfBase64: action.book.book.pdf.pdfBase64 ? action.book.book.pdf.pdfBase64 : '',
      pdfName: action.book.book.pdf.pdfName ? action.book.book.pdf.pdfName : '',
      path: action.book.book.pdf.path,
    },
    ressourceFiles: [],
    thematic: {
      id: action.book.book.thematic.id ? action.book.book.thematic.id : '',
      title: action.book.book.thematic.title ? action.book.book.thematic.title : '',
    },
    thematic_title: action.book.book.thematic_title ? action.book.book.thematic_title : '',
    content: action.book.book.content ? action.book.book.content : '',
    items: [],
  };

  const [value, setValue] = useState<BookType>(INITIAL_VALUE_BOOK);
  const [buttonFileNameImage, setButtonFileNameImage] = useState<string>('Upload Image');
  const [buttonFileNamePdf, setButtonFileNamePdf] = useState<string>('Upload document');

  const handleChange = (e: any) => {
    let next: BookType = INITIAL_VALUE_BOOK;
    switch (e.target.name) {
      case 'title':
        next = { ...value, title: e.target.value };
        setValue(next);
        dispatch(addBook(next));
        break;
      case 'referral_years':
        next = { ...value, referral_years: e.target.value };
        setValue(next);
        dispatch(addBook(next));
        break;
      case 'page_number':
        if (e.target.value <= 10000) {
          next = { ...value, page_number: e.target.value };
          setValue(next);
          dispatch(addBook(next));
        }
        break;
      case 'thematic':
        next = { ...value, thematicId: e.target.value };
        setValue(next);
        dispatch(addBook(next));
        break;
      default:
    }
  };

  const fileUpload = async () => {
    let file: string = '';
    let tab: any[] = [];
    setButtonFileNameImage('Loading...');
    await openFileUploadDialog().then(async (event: any) => {
      file = (event.target.files || [])[0];
      if (file) {
        tab.push(file);
        await Promise.all(
          tab.map((item: any) => {
            return fileToBase64(item).then(async (res: string) => {
              setValue({ ...value, image: { ...value?.image, imageBase64: res.split(',')[1], imageName: item.name } });
              dispatch(
                addBook({ ...value, image: { ...value?.image, imageBase64: res.split(',')[1], imageName: item.name } }),
              );
              setButtonFileNameImage('Upload Image');
            });
          }),
        );
      }
    });
  };

  const fileUploadPdf = async () => {
    let file: string = '';
    let tab: any[] = [];
    setButtonFileNamePdf('Loading...');
    await openPdfUploadDialog().then(async (event: any) => {
      file = (event.target.files || [])[0];
      if (file) {
        tab.push(file);
        await Promise.all(
          tab.map((item: any) => {
            return fileToBase64(item).then(async (res: string) => {
              setValue({ ...value, pdf: { ...value?.pdf, pdfBase64: res.split(',')[1], pdfName: item.name } });
              dispatch(addBook({ ...value, pdf: { ...value?.pdf, pdfBase64: res.split(',')[1], pdfName: item.name } }));
              setButtonFileNamePdf('Upload document');
            });
          }),
        );
      }
    });
  };

  return {
    handleChange,
    value,
    setValue,
    buttonFileNameImage,
    buttonFileNamePdf,
    fileUpload,
    fileUploadPdf,
  };
};
