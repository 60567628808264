import { gql } from '@apollo/client';

const CREATE_FILE_LINK = gql`
  mutation CreateFileLink($link: String, $tier: String!) {
    createFileLink(link: $link, tier: $tier) {
      id
      link
      tier
    }
  }
`;

const UPDATE_FILE_LINK = gql`
  mutation UpdateFileLink($id: ID, $link: String) {
    updateFileLink(id: $id, link: $link)
  }
`;

const DELETE_FILE_LINK = gql`
  mutation DeleteFileLink($id: ID) {
    deleteFileLink(id: $id)
  }
`;

export { CREATE_FILE_LINK, DELETE_FILE_LINK, UPDATE_FILE_LINK };
