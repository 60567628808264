import { ActionType } from '../../utils/constant';
import { CategoryType } from '../../utils/interface';
import { ADD_CATEGORY, SHOW_DIALOG_CATEGORY, BUTTON_ACTION_DIALOG, CATEGORIES_LIST } from '../Actions/ActionCategory';

interface Category {
  category: CategoryType;
  dataCategories: CategoryType[];
  show: boolean;
  actionType: ActionType;
}

const initialState: Category = {
  category: { id: '', name: '' },
  dataCategories: [],
  show: false,
  actionType: ActionType.SAVE,
};

export const categoryReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case ADD_CATEGORY:
      return { ...state, category: action.payload.category };
    case CATEGORIES_LIST:
      return { ...state, dataCategories: action.payload.categories };
    case SHOW_DIALOG_CATEGORY:
      return { ...state, show: action.payload.show };
    case BUTTON_ACTION_DIALOG:
      return { ...state, actionType: action.payload.actionType };
    default:
      return state;
  }
};
