import InputTextField from '../../../components/input/InputTextField';
import { Grid } from '@mui/material';
import { useState } from 'react';
import { ItemType } from '../../../utils/interface';
import InputAdornment from '@mui/material/InputAdornment';
import { validateNumericvalue } from '../../../utils/Validation';
import { useDispatch, useSelector } from 'react-redux';
import { addItem } from '../../../Redux/Actions/ActionBook';

const ItemForm = () => {
  const dispatch = useDispatch();
  const item = useSelector((state: any) => state);
  const [value, setValue] = useState<ItemType>({
    id: item.book.item.id ? item.book.item.id : '',
    price: item.book.item.price ? item.book.item.price : '',
    bookId: item.book.item.bookId ? item.book.item.bookId : '',
  });

  const handleChange = (e: any) => {
    let next: ItemType = {
      id: item.book.item.id ? item.book.item.id : '',
      price: item.book.item.price ? item.book.item.price : '',
      bookId: item.book.item.bookId ? item.book.item.bookId : '',
    };

    switch (e.target.name) {
      case 'price':
        const valueItem = e.target.value;
        if (validateNumericvalue(valueItem)) {
          next = { ...value, price: valueItem };
          setValue(next);
          dispatch(addItem(next));
        }
        if (valueItem === '') {
          next = { ...value, price: '' };
          setValue(next);
          dispatch(addItem(next));
        }
        break;

      default:
    }
  };

  return (
    <>
      <Grid container padding={'1% 10% 2% 10%'}>
        <Grid item xs={12}>
          <InputTextField
            name="price"
            label="Prix"
            sx={{ width: '100%' }}
            value={value.price}
            onChange={handleChange}
            required
            InputProps={{
              startAdornment: <InputAdornment position="start">Ar</InputAdornment>,
            }}
            inputProps={{ 'data-testid': 'price-input' }}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default ItemForm;
