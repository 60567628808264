import { useMutation } from '@apollo/client';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { SEND_VERIFICATION_CODE } from '../../graphql/mutations/SendVerificationCode';
import { VERIFY_CODE } from '../../graphql/mutations/VerifyCode';
import { LISTE_ROUTES } from '../../routes/route';

const useLogic = () => {
  const [code, setCode] = useState<number | string>('');
  const navigate = useNavigate();
  const { state } = useLocation();
  const [sendVerificationCode] = useMutation(SEND_VERIFICATION_CODE);
  const [VerifyCode] = useMutation(VERIFY_CODE);
  const { addToast } = useToasts();
  const { t } = useTranslation();
  const badCodeErrorMessage = t('errorVerification');
  const codeSendSuccessMessage = t('successVerification');

  const handleGoTo = (link: string) => {
    navigate(link);
  };

  const handleResendOTP = async () => {
    await sendVerificationCode({ variables: { type: state } })
      .then((response: any) => {
        if (response.data.sendVerificationCode) {
          addToast(codeSendSuccessMessage, { appearance: 'success' });
        }
      })
      .catch((err: any) => {
        console.log('error : ', err);
      });
  };

  const handleChangeCode = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCode(parseInt(e.target.value.trim()));
  };

  const handleVerifyOTP = async () => {
    await VerifyCode({ variables: { value: code } })
      .then((response: any) => {
        if (response.data.verifyCode) {
          navigate(LISTE_ROUTES.RESET_PASSWORD.path, { state });
        } else addToast(badCodeErrorMessage, { appearance: 'error' });
      })
      .catch((err: any) => console.log('error:', err));
    setCode('');
  };

  return {
    state,
    code,
    handleGoTo,
    handleResendOTP,
    handleChangeCode,
    handleVerifyOTP,
  };
};

export default useLogic;
