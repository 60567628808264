import { ActionType } from '../../utils/constant';
import { ThematicType } from '../../utils/interface';

export const ADD_THEMATIC = 'ADD_THEMATIC';
export const THEMATIC_LIST = 'THEMATIC_LIST';
export const SHOW_DIALOG_THEMATIC = 'SHOW_DIALOG_THEMATIC';
export const BUTTON_ACTION_DIALOG = 'BUTTON_ACTION_DIALOG';
export const addThematic = (thematic: ThematicType) => {
  return {
    type: ADD_THEMATIC,
    payload: {
      thematic,
    },
  };
};

export const showDialogThematic = (show: boolean) => {
  return {
    type: SHOW_DIALOG_THEMATIC,
    payload: {
      show,
    },
  };
};

export const thematicList = (thematics: ThematicType[]) => {
  return {
    type: THEMATIC_LIST,
    payload: {
      thematics,
    },
  };
};

export const buttonActionDialog = (actionType: ActionType) => {
  return {
    type: BUTTON_ACTION_DIALOG,
    payload: {
      actionType,
    },
  };
};
