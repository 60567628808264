import React, { useState } from 'react';
import { Box, Tab, Tabs } from '@mui/material';
import { FC } from 'react';
import ListeCandidaties from './ListeCandidaties';
import ParamCandidaties from './ParamCandidaties';

interface Props {}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel: FC<TabPanelProps> = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
};

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const Candidaties: FC<Props> = () => {
  const [value, setValue] = useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} TabIndicatorProps={{
          style: {backgroundColor: '#E85D04'}
        }}>
          <Tab
            sx={{ fontSize: 20, color: '#6C757D', '&.Mui-selected': { color: '#E85D04' } }}
            label="Liste"
            {...a11yProps(0)}
          />
          <Tab
            sx={{ fontSize: 20, color: '#6C757D', '&.Mui-selected': { color: '#E85D04' } }}
            label="Params"
            {...a11yProps(1)}
          />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <ListeCandidaties />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <ParamCandidaties />
      </TabPanel>
    </Box>
  );
};

export default Candidaties;
