import { TrendingDown, TrendingUp } from '@mui/icons-material';
import { Box, Card, CardContent, Icon, Typography } from '@mui/material';
import useStyles from './styles';

interface CardInterface {
  title: string;
  total: number;
  filtered: number;
  img?: any;
  background?: any;
}

const SimpleCard = ({ title, total, filtered, img, background }: CardInterface) => {
  const roundTwoDigit = (value: number) => {
    return Math.round(value * 100) / 100;
  };
  const calculatePercentage = (total: number, filtered: number) => {
    if (total === 0 || filtered === 0) {
      return 0;
    }
    return (filtered * 100) / total;
  };
  const percentage = calculatePercentage(total, filtered);
  const arrow = percentage >= 50 ? <TrendingUp /> : <TrendingDown />;
  // const backGroundPercentage = percentage >= 50 ? 'transparent' : 'transparent';
  const colorPercentage = percentage < 50 ? '#F47D7D' : '#86DC84';
  const styles = useStyles();

  return (
    <Card
      sx={{
        display: 'flex ',
        height: '208px',
        maxWidth: '560px',
        minWidth: 300,
        width: '100%',
        boxShadow: 'unset',
        marginBottom: 4,
        padding: 2,
        borderRadius: 4,
      }}
      className={styles.wrapAllCard}
    >
      <CardContent
        sx={{
          display: 'flex',
          width: '100%',
          justifyContent: 'space-between',
          paddingBottom: '0 !important'
        }}
      >
        <Box className={styles.rightSideCard}>
          <Box className={styles.wrapCard}>
            <Typography
              align="left"
              gutterBottom
              variant="body1"
              sx={{
                color: '#36414C',
                letterSpacing: '-1px',
                fontWeight: '600',
                fontSize: '24px',
                maxWidth: '360px',
                lineHeight: '24px',
              }}
            >
              {title}
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'start',
              justifyContent: 'space-between',
            }}
          >
            <Typography variant="body2" style={{ fontSize: 64, fontWeight: '400', lineHeight: '60px'}}>
              {filtered}
            </Typography>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'justify-center',
                // justifyContent: 'space-between',
                // backgroundColor: backGroundPercentage,
                padding: '0px 10px',
                borderRadius: '5px',
                color: '#ffffff',
              }}
            >
              <Icon style={{ color: colorPercentage }}>{arrow}</Icon>
              <Typography
                variant="h6"
                style={{
                  fontSize: 20,
                  lineHeight: '20px',
                  margin: 3,
                  color: '#C6CAD0',
                }}
              >
                {roundTwoDigit(percentage)} %
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box
          style={{
            backgroundColor: background,
            width: 98,
            height: 98,
            borderRadius: '50%',
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
          }}
        >
          <img src={img} alt="image" />
        </Box>
      </CardContent>
    </Card>
  );
};

export default SimpleCard;
