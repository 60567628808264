import { makeStyles } from '@mui/styles'

export default makeStyles(() => ({
    td: {
        fontSize: 16
    },
    wrapList: {
        '& .MuiTableCell-root': {
            fontSize: '16px'
        }
    },
    wrapListTable: {
        fontSize: '16px !important'
    },
    inputSearch: {
        fontSize: '16px',
        width: '100%',
        maxWidth: 512,
        height: 56,
        '&:focus-visible': {
            border: 'unset !important'
        },
        '& div': {
            borderRadius: 14,
            '&:focus-visible': {
                border: 'unset !important'
            }
        },
    },
    switchMod: {
        '& .MuiSwitch-root': {
            padding: 0,
            width: 26,
            height: 13,
            '& .Mui-checked': {
                color: "#6C757D",
                backgroundColor: "#6C757D",
                transform: "translateX(11px)"
            }
        },
        '& .MuiSwitch-thumb': {
            width: 11,
            height: 11
        },
        '& .MuiSwitch-switchBase': {
            padding: 1,
        }
    },
    inputText: {
        '& div': {
            borderRadius: 12,
            height: '52px !important',
            width: '100%',
            overflow: 'hidden'
        }
    },
    paperParam:{
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        flexDirection:'column',
        paddingTop:"102px",
        paddingBottom:"102px",
        "@media(max-width:1550px)":{
            padding:'102px 50px',
        },
        '& div':{
            margin:'unset'
        }
    }
}))
