import { gql } from '@apollo/client';

const GET_STUDENTS = gql`
  query GetStudents {
    getStudents {
      username
      year
      tuitionAmount
      feeAmount
      rest
    }
  }
`;

export { GET_STUDENTS };
