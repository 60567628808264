import * as React from 'react';
import { Box, Button, Grid, Stack, Typography, Pagination } from '@mui/material';
import { useEffect, useState } from 'react';
import { ActualityType } from '../../../utils/interface';
import { ActionType, DATE_NOW } from '../../../utils/constant';
import { useDispatch, useSelector } from 'react-redux';
import {
  addActualities,
  addActuality,
  buttonActionDialog,
  showDialogActuality,
} from '../../../Redux/Actions/ActionActuality';
import CardActuality from '../../../components/Card/CardActuality';
import { useTranslation } from 'react-i18next';
import { GET_ACTUALITIES } from '../../../graphql/querries/actuality';
import { useLazyQuery } from '@apollo/client';
import { LibraryBooksOutlined } from '@mui/icons-material';

const ActualitiesList = () => {
  const dispatch = useDispatch();
  const store = useSelector((state: any) => state);
  const [actualities, setActualities] = useState<ActualityType[]>([]);
  const { t } = useTranslation();
  const [isDeleted, setIsDeleted] = useState(false);
  const limit = 5;
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(1);
  const [getActualitiesBack, { refetch }] = useLazyQuery(GET_ACTUALITIES, {
    variables: {
      limit,
      page,
    },
    onCompleted: (data) => {
      setActualities(data.getActualitiesBack.actualities);
      setCount(data.getActualitiesBack.pageCount);
    },
  });

  useEffect(() => {
    if (actualities.length > 0) {
      dispatch(addActualities(actualities));
    }
  }, [actualities, dispatch]);

  useEffect(() => {
    getActualitiesBack();
    if (isDeleted || count) {
      refetch();
    }
    setIsDeleted(false);
  }, [page, isDeleted, count, actualities, getActualitiesBack, refetch, dispatch]);

  const handleAddActuality = () => {
    dispatch(
      addActuality({
        id: '',
        title: '',
        categoryId: store?.category?.dataCategories[0]?.id ? store?.category?.dataCategories[0]?.id : '',
        default_date: DATE_NOW.format('DD/MM/YYYY'),
        end_date: DATE_NOW.format('DD/MM/YYYY'),
        start_date: DATE_NOW.format('DD/MM/YYYY'),
        image: '',
        checked: 1,
        active: true,
        content: '',
      }),
    );
    dispatch(buttonActionDialog(ActionType.SAVE));
    dispatch(showDialogActuality(true));
  };
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  return (
    <Box sx={{ height: 600, width: '100%' }}>
      <Grid xs={5} style={{ margin: '25px 70px' }}>
        <Button
          onClick={handleAddActuality}
          variant="contained"
          endIcon={<LibraryBooksOutlined />}
          data-testid="actuality-liste-button-create"
          style={{ width: '15%', height: 50 }}
          sx={{
            bgcolor: '#388fb1',
            ':hover': {
              bgcolor: '#4c5b69',
            },
            ':active': {
              bgcolor: '#4c5b69',
            },
          }}
        >
          {t('Actuality.creation')}
        </Button>
      </Grid>

      <Typography style={{ paddingBottom: '2%', marginLeft: '36%' }}>Liste des actualités</Typography>
      <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: 50 }}>
        <Box
          style={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexWrap: 'nowrap',
          }}
        >
          {store.actuality.actualities.map((actuality: ActualityType) => {
            return <CardActuality actuality={actuality} />;
          })}
        </Box>
        <Box>
          <Stack spacing={2}>
            <Pagination count={count} page={page} onChange={handleChange} />
          </Stack>
        </Box>
      </Box>
    </Box>
  );
};
export default ActualitiesList;
