import { ApolloQueryResult, OperationVariables, useMutation, useQuery } from '@apollo/client';
import { useFormik } from 'formik';
import react, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';
import { DELETE_CANDIDATY } from '../../../graphql/mutations/Candidature';
import { CREATE_USER } from '../../../graphql/mutations/user';
import { GET_COUNTRY } from '../../../graphql/querries/country';
import { GET_DIPLOME } from '../../../graphql/querries/diplome';
import { GET_FORMATION } from '../../../graphql/querries/formation';
import { SelectChangeEvent } from '@mui/material/Select';

import { CountryTypes, FormationType, UserTypes } from '../../../utils/types';
import { validationSchema } from '../validation';

interface Props {
  userCreated: UserTypes | undefined;
  setOpen: react.Dispatch<react.SetStateAction<boolean>>;
  refetch: (variables?: Partial<OperationVariables> | undefined) => Promise<ApolloQueryResult<any>>;
}

const ROLE = {
  ADMIN: 'admin',
  STUDENT: 'student',
  MEMBER: 'member',
};

const useLogic = ({ userCreated, setOpen, refetch }: Props) => {
  const { addToast } = useToasts();
  const { t } = useTranslation();
  const [createUser] = useMutation(CREATE_USER);
  const [deleteCandidaty] = useMutation(DELETE_CANDIDATY);
  const steps = ['Information', 'Cursus'];
  const [activeStep, setActiveStep] = useState(0);

  const countryQuery = useQuery(GET_COUNTRY);
  const diplomeQuery = useQuery(GET_DIPLOME);
  const formationQuery = useQuery(GET_FORMATION);

  const [{ data: dataDiplome }, { data: dataFormation }, { data: dataCountry }] = [
    diplomeQuery,
    formationQuery,
    countryQuery,
  ];

  const diplome = dataDiplome?.getDeplomes;
  const formation: FormationType[] = (dataFormation?.getEducations as FormationType[]) || ([] as FormationType[]);
  const country: CountryTypes[] = (dataCountry?.getCountry as CountryTypes[]) || ([] as CountryTypes[]);

  const formik = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    validateOnMount: false,
    isInitialValid: false,
    initialValues: (userCreated as UserTypes) || ({} as UserTypes),
    validationSchema,
    onSubmit: (values) => {
      handleCreation(values);
      handleClose();
    },
  });

  const handleClose = () => {
    setActiveStep(0);
    formik.setValues(() => {
      return {} as UserTypes;
    });
    setOpen(false);
  };

  const handleRoleSelect = (e: SelectChangeEvent<string>) => {
    if (e.target.value === ROLE.ADMIN) {
      formik.setFieldValue('Course', undefined);
      formik.setErrors({});
    }

    formik.setFieldValue('Role.type', e.target.value);
  };

  const stepError1 = () =>
    (formik?.errors?.firstName && formik?.errors?.firstName?.length > 0) ||
    (formik?.errors?.Role && formik?.errors?.Role?.length > 0) ||
    (formik?.errors?.lastName && formik?.errors?.lastName?.length > 0) ||
    (formik?.errors?.tel && formik?.errors?.tel?.length > 0) ||
    (formik?.errors?.email && formik?.errors?.email?.length > 0) ||
    (formik?.errors?.username && formik?.errors?.username?.length > 0)
      ? true
      : false;

  const stepError2 = () => (formik?.errors?.Course ? true : false);

  const isLastStep = () => {
    return activeStep === steps.length - 1;
  };

  const handleCreation = async (values: UserTypes) => {
    const files: string[] = [];
    const path: string[] = [];
    formik.values?.RessourceFiles?.forEach((ressource) => {
      if (ressource?.link) files.push(ressource?.link);
      if (ressource?.path) path.push(ressource?.path);
    });
    await createUser({
      variables: {
        lastName: values?.lastName,
        firstName: values?.firstName,
        locationTown: values?.Location?.town,
        locationCountryId: values?.Location?.Country?.id,
        role: values?.Role?.type,
        address: values?.address,
        tel: values?.tel,
        status: true,
        username: values?.username,
        email: values?.email,
        files: files,
        path: path,
        educationYear: values?.Course && values?.Course[0]?.Curriculum?.year,
        educationName: values?.Course && values?.Course[0]?.Curriculum?.Education?.name,
      },
    })
      .then(async () => {
        handleClose();
        if (values?.id) {
          await deleteCandidaty({
            variables: {
              id: values?.id,
              deleteFiles: false,
            },
          })
            .then(() => {
              refetch();
            })
            .catch((error) => {
              addToast(error.message, {
                appearance: 'error',
              });
            });
        } else refetch();

        formik.setValues({} as UserTypes);
        addToast(t('successCreat'), {
          appearance: 'success',
        });
      })
      .catch((error) => {
        addToast(error.message, {
          appearance: 'error',
        });
      });
  };

  const handleNext = () => {
    if (!isLastStep()) setActiveStep(activeStep + 1);
    else {
      formik.submitForm();
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step: number) => () => {
    setActiveStep(step);
  };

  useEffect(() => {
    if (userCreated) formik.setValues(() => userCreated);
  }, [userCreated]);

  return {
    country,
    formation,
    handleClose,
    handleNext,
    handleBack,
    handleStep,
    handleRoleSelect,
    ROLE,
    activeStep,
    steps,
    formik,
    diplome,
    stepError1,
    stepError2,
  };
};

export default useLogic;
