import { gql } from '@apollo/client';

export const Categories = gql`
  fragment Categories on Category {
    id
    name
    actualities {
      active
      categoryId
      content
      end_date
      id
    }
  }
`;
