import { ApolloQueryResult, OperationVariables, useMutation } from '@apollo/client';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';
import { DELETE_RESSOURCE_FILE } from '../../../graphql/mutations/ressourceFile';
import { RessourceFilesTypes } from '../../../utils/types';

interface Props {
  files: RessourceFilesTypes[] | undefined;
  setcurrentFiles: React.Dispatch<React.SetStateAction<RessourceFilesTypes[] | undefined>>;
  refetch: (variables?: Partial<OperationVariables> | undefined) => Promise<ApolloQueryResult<any>>;
}

const useLogic = ({ files, setcurrentFiles, refetch }: Props) => {
  const { addToast } = useToasts();
  const { t } = useTranslation();
  const [deleteRessource, { loading }] = useMutation(DELETE_RESSOURCE_FILE);

  const handleDelete = async (file: RessourceFilesTypes) => {
    await deleteRessource({
      variables: {
        id: file.id,
      },
    })
      .then(() => {
        if (files) {
          let tempFile = [...files];
          const index = tempFile.indexOf(file);
          if (index >= 0) {
            tempFile?.splice(index, 1);
            setcurrentFiles(() => tempFile);
          }
          refetch();
        }
        addToast(t('successActi'), {
          appearance: 'success',
        });
      })
      .catch((error) => {
        addToast(error.message, {
          appearance: 'error',
        });
      });
  };
  return {
    loading,
    handleDelete,
  };
};

export default useLogic;
