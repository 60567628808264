import dayjs from 'dayjs';

export const compareTwoDate = (a: any, b: any) => {
  if (dayjs(a?.createdAt) < dayjs(b?.createdAt)) return -1;
  if (
    dayjs(a?.default_date) > dayjs(b?.start_date) ||
    dayjs(a?.start_date) > dayjs(b?.default_date) ||
    dayjs(a?.start_date) > dayjs(b?.start_date) ||
    dayjs(a?.default_date) > dayjs(b?.default_date)
  )
    return 1;
  return 0;
};

export const dateAndTimeToString = (dateAndTime: Date) => {
  const date = new Date(dateAndTime).toLocaleDateString();
  const time = new Date(dateAndTime).toLocaleTimeString();
  return `${date}, ${time}`;
};
