import MenuBookIcon from '@mui/icons-material/MenuBook';
import { Box, Dialog, DialogContent, Typography } from '@mui/material';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { IMAGE_EXTENSIONS } from '../../../utils/constant';
import { dateAndTimeToString } from '../../../utils/date';

interface DetailsInterface {
  open: boolean;
  handleClose: () => void;
}

const Details: FC<DetailsInterface> = ({ open, handleClose }) => {
  const dataBook = useSelector((state: any) => state);
  const { title, description, page_number, published_date, referral_years, thematic_title, items, ressourceFiles } =
    dataBook?.book?.book ?? {};
  const published_at = published_date
    ? `Date de publication : ${dateAndTimeToString(published_date as Date).split(',')[0]}`
    : null;
  const refferal = referral_years ? `Recommandé pour les ${referral_years}` : null;
  const thematic = thematic_title ? `Thématique : ${thematic_title}` : null;
  const bookDescription = description ? `Déscription : ${description}` : null;
  const bookPrice = items[0]?.price ? items[0]?.price : 0;
  const page = page_number > 1 ? `${page_number} pages` : `${page_number} page`;

  const getLink = () => {
    if (ressourceFiles?.length > 0)
      for (const ressource of ressourceFiles) {
        const pathSplit = ressource?.link?.split('.');
        const extension = pathSplit[pathSplit.length - 1].toLowerCase().split('?')[0];
        if (IMAGE_EXTENSIONS.includes(extension)) {
          return ressource.link;
        }
      }
  };
  const linkFile = getLink();

  return (
    <Box>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          '.css-1t1j96h-MuiPaper-root-MuiDialog-paper': {
            minWidth: '50vw',
            width: 'auto',
            height: 'auto',
          },
        }}
      >
        <DialogContent
          style={{
            display: 'flex',
            flexWrap: 'nowrap',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Box
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-around',
              height: 'auto',
            }}
          >
            <Typography variant="h4" style={{ color: '#00184c', marginBottom: 10 }}>
              {title}
            </Typography>
            <Typography variant="body1" style={{ marginBottom: 10 }}>
              {refferal}
            </Typography>
            <Box style={{ color: '#6C757D', marginBottom: 10 }}>
              <MenuBookIcon style={{ display: 'inline-flex', verticalAlign: 'top' }} />{' '}
              <span style={{ fontSize: 12, lineHeight: 0 }}>{page}</span>
            </Box>
            <Typography variant="body1" style={{ color: '#e95d04', marginBottom: 10 }}>
              {bookPrice} <sup>Ar</sup>
            </Typography>
            <Typography style={{ marginBottom: 10 }}>{published_at}</Typography>
            <Typography style={{ marginBottom: 10 }}>{thematic}</Typography>
            <Typography style={{ marginBottom: 10 }}>{bookDescription}</Typography>
          </Box>
          <Box>
            <img src={linkFile} alt="book" style={{ width: '300px' }} />
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default Details;
