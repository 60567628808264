import { SHOW_LOADER } from '../Actions/ActionLoader';

interface Loader {
  loader: boolean;
}

const initialState: Loader = {
  loader: false,
};

export const loaderReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SHOW_LOADER:
      return { ...state, loader: action.payload.loader };

    default:
      return state;
  }
};
