import { gql } from '@apollo/client';

const GET_PARAMETER = gql`
  query GetParameters {
    getParameters {
      id
      status
      type
      service
      user
      port
      ssl
      password
    }
  }
`;

export { GET_PARAMETER };
