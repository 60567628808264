import { gql } from '@apollo/client';

const CREATE_NOTIF_CANDIDAT = gql`
  mutation CreateNotifCandidat($candidature: String, $type: String) {
    createNotifCandidat(candidature: $candidature, type: $type)
  }
`;

export { CREATE_NOTIF_CANDIDAT };

const CREATE_NOTIF_CONTACT = gql`
  mutation CreateNotifContact($contact: String, $type: String) {
    createNotifContact(contact: $contact, type: $type)
  }
`;

export { CREATE_NOTIF_CONTACT };

const UPDATE_NOTIF_CANDIDAT = gql`
  mutation UpdateNotifCandidat($candidature: String, $type: String) {
    updateNotifCandidat(candidature: $candidature, type: $type)
  }
`;

export { UPDATE_NOTIF_CANDIDAT };

const UPDATE_NOTIF_CONTACT = gql`
  mutation UpdateNotifContact($contact: String, $type: String) {
    updateNotifContact(contact: $contact, type: $type)
  }
`;

export { UPDATE_NOTIF_CONTACT };
