import { Upload, Download } from '@mui/icons-material';
import { Button, ButtonGroup, Grid } from '@mui/material';
import { useRef } from 'react';
import Loader from '../../../components/Loader';
import useLogic from './useLogic';

const Migration = () => {
  const inputFileRef = useRef<HTMLInputElement | null>(null);

  const { handleImport, handleExport, handleUpload, loadingExport, loadingImport } = useLogic({
    inputFileRef,
  });

  return (
    <Grid container spacing={2} sx={{ height: 500 }}>
      <Loader enable={loadingExport || loadingImport}></Loader>
      <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <ButtonGroup variant="text" aria-label="text button group" sx={{ width: '100%' }}>
          <input ref={inputFileRef} hidden accept=".geit" multiple type="file" onChange={handleUpload} />
          <Button
            data-testid="parameter-migration-import"
            sx={{ width: '100%', height: 80 }}
            startIcon={<Upload />}
            onClick={handleImport}
          >
            Import
          </Button>
          <Button
            data-testid="parameter-migration-export"
            sx={{ width: '100%', height: 80 }}
            endIcon={<Download />}
            onClick={handleExport}
          >
            Export
          </Button>
        </ButtonGroup>
      </Grid>
    </Grid>
  );
};

export default Migration;
