import { gql } from '@apollo/client';
import { Actualities } from '../fragments/actuality';

const CREATE_ACTUALITY = gql`
  mutation CreateActuality(
    $title: String
    $image: String
    $defaultDate: Date
    $startDate: Date
    $endDate: Date
    $content: Text
    $active: Boolean
    $categoryId: String
  ) {
    createActuality(
      title: $title
      image: $image
      default_date: $defaultDate
      start_date: $startDate
      end_date: $endDate
      content: $content
      active: $active
      categoryId: $categoryId
    ) {
      ...Actualities
    }
  }
  ${Actualities}
`;

export { CREATE_ACTUALITY };

const UPDATE_ACTUALITY = gql`
  mutation UpdateActuality(
    $updateActualityId: String
    $title: String
    $image: String
    $content: Text
    $defaultDate: Date
    $startDate: Date
    $endDate: Date
    $active: Boolean
    $categoryId: String
  ) {
    updateActuality(
      id: $updateActualityId
      title: $title
      image: $image
      content: $content
      default_date: $defaultDate
      start_date: $startDate
      end_date: $endDate
      active: $active
      categoryId: $categoryId
    ) {
      ...Actualities
    }
  }
  ${Actualities}
`;

export { UPDATE_ACTUALITY };

const DELETE_ACTUALITY = gql`
  mutation DeleteActuality($deleteActualityId: String) {
    deleteActuality(id: $deleteActualityId) {
      ...Actualities
    }
  }
  ${Actualities}
`;

export { DELETE_ACTUALITY };
