import { useMutation } from '@apollo/client';
import { CREATE_BOOK, DELETE_BOOK, UPDATE_BOOK } from '../mutations/book';

export const useCreateBookMutation = () => {
  const [createBookMutation] = useMutation(CREATE_BOOK);

  return { createBookMutation };
};

export const useUpdateBookMutation = () => {
  const [updateBookMutation] = useMutation(UPDATE_BOOK);

  return { updateBookMutation };
};

export const useDeleteBookMutation = () => {
  const [deleteBookMutation] = useMutation(DELETE_BOOK);

  return { deleteBookMutation };
};
