import { useQuery } from '@apollo/client';
import React from 'react';
import Box from '@mui/material/Box';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { GET_STUDENTS } from '../../graphql/querries/Student';
import { useTranslation } from 'react-i18next';

const ListComponent = () => {
  const { t } = useTranslation();

  const { loading, data } = useQuery(GET_STUDENTS);

  const columns: GridColDef[] = [
    {
      field: 'username',
      headerName: t('Home.student') + '',
      width: 240,
      editable: false,
      flex: 1,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'tuitionAmount',
      headerName: t('Home.tuitionFees') + '',
      width: 300,
      editable: false,
      type: 'number',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      renderCell: ({ value }) => {
        return value + ' Ariary';
      },
    },
    {
      field: 'year',
      headerName: t('Home.year') + '',
      editable: false,
      width: 150,
      flex: 1,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'feeAmount',
      headerName: t('Home.amountPaid') + '',
      type: 'number',
      width: 300,
      editable: false,
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      renderCell: ({ value }) => {
        return value + ' Ariary';
      },
    },
    {
      field: 'rest',
      headerName: t('Home.remainingAmount') + '',
      type: 'number',
      width: 300,
      editable: false,
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      renderCell: ({ value }) => {
        return value + ' Ariary';
      },
    },
  ];

  return (
    <Box sx={{ height: 'auto', width: '100%',border:'unset !important', boxShadow:'unset', paddingLeft: 2.5, paddingRight: 2.5 }}>
      <DataGrid
        autoHeight        
        columns={columns}
        rows={data?.getStudents ?? []}
        disableColumnMenu
        disableSelectionOnClick
        getRowId={(row) => `${row.username}+${row.rest}`}
        pageSize={5}
        loading={loading}
        rowHeight={52}
        rowsPerPageOptions={[5, 10, 15]}
        experimentalFeatures={{ newEditingApi: true }}
        sx={{
          fontSize: 16,
          '.MuiDataGrid-columnSeparator': {
            display: 'none',
          },
          '&.MuiDataGrid-root': {
            border: 'none',
          },
        }}
      />
    </Box>
  );
};
export default ListComponent;