import React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { LISTE_ROUTES } from '../../routes/route';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import useLogic from './useLogic';
import { InputAdornment } from '@mui/material';
import Visibility from './images/MdiEyeOtline/MdiEyeOtline';
import VisibilityOff from './images/MdiEyeOtlineOff/MdiEyeOtlineOff';
import { useTranslation } from 'react-i18next';
import useStyles from './style';
import GEIT_BO_Logo from './images/LogoBO.svg';
const theme = createTheme();

const Login = () => {
  const { t } = useTranslation();
  const { signInData, passwordType, showPassword, handleChange, handleSubmit, handleToggleVisibility } = useLogic();
  const styles = useStyles();

  return (
    <ThemeProvider theme={theme}>
      <Box style={{ backgroundColor: '#ffffff' }}>
        <Container component="main" maxWidth="md">
          <CssBaseline />
          <Box className={styles.ContentLogin}>
            <img src={GEIT_BO_Logo} alt="logo BO" />
            <Box className="wrapLogin">
              {/* <Avatar sx={{ m: 1, bgcolor: '#ff9800' }}>
              <LockOutlinedIcon />
            </Avatar> */}
              <Typography component="h1" variant="h5" className={styles.titleConnection}>
                {t('Login.sign_in')}
              </Typography>
              <Box component="form" noValidate sx={{ mt: 1 }} className={styles.input}>
                <TextField
                  margin="normal"
                  fullWidth
                  value={signInData.email}
                  id="email"
                  label={t('Login.texfield_username')}
                  name="email"
                  autoComplete="email"
                  autoFocus
                  onChange={handleChange}
                  inputProps={{
                    'data-testid': 'login-field-email',
                    sx: {
                      color: '#6C757D',
                    },
                  }}
                  InputProps={{
                    sx: {
                      '&:hover fieldset': {
                        border: '1px solid #36414C !important',
                      },
                      '&:focus-within fieldset, &:focus-visible fieldset': {
                        border: '1px solid #36414C !important',
                      },
                    },
                  }}
                  InputLabelProps={{
                    sx: {
                      // color: '#36414C',
                      '&.Mui-focused': {
                        color: '#36414C',
                      },
                    },
                  }}
                />
                <TextField
                  margin="normal"
                  fullWidth
                  value={signInData.password}
                  name="password"
                  label={t('Login.texfield_password')}
                  type={passwordType}
                  id="password"
                  autoComplete="current-password"
                  onChange={handleChange}
                  inputProps={{
                    'data-testid': 'login-field-password',
                    sx: {
                      color: '#6C757D',
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end" onClick={handleToggleVisibility}>
                        {!showPassword ? <Visibility /> : <VisibilityOff />}
                      </InputAdornment>
                    ),
                    sx: {
                      '&:hover fieldset': {
                        border: '1px solid #36414C !important',
                      },
                      '&:focus-within fieldset, &:focus-visible fieldset': {
                        border: '1px solid #36414C !important',
                      },
                    },
                  }}
                  InputLabelProps={{
                    sx: {
                      // color: '#36414C',
                      '&.Mui-focused': {
                        color: '#36414C',
                      },
                    },
                  }}
                />
                <Box className={styles.rememberMe}>
                  <FormControlLabel
                    control={<Checkbox value="remember" color="primary" />}
                    label={t('Login.labe_remember_me')}
                  />
                </Box>
                <Button
                  fullWidth
                  variant="contained"
                  sx={{ mt: 1, mb: 2 }}
                  onClick={handleSubmit}
                  data-testid="login-button-submit"
                  className={styles.bgOrange}
                >
                  {t('Login.sign_in')}
                </Button>

                <Grid container>
                  <Grid item xs>
                    <Link href={LISTE_ROUTES.FORGOT_PASSWORD.path} variant="body2" className={styles.forgotPassword}>
                      {t('Login.link_forgot_password')}
                    </Link>
                  </Grid>
                  {/* <Grid item>
                  <Link href="#" variant="body2">
                    {t('Login.link_dont_have_account')}
                  </Link>
                </Grid> */}
                </Grid>
              </Box>
            </Box>
          </Box>
        </Container>
      </Box>
    </ThemeProvider>
  );
};

export default Login;
