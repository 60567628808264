import { gql } from '@apollo/client';
import { Thematics } from '../fragments/thematic';

export const CREATE_THEMATIC = gql`
  mutation CreateThematic($title: String) {
    createThematic(title: $title) {
      ...Thematics
    }
  }
  ${Thematics}
`;

export const UPDATE_THEMATIC = gql`
  mutation UpdateThematic($updateThematicId: String, $title: String) {
    updateThematic(id: $updateThematicId, title: $title) {
      ...Thematics
    }
  }
  ${Thematics}
`;

export const DELETE_THEMATIC = gql`
  mutation DeleteThematic($deleteThematicId: String) {
    deleteThematic(id: $deleteThematicId) {
      ...Thematics
    }
  }
  ${Thematics}
`;
