import { COLORS } from './constant';

interface NameOptions {
  firstName?: string;
  lastName?: string;
  username?: string;
}

export const getColor = (name: string) => {
  const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const index = letters.indexOf(name[0].toUpperCase());

  return COLORS[index];
};

const checkFirstLetter = (word: string) => {
  return Array.from(word)[0]?.toUpperCase();
};

export const getFirstLetter = ({ firstName = '', lastName = '', username = '' }: NameOptions) => {
  if (firstName && lastName) return `${checkFirstLetter(firstName)}${checkFirstLetter(lastName)}`;
  return checkFirstLetter(username) || checkFirstLetter(firstName) || checkFirstLetter(lastName);
};

export const getName = ({ firstName = '', lastName = '', username = '' }: NameOptions) => {
  if (firstName && lastName) return `${firstName} ${lastName}`;
  return username || firstName || lastName;
};
