import { Box, Tab, Tabs } from '@mui/material';
import ActualitiesList from './Actuality/ActualitiesList';
import CategoriesList from './Category/CategoriesList';
import DialogCustom from '../../components/Dialog';
import { FC, useEffect, useState } from 'react';
import FormActuality from './Actuality/ActualityForm';
import FormCategory from './Category/CategoryForm';
import Action from './Actuality/ActionActuality';
import ActionCategory from './Category/ActionCategory';
import { useCategories } from '../../graphql/consumerHooks/category';
import { DATE_NOW, TITLE } from '../../utils/constant';
import { CategoryType } from '../../utils/interface';
import Loader from '../../components/Loader';
import { useDispatch, useSelector } from 'react-redux';
import { addCategory, categoriesList, showDialogCategory } from '../../Redux/Actions/ActionCategory';
import { addActuality, showDialogActuality } from '../../Redux/Actions/ActionActuality';

import { useTranslation } from 'react-i18next';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel: FC<TabPanelProps> = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
};

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const Actualities = () => {
  const { t } = useTranslation();

  const [panel, setPanel] = useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setPanel(newValue);
  };
  const { data: dataCategories, loading } = useCategories();
  const dispatch = useDispatch();
  const action = useSelector((state: any) => state);

  useEffect(() => {
    if (!loading && dataCategories) {
      const categories: CategoryType[] = [];
      dataCategories?.map((item: any) => {
        return categories.push({ id: item?.id, name: item?.name });
      });

      dispatch(categoriesList(categories));
    }
  }, [loading, dataCategories, dispatch]);

  const handleCloseActuality = () => {
    dispatch(showDialogActuality(false));
    dispatch(
      addActuality({
        id: '',
        active: true,
        categoryId: action.category.dataCategories[0]?.id,
        checked: 1,
        default_date: DATE_NOW.format('DD/MM/YYYY'),
        end_date: DATE_NOW.format('DD/MM/YYYY'),
        start_date: DATE_NOW.format('DD/MM/YYYY'),
        image: '',
        title: '',
        content: '',
      }),
    );
  };

  const handleCloseCategory = () => {
    dispatch(showDialogCategory(false));
    dispatch(
      addCategory({
        id: '',
        name: '',
      }),
    );
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={panel} onChange={handleChange}>
          <Tab label={t('actuality') + ''} {...a11yProps(0)} data-testid="actuality-button-list" />
          <Tab label={t('category') + ''} {...a11yProps(1)} data-testid="actuality-button-thematic" />
        </Tabs>
      </Box>
      <Loader enable={action?.loader?.loader} />
      <DialogCustom
        title={TITLE['actuality']}
        Action={Action}
        Content={FormActuality}
        open={action?.actuality?.show}
        handleClose={handleCloseActuality}
      />
      <DialogCustom
        title={TITLE['category']}
        Action={ActionCategory}
        Content={FormCategory}
        open={action?.category?.show}
        handleClose={handleCloseCategory}
      />

      <TabPanel value={panel} index={0}>
        <ActualitiesList />
      </TabPanel>
      <TabPanel value={panel} index={1}>
        <CategoriesList />
      </TabPanel>
    </Box>
  );
};

export default Actualities;
