import { gql } from '@apollo/client';

export const Candidatures = gql`
  fragment Candidatures on Candidacy {
    id
    name
    surname
    email
    files
    path
    tel
    message
    formation
    year
    createdAt
  }
`;
