import react from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { CountryTypes, FormationType, UserTypes } from '../../../utils/types';
import { Grid, MenuItem, MobileStepper, Select, Step, Stepper, Typography, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import useLogic from './useLogic';
import { ApolloQueryResult, OperationVariables } from '@apollo/client';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import StepLabel from '@mui/material/StepLabel';

import { MuiTelInput } from 'mui-tel-input';
import { useTranslation } from 'react-i18next';

import '../style.css';

interface Props {
  open: boolean;
  setOpen: react.Dispatch<react.SetStateAction<boolean>>;
  userCreated: UserTypes | undefined;
  refetch: (variables?: Partial<OperationVariables> | undefined) => Promise<ApolloQueryResult<any>>;
}

const ANNEE_ETUDE = ['L1', 'L2', 'L3', 'M1', 'M2'];

const UserCreate: React.FC<Props> = ({ open, setOpen, userCreated, refetch }) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const {
    handleClose,
    formik,
    country,
    handleNext,
    handleBack,
    handleStep,
    handleRoleSelect,
    ROLE,
    activeStep,
    steps,
    stepError1,
    stepError2,
    formation,
    diplome,
  } = useLogic({
    userCreated,
    setOpen,
    refetch,
  });

  return (
    <>
      <Dialog open={open} onClose={handleClose} maxWidth={'md'} fullWidth={true}>
        <Typography variant="h4" sx={{ alignSelf: 'center', marginTop: 5 }}>
          {steps[activeStep]}{' '}
        </Typography>
        <Stepper nonLinear activeStep={activeStep} sx={{ margin: 10, marginTop: 5, marginBottom: 5 }}>
          {steps?.map((label, index) => {
            const labelProps: {
              optional?: React.ReactNode;
              error?: boolean;
            } = {};

            if (index === 0 ? stepError1() : stepError2()) {
              labelProps.optional = (
                <Typography variant="caption" color="error">
                  {t('User.error')}
                </Typography>
              );
              labelProps.error = true;
            }

            return (
              <Step key={label} {...labelProps} completed={false}>
                <StepLabel onClick={handleStep(index)} {...labelProps}>
                  {label}
                </StepLabel>
              </Step>
            );
          })}
        </Stepper>
        <form onSubmit={formik.handleSubmit}>
          <DialogContent>
            {activeStep === 0 && (
              <>
                <Grid container rowSpacing={1} columnSpacing={{ xs: 4, sm: 4, md: 4 }} sx={{ width: '100%' }}>
                  <Grid item xs={6} sm={6} md={6}>
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      id="username"
                      inputProps={{ 'data-testid': 'userCreate-textfield-username' }}
                      margin="dense"
                      value={formik?.values?.username}
                      onChange={formik.handleChange}
                      error={formik.errors?.username ? true : false}
                      label={t('User.nickname')}
                      type="text"
                      fullWidth
                      variant="standard"
                      required
                    />
                  </Grid>
                  <Grid item xs={6} sm={6} md={6}>
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      margin="dense"
                      inputProps={{ 'data-testid': 'userCreate-textfield-firstName' }}
                      value={formik?.values?.firstName}
                      error={formik.errors?.firstName ? true : false}
                      onChange={formik.handleChange}
                      id="firstName"
                      label={t('User.firstName')}
                      type="text"
                      fullWidth
                      variant="standard"
                      required
                    />
                  </Grid>
                  <Grid item xs={6} sm={6} md={6}>
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      margin="dense"
                      id="lastName"
                      inputProps={{ 'data-testid': 'userCreate-textfield-lastName' }}
                      error={formik.errors?.lastName ? true : false}
                      onChange={formik.handleChange}
                      label={t('User.lastName')}
                      value={formik?.values?.lastName}
                      type="text"
                      fullWidth
                      variant="standard"
                      required
                    />
                  </Grid>
                  <Grid item xs={6} sm={6} md={6}>
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      margin="dense"
                      value={formik?.values?.email}
                      error={formik.errors?.email ? true : false}
                      onChange={formik.handleChange}
                      id="email"
                      inputProps={{ 'data-testid': 'userCreate-textfield-email' }}
                      name="email"
                      label="Email Address"
                      fullWidth
                      variant="standard"
                      required
                    />
                  </Grid>
                  <Grid item xs={6} sm={6} md={6}>
                    <MuiTelInput
                      id="tel"
                      inputProps={{ 'data-testid': 'userCreate-textfield-tel' }}
                      name="tel"
                      margin="dense"
                      value={formik?.values?.tel}
                      error={formik.errors?.tel ? true : false}
                      onChange={(val) => formik.setFieldValue('tel', val.replaceAll(' ', ''))}
                      defaultCountry="MG"
                      label={t('User.phone')}
                      fullWidth
                      variant="standard"
                      required
                    />
                  </Grid>
                  <Grid item xs={6} sm={6} md={6}>
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      margin="dense"
                      value={formik?.values?.address}
                      error={formik.errors?.address ? true : false}
                      onChange={formik.handleChange}
                      id="address"
                      inputProps={{ 'data-testid': 'userCreate-textfield-address' }}
                      label={t('User.adress')}
                      type="text"
                      fullWidth
                      variant="standard"
                      required
                    />
                  </Grid>
                  <Grid item xs={6} sm={6} md={6}>
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      margin="dense"
                      value={formik?.values?.Location?.town}
                      onChange={formik.handleChange}
                      // onChange={handleForm}
                      id="Location.town"
                      inputProps={{ 'data-testid': 'userCreate-textfield-town' }}
                      label={t('User.city')}
                      type="text"
                      fullWidth
                      variant="standard"
                    />
                  </Grid>{' '}
                  <Grid item xs={6} sm={6} md={6}></Grid>
                  <Grid item xs={6} sm={6} md={6}>
                    <Box sx={{ marginTop: 2 }}>
                      <Typography variant="caption" display="block" color={'GrayText'}>
                        {t('User.country')}
                      </Typography>
                      <Select
                        labelId="country"
                        id="Location.Country.id"
                        inputProps={{ 'data-testid': 'userCreate-select-country' }}
                        name="Location.Country.id"
                        value={formik?.values?.Location?.Country?.id || ''}
                        onChange={(e) => formik.setFieldValue('Location.Country.id', e.target.value)}
                        label={t('User.country')}
                        // onChange={handleSelectRegion}
                        variant="standard"
                        sx={{ width: '100%' }}
                      >
                        {country?.map((cntr: CountryTypes) => (
                          <MenuItem key={cntr?.id} value={cntr?.id}>
                            {cntr.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </Box>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6}>
                    <Box sx={{ marginTop: 2 }}>
                      <Typography variant="caption" display="block" color={'GrayText'}>
                        {t('User.role')}*
                      </Typography>
                      <Select
                        labelId="role"
                        label="Role"
                        id="Role.type"
                        inputProps={{ 'data-testid': 'userCreate-select-role' }}
                        name="Role.type"
                        value={formik?.values?.Role?.type || ''}
                        error={formik.errors?.Role ? true : false}
                        onChange={handleRoleSelect}
                        variant="standard"
                        sx={{ width: '100%' }}
                      >
                        <MenuItem value={ROLE.ADMIN}>{t('Role.admin')}</MenuItem>
                        <MenuItem value={ROLE.MEMBER}>{t('Role.member')}</MenuItem>
                        <MenuItem value={ROLE.STUDENT}>{t('Role.student')}</MenuItem>
                      </Select>
                    </Box>
                  </Grid>
                </Grid>
              </>
            )}

            {activeStep === 1 && (
              <>
                <Grid container rowSpacing={1} columnSpacing={{ xs: 4, sm: 4, md: 4 }} sx={{ width: '100%' }}>
                  <Grid item xs={6} sm={6} md={6}>
                    <Box sx={{ marginTop: 2 }}>
                      <Typography variant="caption" display="block" color={'GrayText'}>
                        {t('User.study_year')}*
                      </Typography>
                      <Select
                        labelId="year"
                        id="Year"
                        // inputProps={{ 'data-testid': 'usercreate-select-year-input' }}
                        data-testid="usercreate-select-year"
                        name="Year"
                        value={
                          (formik?.values?.Course &&
                            formik?.values?.Course[0] &&
                            formik?.values?.Course[0]?.Curriculum?.year) ||
                          ''
                        }
                        onChange={(e) => formik.setFieldValue('Course[0].Curriculum.year', e.target.value)}
                        label="Année d'études"
                        variant="standard"
                        sx={{
                          width: '100%',
                        }}
                        error={
                          stepError2() &&
                          !(
                            formik?.values?.Course &&
                            formik?.values?.Course[0] &&
                            formik?.values?.Course[0]?.Curriculum?.year
                          )
                        }
                        disabled={formik?.values?.Role?.type === 'admin'}
                      >
                        {ANNEE_ETUDE?.map((etude) => (
                          <MenuItem key={etude} value={etude}>
                            {etude}
                          </MenuItem>
                        ))}
                      </Select>
                    </Box>
                  </Grid>

                  <Grid item xs={6} sm={6} md={6}>
                    <Box sx={{ marginTop: 2 }}>
                      <Typography variant="caption" display="block" color={'GrayText'}>
                        Formation*
                      </Typography>
                      <Select
                        labelId="etude"
                        id="Course[0].Curriculum.Education.name"
                        // inputProps={{ 'data-testid': 'userCreate-select-etude-input' }}
                        data-testid="userCreate-select-etude"
                        name="Course[0].Curriculum.Education.name"
                        onChange={(e) => formik.setFieldValue('Course[0].Curriculum.Education.name', e.target.value)}
                        value={
                          formik?.values?.Course &&
                          formik?.values?.Course[0] &&
                          formik?.values?.Course[0]?.Curriculum?.Education?.name
                        }
                        label="etude"
                        variant="standard"
                        sx={{ width: '100%' }}
                        disabled={formik?.values?.Role?.type === 'admin'}
                        error={
                          stepError2() &&
                          !(
                            formik?.values?.Course &&
                            formik?.values?.Course[0] &&
                            formik?.values?.Course[0]?.Curriculum?.Education?.name
                          )
                        }
                      >
                        {formation?.map((formation: FormationType) => (
                          <MenuItem key={formation?.name} value={formation?.name}>
                            {formation?.title}
                          </MenuItem>
                        ))}
                      </Select>
                    </Box>
                  </Grid>

                  <Grid item xs={6} sm={6} md={6}>
                    <Box sx={{ marginTop: 2 }}>
                      <Typography variant="caption" display="block" color={'GrayText'}>
                        {t('User.diploma')}
                      </Typography>
                      <Select
                        labelId="etude"
                        id="Course.Curriculum.year"
                        // inputProps={{ 'data-testid': 'userCreate-select-course-input' }}
                        data-testid="userCreate-select-course"
                        name="Course.Curriculum.year"
                        value={
                          (formik?.values?.Course &&
                            formik?.values?.Course[0] &&
                            formik?.values?.Course[0]?.Curriculum?.year?.charAt(0)) ||
                          'L'
                        }
                        label="etude"
                        variant="standard"
                        sx={{ width: '100%' }}
                        disabled
                      >
                        {diplome?.map((dipl: any) => (
                          <MenuItem key={dipl?.type} value={dipl?.type?.charAt(0)}>
                            {dipl?.type}
                          </MenuItem>
                        ))}
                      </Select>
                    </Box>
                  </Grid>
                </Grid>
              </>
            )}
          </DialogContent>
          <MobileStepper
            variant="dots"
            steps={2}
            position="static"
            activeStep={activeStep}
            sx={{ flexGrow: 1, margin: 10 }}
            nextButton={
              <Button data-testid="usercreate-button-next" size="small" onClick={handleNext}>
                {activeStep < 1 ? t('User.next') : t('User.send')}
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
              </Button>
            }
            backButton={
              <Button
                data-testid="usercreate-button-back"
                size="small"
                onClick={handleBack}
                disabled={activeStep === 0}
              >
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                {t('User.back')}
              </Button>
            }
          />
        </form>
      </Dialog>
    </>
  );
};

export default UserCreate;
