import { DeleteOutline, EditOutlined, NotificationsOff, Notifications } from '@mui/icons-material';
import { Box, IconButton, Tooltip } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import dayjs from 'dayjs';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import { useDeleteActualityMutation, useUpdateActualityMutation } from '../../graphql/mutationHooks/actuality';
import {
  addActualities,
  addActuality,
  buttonActionDialog,
  showDialogActuality,
} from '../../Redux/Actions/ActionActuality';
import { ActionType } from '../../utils/constant';
import { ActualityType } from '../../utils/interface';

interface CardInterface {
  actuality: ActualityType;
}

const CardBook: FC<CardInterface> = ({ actuality }) => {
  const ActualityId = actuality?.id ? actuality?.id : '';
  const { updateActualityMutation } = useUpdateActualityMutation();
  const { deleteActualityMutation } = useDeleteActualityMutation();
  const store = useSelector((state: any) => state);
  const { addToast } = useToasts();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const handleDeleteActuality = async (id: string) => {
    await deleteActualityMutation({
      variables: {
        deleteActualityId: id,
      },
    })
      .then((res: any) => {
        const tabTemp: ActualityType[] = [];
        res.data.deleteActuality.map((item: ActualityType) => {
          return tabTemp.push(item);
        });
        dispatch(addActualities(tabTemp));

        addToast(t('successDelete'), {
          appearance: 'success',
        });
      })
      .catch((error) => {
        addToast(error.message, {
          appearance: 'error',
        });
      });
  };
  const handleUpdateActuality = (clickedRowActuality: ActualityType) => {
    if (clickedRowActuality.default_date) {
      dispatch(
        addActuality({
          id: clickedRowActuality.id,
          title: clickedRowActuality.title,
          default_date: clickedRowActuality.default_date
            ? dayjs(clickedRowActuality.default_date).format('DD/MM/YYYY')
            : null,
          start_date: clickedRowActuality.start_date,
          end_date: clickedRowActuality.end_date,
          active: clickedRowActuality.active,
          categoryId: clickedRowActuality.categoryId,
          checked: 1,
          content: clickedRowActuality.content,
          image: clickedRowActuality.image,
        }),
      );
    }
    if (clickedRowActuality.start_date && clickedRowActuality.end_date) {
      dispatch(
        addActuality({
          id: clickedRowActuality.id,
          title: clickedRowActuality.title,
          default_date: clickedRowActuality.default_date,
          start_date: clickedRowActuality.start_date
            ? dayjs(clickedRowActuality.start_date).format('DD/MM/YYYY')
            : null,
          end_date: clickedRowActuality.end_date ? dayjs(clickedRowActuality.end_date).format('DD/MM/YYYY') : null,
          active: clickedRowActuality.active,
          categoryId: clickedRowActuality.categoryId,
          checked: 0,
          content: clickedRowActuality.content,
          image: clickedRowActuality.image,
        }),
      );
    }

    dispatch(buttonActionDialog(ActionType.EDIT));
    dispatch(showDialogActuality(true));
  };
  const handleActive = (clickedActuality: ActualityType | undefined) => {
    const updatedActualities = store.actuality.actualities.map((actuality: ActualityType) => {
      if (clickedActuality?.id === actuality.id) {
        updateActualityMutation({
          variables: {
            updateActualityId: clickedActuality?.id,
            title: clickedActuality?.title,
            defaultDate: clickedActuality?.default_date,
            startDate: clickedActuality?.start_date,
            endDate: clickedActuality?.end_date,
            active: !clickedActuality?.active,
            content: clickedActuality?.content,
            image: clickedActuality?.image,
          },
        });
        return {
          ...actuality,
          active: !clickedActuality?.active,
        };
      }

      return actuality;
    });
    dispatch(addActualities(updatedActualities));
  };

  return (
    <Card
      style={{
        width: 230,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        height: 'auto',
        borderRadius: 20,
        // boxShadow: '0 8px 10px rgb(0 0 0 / 0.08)',
        padding: 10,
        margin: 30,
      }}
    >
      <CardMedia
        component="img"
        alt="green iguana"
        height="140"
        src={actuality?.image}
        style={{ width: '96%', borderRadius: '20px', margin: '2% 2% 6% 2%', minHeight: 300 }}
      />
      <Box style={{ display: 'flex', flexDirection: 'column' }}>
        <CardContent
          style={{
            padding: 0,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: 'auto',
            width: 'auto',
            margin: 10,
          }}
        >
          <Typography variant="h6" style={{ fontWeight: 'bolder', marginBottom: 0 }}>
            {actuality?.title}
          </Typography>
          <Typography variant="body2" style={{ color: '#6C757D', textTransform: 'capitalize' }}>
            {actuality?.category?.name}
          </Typography>
        </CardContent>
      </Box>
      <Box
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-around',
          width: '-webkit-fill-available',
          marginTop: 10,
        }}
      >
        <Tooltip title={actuality.active ? 'Desactiver' : 'Activer'} placement="bottom" components={{ Tooltip: 'p' }}>
          {actuality.active ? (
            <IconButton onClick={() => handleActive(actuality)}>
              <Notifications
                sx={{
                  color: '#e85d04',
                  ':hover': {
                    color: '#ff9800',
                  },
                  ':active': {
                    color: '#ce334d',
                  },
                }}
              />
            </IconButton>
          ) : (
            <IconButton onClick={() => handleActive(actuality)}>
              <NotificationsOff
                sx={{
                  color: '#e85d04',
                  ':hover': {
                    color: '#ff9800',
                  },
                  ':active': {
                    color: '#ce334d',
                  },
                }}
              />
            </IconButton>
          )}
        </Tooltip>
        <Tooltip title="Modifier le livre" placement="bottom" components={{ Tooltip: 'p' }}>
          <IconButton onClick={() => handleUpdateActuality(actuality)}>
            <EditOutlined
              sx={{
                color: '#e85d04',
                ':hover': {
                  color: '#ff9800',
                },
                ':active': {
                  color: '#ce334d',
                },
              }}
            />
          </IconButton>
        </Tooltip>
        <Tooltip title="Supprimer le livre" placement="bottom" components={{ Tooltip: 'p' }}>
          <IconButton onClick={() => handleDeleteActuality(ActualityId)}>
            <DeleteOutline
              sx={{
                color: '#e85d04',
                ':hover': {
                  color: '#ff9800',
                },
                ':active': {
                  color: '#ce334d',
                },
              }}
            />
          </IconButton>
        </Tooltip>
      </Box>
    </Card>
  );
};

export default CardBook;
