import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { LISTE_ROUTES } from '../../routes/route';
import { useMutation } from '@apollo/client';
import { SEND_VERIFICATION_CODE } from '../../graphql/mutations/SendVerificationCode';
import { validateEmail } from '../../utils/Validation';
import { useTranslation } from 'react-i18next';

const useLogic = () => {
  const [email, setEmail] = useState<string>('');
  const navigate = useNavigate();
  const { addToast } = useToasts();
  const { t } = useTranslation();
  const emptyErrorMessage = t('erroEmptyMail');
  const invalidErrorMessage = t('errorInvalidMail');
  const badUserErrorMessage = t('errorInvalidUser');
  const invalidParameterErrorMessage = t('errorInvalidParams');
  const codeSendedSuccessMessage = t('successVerification');
  const [sendVerificationCode, {}] = useMutation(SEND_VERIFICATION_CODE);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value.trim());
  };

  const hanldeClick = async () => {
    if (!email)
      addToast(emptyErrorMessage, {
        appearance: 'error',
      });
    else if (email && !validateEmail(email)) {
      addToast(invalidErrorMessage, { appearance: 'error' });
    } else {
      await sendVerificationCode({ variables: { type: email } })
        .then((response: any) => {
          if (!response.data.sendVerificationCode) addToast(badUserErrorMessage, { appearance: 'error' });
          else if (response.data.sendVerificationCode === 'invalid parameter') {
            addToast(invalidParameterErrorMessage, { appearance: 'error' });
          } else if (response.data.sendVerificationCode !== 'invalid parameter') {
            addToast(codeSendedSuccessMessage, { appearance: 'success' });
            navigate(LISTE_ROUTES.VERIFY_CODE.path, { state: email });
          }
        })
        .catch((err: any) => {
          console.log('error : ', err);
        });
      setEmail('');
    }
  };

  const isDisabled = email ? false : true;
  return {
    email,
    isDisabled,
    handleChange,
    hanldeClick,
  };
};

export default useLogic;
