import { Card, CardContent } from '@mui/material';
import React, { FC } from 'react';
import AddIcon from '@mui/icons-material/Add';

interface AddBookInterface {
  handleAddBook: () => void;
}

const CardAddBook: FC<AddBookInterface> = ({ handleAddBook }) => {
  return (
    <Card
      style={{
        width: 230,
        height: '90%',
        minHeight: '400px',
        borderRadius: 20,
        // boxShadow: '0 8px 10px rgb(0 0 0 / 0.08)',
        margin: 20,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <CardContent onClick={handleAddBook} data-testid="book-cardaddbook">
        <AddIcon
          sx={{
            fontSize: 80,
            color: '#6C757D',
            ':hover': {
              color: '#e95d04',
            },
            ':active': {
              color: '#ff9800',
              fontSize: 100,
            },
          }}
        />
      </CardContent>
    </Card>
  );
};

export default CardAddBook;
