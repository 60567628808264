import { ApolloQueryResult, OperationVariables } from '@apollo/client';
import { ChevronRightOutlined, Delete, Download } from '@mui/icons-material';
import { Box, Drawer, IconButton, styled, Typography } from '@mui/material';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { FC } from 'react';
import { RessourceFilesTypes } from '../../../utils/types';
import useLogic from './useLogic';
import { useTranslation } from 'react-i18next';

interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  files: RessourceFilesTypes[] | undefined;
  setcurrentFiles: React.Dispatch<React.SetStateAction<RessourceFilesTypes[] | undefined>>;
  refetch: (variables?: Partial<OperationVariables> | undefined) => Promise<ApolloQueryResult<any>>;
}

const UserFiles: FC<Props> = ({ open, setOpen, files, setcurrentFiles, refetch }) => {
  const { handleDelete } = useLogic({ files, setcurrentFiles, refetch });
  const drawerWidth = 500;

  const { t } = useTranslation();

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: t('User.lastName') + '',
      flex: 1,
      editable: false,
    },

    {
      field: 'createdAt',
      headerName: t('User.submission_date') + '',
      type: 'string',
      flex: 1,
      editable: false,
      valueGetter: (params: GridValueGetterParams) => new Date(+params.row.createdAt).toLocaleDateString(),
    },

    {
      field: 'link',
      headerName: t('User.link') + '',
      type: 'string',
      flex: 1,
      editable: false,
    },
    {
      field: '  ',
      headerName: '',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      width: 50,
      renderCell: (params: any) => {
        return (
          <div>
            <a href={params?.row?.link} rel="noreferrer" target="_blank">
              <IconButton color="primary" edge="end" sx={{ mr: 2 }}>
                <Download />
              </IconButton>
            </a>
          </div>
        );
      },
    },
    {
      field: ' ',
      headerName: '',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      width: 50,
      renderCell: (params: any) => {
        return (
          <div>
            <IconButton
              color="error"
              aria-label="open drawer"
              onClick={() => {
                handleDelete(params.row);
              }}
              edge="end"
              sx={{ mr: 2 }}
            >
              <Delete />
            </IconButton>
          </div>
        );
      },
    },
  ];

  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  }));

  const handlecloseDrawer = () => {
    setOpen(false);
  };

  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
        },
      }}
      variant="persistent"
      anchor="right"
      open={open}
    >
      <DrawerHeader style={{ display: 'flex', justifyContent: 'space-between' }}>
        <IconButton onClick={handlecloseDrawer}>
          <ChevronRightOutlined />
        </IconButton>
        <Typography variant="h5" gutterBottom>
          {t('User.file')}
        </Typography>
      </DrawerHeader>

      <Box sx={{ width: '100%', height: '100%' }}>
        <DataGrid
          rows={files ? files : []}
          columns={columns}
          pageSize={8}
          rowsPerPageOptions={[5]}
          checkboxSelection
          disableSelectionOnClick
          experimentalFeatures={{ newEditingApi: true }}
        />
      </Box>
    </Drawer>
  );
};

export default UserFiles;
