import { gql } from '@apollo/client';

const GET_COUNTRY = gql`
  query GetCountry {
    getCountry {
      id
      isoCode
      name
    }
  }
`;

export { GET_COUNTRY };
