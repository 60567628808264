const LISTE_ROUTES = {
  CANDIDATY: { name: 'Candidaty', path: '/candidaty' },
  CONTACT: { name: 'Contact', path: '/contact' },
  DASHBOARD: { name: 'DashBoard', path: '/dashboard' },
  FORGOT_PASSWORD: { name: 'ForgotPassword', path: '/forgot-password' },
  LOGIN: { name: 'Login', path: '/' },
  PARAMETRE: { name: 'Parametre', path: '/parametre' },
  ACTUALITY: { name: 'Actuality', path: '/actualite' },
  BIBLIOTHEQUE: { name: 'Bibliotheque', path: '/bibliotheque' },
  USER: { name: 'User', path: '/user' },
  VERIFY_CODE: { name: 'VerifyCode', path: '/verify-code' },
  RESET_PASSWORD: { name: 'ResetPassword', path: '/reset-password' },
};

export { LISTE_ROUTES };
