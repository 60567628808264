import { makeStyles } from '@mui/styles';
export default makeStyles(() => ({
  wrapper: {},
  title: {
    '& input':{
        fontWeight: '700',
        letterSpacing: '-1px',
    } 
  },
}));
