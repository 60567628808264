import { ActionType, DATE_NOW } from '../../utils/constant';
import { ActualityType } from '../../utils/interface';
import {
  ADD_ACTUALITY,
  SHOW_DIALOG_ACTUALITY,
  BUTTON_ACTION_DIALOG,
  ADD_ACTUALITIES,
} from '../Actions/ActionActuality';

interface Actuality {
  actuality: ActualityType;
  actualities: ActualityType[];
  show: boolean;
  actionType: ActionType;
}
export const INITIAL_VALUE_ACTUALITY: ActualityType = {
  id: '',
  title: '',
  categoryId: '',
  default_date: DATE_NOW.format('DD/MM/YYYY'),
  end_date: DATE_NOW.format('DD/MM/YYYY'),
  start_date: DATE_NOW.format('DD/MM/YYYY'),
  image: '',
  checked: 0,
  active: true,
  content: '',
};

const initialState: Actuality = {
  actuality: INITIAL_VALUE_ACTUALITY,
  actualities: [],
  show: false,
  actionType: ActionType.SAVE,
};

export const actualityReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case ADD_ACTUALITY:
      return { ...state, actuality: action.payload.actuality };
    case SHOW_DIALOG_ACTUALITY:
      return { ...state, show: action.payload.show };
    case ADD_ACTUALITIES:
      return { ...state, actualities: action.payload.actualities };
    case BUTTON_ACTION_DIALOG:
      return { ...state, actionType: action.payload.actionType };
    default:
      return state;
  }
};
