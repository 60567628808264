import * as React from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { Delete, Edit } from '@mui/icons-material';
import { Box, Button, Grid, IconButton, Typography } from '@mui/material';
import { CategoryType } from '../../../utils/interface';
import { useEffect, useState } from 'react';
import { useCategories } from '../../../graphql/consumerHooks/category';
import { useDeleteCategoryMutation } from '../../../graphql/mutationHooks/category';
import { ActionType } from '../../../utils/constant';
import { useToasts } from 'react-toast-notifications';
import { useDispatch } from 'react-redux';
import { addCategory, buttonActionDialog, showDialogCategory } from '../../../Redux/Actions/ActionCategory';

import { useTranslation } from 'react-i18next';

const CategoriesList = () => {
  const [categories, setCategories] = useState<CategoryType[]>([]);
  const { data: dataCategories, loading: loadingCategories } = useCategories();

  const { deleteCategoryMutation } = useDeleteCategoryMutation();
  const { addToast } = useToasts();

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleDeleteCategory = async (clickedRow: CategoryType) => {
    await deleteCategoryMutation({
      variables: {
        deleteCategoryId: clickedRow.id,
      },
    })
      .then((res: any) => {
        const tabTemp: CategoryType[] = [];
        res.data.deleteCategory.map((item: CategoryType) => {
          return tabTemp.push(item);
        });
        setCategories(tabTemp);

        addToast(t('successDelete'), {
          appearance: 'success',
        });
      })
      .catch((error) => {
        addToast(error.message, {
          appearance: 'error',
        });
      });
  };

  const handleIcon = (params: any) => {
    return (
      <Box>
        <IconButton
          style={{ color: 'green' }}
          onClick={() => {
            handleUpdateCategory(params);
          }}
        >
          <Edit />
        </IconButton>
        <IconButton
          style={{ color: 'red' }}
          onClick={() => {
            handleDeleteCategory(params);
          }}
        >
          <Delete />
        </IconButton>
      </Box>
    );
  };

  const handleUpdateCategory = async (clickedRow: any) => {
    dispatch(addCategory({ id: clickedRow.id, name: clickedRow.row.name }));
    dispatch(buttonActionDialog(ActionType.EDIT));
    dispatch(showDialogCategory(true));
  };

  const columns: GridColDef[] = [
    { field: 'name', headerName: 'Nom', flex: 1 },
    { field: 'action', headerName: 'Action', renderCell: handleIcon, flex: 1 },
  ];

  useEffect(() => {
    if (!loadingCategories && dataCategories) {
      const tabTemp: any[] = [];
      dataCategories.map((item: any) => {
        tabTemp.push(item);
        return tabTemp;
      });

      setCategories(tabTemp);
    }
  }, [dataCategories, loadingCategories]);

  const handleShow = () => {
    dispatch(buttonActionDialog(ActionType.SAVE));
    dispatch(showDialogCategory(true));
  };

  return (
    <Box sx={{ height: 600, width: '100%' }}>
      <Grid xs={5}>
        <Button
          onClick={handleShow}
          style={{ display: 'flex', marginBottom: '2%', textTransform: 'none' }}
          variant="contained"
          data-testid="actuality-category-button-creation"
        >
          Création
        </Button>
      </Grid>
      <Typography style={{ paddingBottom: '2%', marginLeft: '36%' }}>Liste des catégories</Typography>
      <DataGrid
        rows={categories ? categories : []}
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[5]}
        getRowId={(row: CategoryType) => (row.id ? row.id : 0)}
      />
    </Box>
  );
};
export default CategoriesList;
