import React from 'react';
import { UserTypes } from './constant';

const AuthContext = React.createContext({
  authToken: '',
  validity: 0,
  me: {} as UserTypes | null,
  refetch: () => {},
  login: (token: string, user: UserTypes) => {},
  logOut: () => {},
});
export default AuthContext;
