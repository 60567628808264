import { gql } from '@apollo/client';
import { Candidatures } from '../fragments/candidature';

const GET_CANDIDATY = gql`
  query Candidacies {
    Candidacies {
      ...Candidatures
    }
  }
  ${Candidatures}
`;

const GET_CANDIDATY_COUNT = gql`
  query GetCandidaciesCount($year: String) {
    getCandidaciesCount(year: $year) {
      totalCount
      filteredCount
    }
  }
`;

const GET_GROUP_CANDIDATY_COUNT = gql`
  query GetCandidaciesGroupByYear($start: Int, $end: Int) {
    getCandidaciesGroupByYear(start: $start, end: $end) {
      year
      count
    }
  }
`;

export { GET_CANDIDATY, GET_CANDIDATY_COUNT, GET_GROUP_CANDIDATY_COUNT };
