import { Button } from '@mui/material';
import { useEffect, useState } from 'react';
import { useCreateBookMutation, useUpdateBookMutation } from '../../../graphql/mutationHooks/Book';
import { useToasts } from 'react-toast-notifications';
import { ActionType, DATE_NOW } from '../../../utils/constant';
import dayjs from 'dayjs';
import { GET_BOOKS } from '../../../graphql/querries/book';
import { useSelector, useDispatch } from 'react-redux';
import { addBook, showDialogBook, refetchBook } from '../../../Redux/Actions/ActionBook';
import { showLoader } from '../../../Redux/Actions/ActionLoader';
import { BookType } from '../../../utils/interface';
import { INITIAL_VALUE_BOOK } from '../../../Redux/Reducers/ReducerBook';

import { useTranslation } from 'react-i18next';

const ThematicAction = () => {
  const { createBookMutation } = useCreateBookMutation();
  const { updateBookMutation } = useUpdateBookMutation();
  const [books, setBooks] = useState<BookType>(INITIAL_VALUE_BOOK);
  const [actionType, setActionType] = useState<string>('');
  const { addToast } = useToasts();
  const { t } = useTranslation();

  const action = useSelector((state: any) => state);

  const [isDisabled, setDisabled] = useState<boolean>(true);
  const dispatch = useDispatch();

  useEffect(() => {
    setBooks(action.book.book);
    setActionType(action.book.actionType);
  }, [action.book]);

  const handleClose = () => {
    dispatch(showDialogBook(false));
    dispatch(
      addBook({
        id: '',
        title: '',
        published_date: DATE_NOW.format('DD/MM/YYYY'),
        thematicId: '',
        referral_years: '',
        page_number: 1,
        image: { imageBase64: '', imageName: '' },
        pdf: { pdfBase64: '', pdfName: '' },
        ressourceFiles: [],
        thematic: { id: '', title: '' },
        thematic_title: '',
        content: '',
        items: [],
      }),
    );
  };
  const handleClicked = async () => {
    if (actionType === 'EDIT') {
      dispatch(showLoader(true));
      handleClose();
      await updateBookMutation({
        variables: {
          updateBookId: books.id,
          title: books.title,
          publishedDate: books.published_date ? dayjs(books.published_date, 'DD/MM/YYYY').format('YYYY/MM/DD') : null,
          thematicId: books.thematicId,
          referralYears: books.referral_years,
          pageNumber: books.page_number,
          description: books.content,
          image: JSON.stringify({
            imageBase64: books.image.imageBase64,
            imageName: books.image.imageName,
            path: books.image.path,
          }),
          pdf: JSON.stringify({
            pdfBase64: books.pdf.pdfBase64,
            pdfName: books.pdf.pdfName,
            path: books.pdf.path,
          }),
        },
        refetchQueries: [{ query: GET_BOOKS }],
      })
        .then((res: any) => {
          addToast(t('successEdit'), {
            appearance: 'success',
          });
          setDisabled(false);
          dispatch(showLoader(false));
          dispatch(refetchBook(true));
        })
        .catch((error) => {
          addToast(error.message, {
            appearance: 'error',
          });
          setDisabled(true);
          dispatch(showLoader(false));
        });
    }

    if (actionType === 'SAVE') {
      dispatch(showLoader(true));
      handleClose();
      await createBookMutation({
        variables: {
          title: books.title,
          publishedDate: books.published_date ? dayjs(books.published_date, 'DD/MM/YYYY').format('YYYY/MM/DD') : null,
          thematicId: !books.thematicId ? null : books.thematicId,
          referralYears: books.referral_years,
          pageNumber: books.page_number.toString(),
          description: books.content,
          image: JSON.stringify({
            imageBase64: books.image.imageBase64,
            imageName: books.image.imageName,
          }),
          pdf: JSON.stringify({ pdfBase64: books.pdf.pdfBase64, pdfName: books.pdf.pdfName }),
        },
        refetchQueries: [{ query: GET_BOOKS }],
      })
        .then((res: any) => {
          addToast(t('successSaved'), {
            appearance: 'success',
          });
          setDisabled(true);
          dispatch(showLoader(false));
          dispatch(refetchBook(true));
        })
        .catch((error) => {
          addToast(error.message, {
            appearance: 'error',
          });
          setDisabled(true);
          dispatch(showLoader(false));
        });
      setDisabled(false);
    }

    dispatch(
      addBook({
        id: '',
        title: '',
        published_date: DATE_NOW.format('DD/MM/YYYY'),
        thematicId: '',
        referral_years: '',
        page_number: 1,
        image: { imageBase64: '', imageName: '', path: '' },
        pdf: { pdfBase64: '', pdfName: '', path: '' },
        ressourceFiles: [],
        thematic: { id: '', title: '' },
        thematic_title: '',
        content: '',
        items: [],
      }),
    );
  };

  useEffect(() => {
    action.book.book.title === '' || action.book.book.image.imageName === '' || action.book.book.pdf.pdfName === ''
      ? setDisabled(true)
      : setDisabled(false);
  }, [action.book.book.title, action.book.book.image.imageName, action.book.book.pdf.pdfName]);

  return (
    <>
      <Button
        variant="contained"
        color={actionType === ActionType.SAVE ? 'primary' : 'success'}
        onClick={handleClicked}
        disabled={isDisabled}
        style={{ textTransform: 'none' }}
      >
        {actionType === ActionType.SAVE ? ActionType.SAVE : ActionType.EDIT}
      </Button>
    </>
  );
};

export default ThematicAction;
