import { gql } from '@apollo/client';

const GET_FORMATION = gql`
  query GetEducations {
    getEducations {
      title
      name
      id
      description
      updatedAt
      createdAt
    }
  }
`;

export { GET_FORMATION };
